import { useMemo } from "react";
import { useDpConfig } from "../contexts/dpConfig";
import { LoanProductType } from "./api/types";
import {
  LoanProductConfig,
  LoanProductInvoiceConfig,
} from "../contexts/dpConfig/types";

type InvoiceFinanceProductConfig = LoanProductConfig & LoanProductInvoiceConfig;

const useInvoiceFinanceSettings = () => {
  const {
    state: { products },
  } = useDpConfig();

  const invoiceFinance = useMemo(
    () =>
      products.find(
        (product) => product.type === LoanProductType.INVOICE_DISCOUNTING
      ),
    [products]
  );

  const maxDiscountFlag = useMemo(() => {
    const config = invoiceFinance?.config as InvoiceFinanceProductConfig;

    return config?.discount?.max === 100;
  }, [invoiceFinance]);

  const fullDiscountFlag = useMemo(() => {
    const config = invoiceFinance?.config as InvoiceFinanceProductConfig;

    return config?.discount?.min === 100 && maxDiscountFlag;
  }, [invoiceFinance, maxDiscountFlag]);

  const disableDiscountFlag = useMemo(() => {
    const config = invoiceFinance?.config as InvoiceFinanceProductConfig;

    return !fullDiscountFlag && config?.discount?.min !== config?.discount?.max;
  }, [invoiceFinance, fullDiscountFlag]);

  const repayableByDPFlag = useMemo(() => {
    const config = invoiceFinance?.config as InvoiceFinanceProductConfig;

    return config?.repayableByDP;
  }, [invoiceFinance]);

  return {
    invoiceFinance,
    maxDiscountFlag,
    fullDiscountFlag,
    disableDiscountFlag,
    repayableByDPFlag,
  };
};

export default useInvoiceFinanceSettings;
