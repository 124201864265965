import { FC } from "react";
import { PenIcon } from "../../../assets/icons";

type EditButtonProps = {
  onClick?(): void;
};

const EditButton: FC<EditButtonProps> = ({ onClick }) => {
  return (
    <div
      className="cx-group cx-w-8 cx-h-8 cx-flex cx-justify-center cx-items-center cx-rounded-full cx-border cx-border-stroke-primary cx-cursor-pointer hover:cx-bg-background-brand"
      onClick={onClick}
    >
      <div className="cx-w-4 cx-h-4">
        <PenIcon className="cx-text-text-brand group-hover:cx-text-text-inverse" />
      </div>
    </div>
  );
};

export default EditButton;
