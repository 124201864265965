import { FC } from "react";
import { ShareholderFields } from "./service";
import { Modal } from "../../../components";
import AddShareholder from "./AddShareholder";
import { useLoanApplication } from "../../../contexts";
import {
  useGetActiveLoanApplication,
  useUpdateCompanyShareholder,
  useUpdateIndividualShareholder,
} from "../../../hooks";
import { ShareholderEntityType } from "../../../hooks/api/types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  shareholder?: ShareholderFields;
  onSubmit: (values: ShareholderFields) => void;
  onCancel: () => void;
};

const ShareholderModal: FC<Props> = ({
  isOpen,
  onClose,
  shareholder,
  onSubmit,
  onCancel,
}) => {
  const {
    state,
    actions: { update },
  } = useLoanApplication();

  const { mutateAsync: updateCompanyShareholder } =
    useUpdateCompanyShareholder();
  const { mutateAsync: updateIndividualShareholder } =
    useUpdateIndividualShareholder();
  const { mutateAsync: getActiveLoanApplication } =
    useGetActiveLoanApplication();

  const handleSubmit = async (values: ShareholderFields) => {
    if (shareholder) {
      if (values.__entityType === ShareholderEntityType.INDIVIDUAL) {
        // Update locally in context state before calling the API
        const newShareholders = state.individualShareholders.map((s) =>
          s.id === shareholder.id ? values : s
        );
        await update(
          {
            individualShareholders: newShareholders,
          },
          { local: true }
        );
        await updateIndividualShareholder({ id: shareholder.id!, ...values });
      } else {
        // Update locally in context state before calling the API
        const newShareholders = state.companyShareholders.map((s) =>
          s.id === shareholder.id ? values : s
        );
        await update(
          {
            companyShareholders: newShareholders,
          },
          { local: true }
        );
        await updateCompanyShareholder({ id: shareholder.id!, ...values });
      }
    } else {
      if (values.__entityType === ShareholderEntityType.INDIVIDUAL) {
        await update({
          individualShareholders: [...state.individualShareholders, values],
        }).then(() => {
          getActiveLoanApplication().then((data: any) => {
            update(
              {
                individualShareholders: data.company.individualShareholders,
              },
              {
                local: true,
              }
            );
          });
        });
      } else {
        await update({
          companyShareholders: [...state.companyShareholders, values],
        }).then(() => {
          getActiveLoanApplication().then((data: any) => {
            update(
              {
                companyShareholders: data.company.companyShareholders,
              },
              {
                local: true,
              }
            );
          });
        });
      }
    }
    onSubmit(values);
  };

  return (
    <Modal title="" isOpen={isOpen} onClose={onClose} className="cx-py-4">
      <AddShareholder
        shareholder={shareholder}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        modal
      />
    </Modal>
  );
};

export default ShareholderModal;
