import * as yup from "yup";
import {
  CompanyShareholderType,
  ShareholderEntityType,
} from "../../../hooks/api/types";
import { PHONE_NUMBER_SCHEMA } from "../Onboarding/service";

export type IndividualShareholderFields = {
  __entityType: ShareholderEntityType.INDIVIDUAL;
  id?: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  localNumber: string;
  email: string;
  isStakeholder: boolean;
  hasPOA: boolean;
  type: CompanyShareholderType;
  skipEidUpload?: boolean;
};

export type CompanyShareholderFields = {
  __entityType: ShareholderEntityType.COMPANY;
  id?: string;
  name: string;
  email: string;
  countryCode: string;
  localNumber: string;
  hasPOA: boolean;
  isStakeholder: boolean;
};

export type ShareholderFields =
  | IndividualShareholderFields
  | CompanyShareholderFields;

export type OwnerInformationFields = {
  email: string;
  firstName: string;
  lastName: string;
  isStakeholder: boolean;
  hasPOA: boolean;
  // shareholders: Shareholder[];
};

export const OWNER_INFORMATION_INITIAL_VALUES: OwnerInformationFields = {
  firstName: "",
  lastName: "",
  email: "",
  isStakeholder: true,
  hasPOA: true,
  // shareholders: [],
};

export const INDIVIDUAL_SHAREHOLDER_INITIAL_VALUES: IndividualShareholderFields =
  {
    __entityType: ShareholderEntityType.INDIVIDUAL,
    firstName: "",
    lastName: "",
    countryCode: "+971",
    localNumber: "",
    email: "",
    isStakeholder: true,
    hasPOA: true,
    type: CompanyShareholderType.SHAREHOLDER,
  };

export const COMPANY_SHAREHOLDER_INITIAL_VALUES: CompanyShareholderFields = {
  __entityType: ShareholderEntityType.COMPANY,
  name: "",
  countryCode: "+971",
  localNumber: "",
  email: "",
  hasPOA: true,
  isStakeholder: true,
};

export const EMAIL_SCHEMA = yup
  .string()
  .email("Please enter a valid email address")
  .required("Email is required")
  .matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "Please enter a valid email address"
  );

export const OWNER_INFORMATION_VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: EMAIL_SCHEMA,
  isStakeholder: yup.boolean().required("Please select an option"),
  hasPOA: yup.boolean().required("Please select an option"),
  // shareholders: yup.array().of(
  //   yup.object().shape({
  //     firstName: yup.string().required("First name is required"),
  //     lastName: yup.string().required("Last name is required"),
  //     countryCode: yup.string().required("Country code is required"),
  //     localNumber: PHONE_NUMBER_SCHEMA,
  //     email: EMAIL_SCHEMA,
  //   })
  // ),
});

export const INDIVIDUAL_SHAREHOLDER_VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  countryCode: yup.string().required("Country code is required"),
  localNumber: PHONE_NUMBER_SCHEMA,
  email: EMAIL_SCHEMA,
  isStakeholder: yup.boolean().required("Please select an option"),
  hasPOA: yup.boolean().required("Please select an option"),
});

export const COMPANY_SHAREHOLDER_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required("Company name is required"),
  countryCode: yup.string().required("Country code is required"),
  localNumber: PHONE_NUMBER_SCHEMA,
  email: EMAIL_SCHEMA,
  isStakeholder: yup.boolean().required("Please select an option"),
  hasPOA: yup.boolean().required("Please select an option"),
});

export const BOOLEAN_CARD_OPTIONS = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const isEmailUniqueInShareholders = (
  email: string,
  shareholders: ShareholderFields[],
  currentShareholder?: ShareholderFields
) => {
  return !shareholders
    .filter((shareholder) =>
      currentShareholder ? shareholder.email !== currentShareholder.email : true
    )
    .map((shareholder) => shareholder.email)
    .includes(email);
};
