import { useContext, useEffect, useState } from "react";
import {
  IndividualShareholder,
  StepperContext,
  useLoanApplication,
} from "../../../contexts";
import ApplicantInformationForm from "./ApplicantInformationForm";
import { LoanApplicationStage } from "../../../hooks/api/types";
import { OwnerListSelection } from "./OwnerListSelection";
import { ButtonLoaderIcon } from "../../../assets/icons";

const OwnersInformationForm = () => {
  const { nextStep: onNextStep } = useContext(StepperContext);
  const {
    state,
    actions: { update },
  } = useLoanApplication();

  const [showSelection, setShowSelection] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedShareholder, setSelectedShareholder] =
    useState<IndividualShareholder | null>();

  async function handleNextStep() {
    onNextStep();
    await update({
      stage: LoanApplicationStage.SHAREHOLDER_INFORMATION,
    });
  }

  useEffect(() => {
    if (state) {
      setIsLoading(false);
      if (
        state.applicant.email === "" &&
        (state.individualShareholders.length > 0 ||
          state.companyShareholders.length > 0)
      ) {
        setShowSelection(true);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedShareholder) {
      setShowSelection(false);
    }
  }, [selectedShareholder]);

  const backClicked = () => {
    setSelectedShareholder(null);
    setShowSelection(true);
  };

  const Loader = () => {
    return (
      <div className="cx-w-full cx-h-screen cx-flex cx-justify-center cx-items-center cx-text-brand-primary-regular">
        <ButtonLoaderIcon className="cx-animate-spin cx-w-6 cx-h-6" />
      </div>
    );
  };

  if (state.loading || isLoading) {
    return <Loader />;
  }

  const nextClicked = (shareholder: IndividualShareholder | null) => {
    if (shareholder) {
      setSelectedShareholder(shareholder);
    } else {
      setShowSelection(false);
    }
  };

  return showSelection ? (
    <OwnerListSelection nextClicked={nextClicked} />
  ) : (
    <ApplicantInformationForm
      onSubmit={handleNextStep}
      shareholderPreSelected={selectedShareholder!}
      backClicked={backClicked}
    />
  );
};

export default OwnersInformationForm;
