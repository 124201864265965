import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../../../../components";
import { useMedia } from "../../../../hooks";
import { GetMediaResponse, MediaPurpose } from "../../../../hooks/api/types";
import { ButtonLoaderIcon, EyeIcon, PenIcon } from "../../../../assets/icons";
import { toTitleCase, truncate } from "../../../../utils";

type Document = {
  id: string;
};

type AttachedDocumentCardProps = {
  document: Document;
  onChange?: (file: File) => void;
  loading?: boolean;
  accept?: string;
  showTitle: boolean;
};

const AttachedDocumentCard = ({
  document,
  onChange,
  loading,
  accept = ".pdf,application/pdf",
  showTitle,
}: AttachedDocumentCardProps) => {
  const [media, setMedia] = useState<GetMediaResponse | null>(null);
  const [previewMode, setPreviewMode] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { get } = useMedia();

  useEffect(() => {
    get({ id: document.id }).then((media) => {
      setMedia(media);
    });
  }, [document.id]);

  const showDocumentTitle =
    showTitle &&
    useMemo(
      () => media?.media?.purpose !== MediaPurpose.GENERIC_DOCUMENT,
      [media]
    );

  return (
    <>
      <div className="cx-flex cx-flex-col cx-gap-y-3">
        {showDocumentTitle && (
          <div className="cx-text-text-secondary cx-text-sm cx-font-medium">
            {toTitleCase(media?.media?.purpose ?? "document.pdf")}
          </div>
        )}
        <div
          className="cx-w-48 cx-h-64 cx-rounded-lg cx-border cx-border-stroke-primary"
          style={{
            ...(media?.thumbnailUrl && {
              background: `url(${media?.thumbnailUrl})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }),
          }}
        />
        {media?.media?.fileName && (
          <div className="cx-text-text-secondary cx-text-xs cx-font-medium cx-text-center">
            {truncate(media?.media?.fileName, 35)}
          </div>
        )}
        <div className="cx-flex cx-flex-row cx-justify-center cx-items-center cx-gap-x-4">
          <div
            className="cx-group cx-w-8 cx-h-8 cx-flex cx-justify-center cx-items-center cx-rounded-full cx-border cx-border-stroke-primary cx-cursor-pointer hover:cx-bg-background-brand"
            onClick={() => {
              setPreviewMode(true);
            }}
          >
            <div className="cx-w-4 cx-h-4">
              <EyeIcon className="cx-text-text-brand group-hover:cx-text-text-inverse" />
            </div>
          </div>
          <input
            ref={inputRef}
            type="file"
            hidden
            multiple={false}
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                onChange?.(e.target.files[0]);
              }
            }}
            accept={accept}
          />
          <div
            className="cx-group cx-w-8 cx-h-8 cx-flex cx-justify-center cx-items-center cx-rounded-full cx-border cx-border-stroke-primary cx-cursor-pointer hover:cx-bg-background-brand"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
          >
            <div className="cx-w-4 cx-h-4">
              {loading ? (
                <ButtonLoaderIcon className="cx-animate-spin cx-text-text-brand group-hover:cx-text-text-inverse" />
              ) : (
                <PenIcon className="cx-text-text-brand group-hover:cx-text-text-inverse" />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={previewMode}
        onClose={() => setPreviewMode(false)}
        title={truncate(media?.media?.fileName ?? "", 45)}
        className={"cx-max-w-[960px]"}
      >
        <iframe
          src={media?.url}
          style={{ width: 576, height: 768 }}
          frameBorder="0"
        ></iframe>
      </Modal>
    </>
  );
};

export default AttachedDocumentCard;
