import { FC } from "react";
import { Dropdown, InputField } from "../../../components";
import {
  DETAIL_FORM_EMIRATE_DROPDOWN_OPTIONS,
  DetailFormFields,
} from "../BusinessInformation/service";
import { FormikProps } from "formik";

type OfficeAddressFormProps = {
  form: FormikProps<DetailFormFields>;
};

export const OfficeAddressForm: FC<OfficeAddressFormProps> = ({
  form: { values, errors, touched, handleBlur, handleChange },
}) => {
  return (
    <div className="cx-mt-6 cx-grid cx-grid-cols-2 cx-gap-4">
      <div className="cx-w-full">
        <Dropdown
          label="Emirate"
          options={DETAIL_FORM_EMIRATE_DROPDOWN_OPTIONS}
          inputProps={{
            name: "emirate",
            value: values.emirate,
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          error={touched.emirate && !!errors.emirate ? errors.emirate : ""}
        />
      </div>
      <div className="cx-w-full">
        <InputField
          label="Area"
          tick={touched.area && !errors.area}
          inputProps={{
            name: "area",
            value: values.area,
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          error={touched.area && !!errors.area ? errors.area : ""}
        />
      </div>
      <div className="cx-w-full">
        <InputField
          label="Street name"
          tick={touched.street && !errors.street}
          inputProps={{
            name: "street",
            value: values.street,
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          error={touched.street ? errors.street : ""}
        />
      </div>
      <div className="cx-w-full">
        <InputField
          label="Building"
          tick={touched.building && !errors.building}
          inputProps={{
            name: "building",
            value: values.building,
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          error={touched.building ? errors.building : ""}
        />
      </div>
      <div className="cx-w-full">
        <InputField
          label="Floor"
          tick={touched.floor && !errors.floor}
          inputProps={{
            name: "floor",
            value: values.floor,
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          error={touched.floor ? errors.floor : ""}
        />
      </div>
      <div className="cx-w-full">
        <InputField
          label="Office number"
          tick={touched.number && !errors.number}
          inputProps={{
            name: "number",
            value: values.number,
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          error={touched.number ? errors.number : ""}
        />
      </div>
    </div>
  );
};
