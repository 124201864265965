import { useContext } from "react";
import { Button, Divider, StepPrevButton } from "../../components";
import { StepperContext, useLoanOffer } from "../../contexts";
import { useApproveLoanOffer } from "../../hooks";
import { ArrowLeftIcon } from "../../assets/icons";
import { STEP_STAGE_NAME_MAP } from ".";
import { LoanOfferStage } from "../../hooks/api/types";

export const ExistingShareholderSignature = () => {
  const { gotoStep } = useContext(StepperContext);
  const {
    state,
    actions: { update },
  } = useLoanOffer();

  const { mutateAsync: approveLoanOffer, isLoading: isApproving } =
    useApproveLoanOffer();

  const handleApproveLoanOffer = async () => {
    await approveLoanOffer({
      id: state.id,
    });
    gotoStep(STEP_STAGE_NAME_MAP[LoanOfferStage.COMPLETED]);
  };

  return (
    <>
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        Review Existing Signature
      </div>
      <div className="cx-text-text-secondary cx-text-center">
        Please review and confirm that your existing signature matches your bank
        signature.
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <div className="cx-w-full cx-bg-background-default cx-rounded-lg cx-overflow-hidden cx-my-10 cx-h-52 cx-p-2 cx-relative">
          <img
            src={state.existingShareholderSignatureSignedUrl ?? ""}
            className="cx-w-full cx-h-full"
            alt="Signature"
          />
        </div>
        <Button
          label="Use a different signature "
          fullWidth
          outlined
          onClick={() => {
            update({
              existingShareholderSignatureSignedUrl: null,
            });
          }}
          disabled={isApproving}
        />
        <Divider className="cx-mb-10 cx-mt-10" />
        <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
          <div className="cx-w-1/4">
            <StepPrevButton
              label={<ArrowLeftIcon />}
              type="button"
              onClick={() => {
                update({
                  existingShareholderSignatureSignedUrl: null,
                });
              }}
            />
          </div>
          <Button
            label="Submit"
            fullWidth
            onClick={handleApproveLoanOffer}
            disabled={isApproving}
            {...(isApproving && { loader: "left" })}
          />
        </div>
      </div>
    </>
  );
};
