import { roundTo } from "../../../../utils";
import LoanReviewField from "../LoanReviewField";
import { Divider } from "../../../../components";
import { useLoanMetrics } from "../../../../hooks";
import EditButton from "../EditButton";
import { useState } from "react";
import BusinessLoanModal from "../BusinessLoanModal";
import { useDpConfig } from "../../../../contexts/dpConfig";
import { LoanProductType } from "../../../../hooks/api/types";
import { useLoanApplication } from "../../../../contexts";

const RevenueBasedLoanReviewSection = () => {
  const [loanCalculatorModalOpen, setLoanCalculatorModalOpen] = useState(false);

  const {
    state: { products },
  } = useDpConfig();

  const revenueBasedFinancingProduct = products.find(
    (product) => product.type === LoanProductType.REVENUE_BASED_FINANCING
  );
  const {
    state: { additionalData },
  } = useLoanApplication();
  const {
    revenueBasedLoanPaymentPerMonth,
    totalPayment,
    borrowedAmount,
    duration,
    interestRate,
    currencyUnit,
  } = useLoanMetrics();

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
        <div className="cx-text-2xl cx-font-bold">Revenue Based Loan</div>
        <EditButton onClick={() => setLoanCalculatorModalOpen(true)} />
      </div>
      <div className="cx-flex cx-flex-col sm:cx-flex-row cx-gap-x-10">
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(
              revenueBasedLoanPaymentPerMonth,
              2
            ).toLocaleString()}`}
            label={
              revenueBasedFinancingProduct?.uiLocalizationData?.reviewScreen
                ?.monthlyPaymentLabel || "Your estimated monthly payment"
            }
          />
          <Divider />
          <LoanReviewField
            value={`${duration.value} ${duration.unit}`}
            label="Loan Duration"
          />
          <Divider />
          {import.meta.env.VITE_HARDCODED_INTEREST_RATE ? (
            <LoanReviewField
              label={
                revenueBasedFinancingProduct?.uiLocalizationData?.reviewScreen
                  ?.monthlyInterestLabel || "Monthly interest rate"
              }
              value={`${import.meta.env.VITE_HARDCODED_INTEREST_RATE}%`}
            />
          ) : (
            <LoanReviewField
              label={
                revenueBasedFinancingProduct?.uiLocalizationData?.reviewScreen
                  ?.monthlyInterestLabel || "Monthly interest rate"
              }
              value={`${
                !revenueBasedFinancingProduct?.uiLocalizationData?.reviewScreen
                  ?.hidePayAsLowAs
                  ? "Pay as low as "
                  : ""
              }${roundTo((interestRate / 12) * 100, 2)}%${
                !revenueBasedFinancingProduct?.uiLocalizationData?.reviewScreen
                  ?.hideLoanMetricsDisclaimers
                  ? "*"
                  : ""
              }`}
              description={
                !revenueBasedFinancingProduct?.uiLocalizationData?.reviewScreen
                  ?.hideLoanMetricsDisclaimers
                  ? "*Depends on your loan application and eligibility."
                  : undefined
              }
            />
          )}
        </div>
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(
              totalPayment,
              2
            ).toLocaleString()}`}
            label="Total repayment amount"
          />
          <Divider />
          <LoanReviewField
            value={`
              ${currencyUnit} ${roundTo(borrowedAmount, 2).toLocaleString()}${
              !revenueBasedFinancingProduct?.uiLocalizationData?.reviewScreen
                ?.hideLoanMetricsDisclaimers
                ? "*"
                : ""
            }`}
            label="Amount to borrow"
          />
          {additionalData?.disbursementAccount?.iban && (
            <LoanReviewField
              label={"Disbursement account"}
              value={additionalData.disbursementAccount.iban}
            />
          )}
        </div>
      </div>
      <BusinessLoanModal
        isOpen={loanCalculatorModalOpen}
        onClose={() => {
          setLoanCalculatorModalOpen(false);
        }}
      />
    </div>
  );
};

export default RevenueBasedLoanReviewSection;
