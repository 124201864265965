import { ArrowIcon } from "../../../assets/icons";

type Props = {
  name: string;
  licenseNumber: string;
  licensingAuthority: string;
  selected?: boolean;
};

const CompanyProfileCard: React.FC<Props> = ({
  name,
  licenseNumber,
  licensingAuthority,
  selected = false,
}) => {
  return (
    <div className="cx-w-full cx-rounded-base cx-py-4 cx-px-1.5 cx-bg-background-default cx-cursor-pointer cx-flex cx-flex-row cx-justify-around cx-items-center cx-border-1.5">
      <div className="cx-flex cx-flex-col cx-gap-1 cx-w-80">
        <div className="cx-flex">
          <p className="cx-text-center cx-font-medium">{name}</p>
        </div>
        <div className="cx-flex">
          <p className="cx-text-center cx-text-xs cx-text-text-secondary cx-font-light">
            {licensingAuthority}
          </p>
        </div>
        <div className="cx-flex">
          <p className="cx-text-center cx-text-xs cx-text-text-secondary cx-font-light">
            {licenseNumber}
          </p>
        </div>
      </div>
      <div className="cx-flex cx-flex-col cx-items-center cx-width-24">
        <div className="cx-flex cx-items-center">
          <ArrowIcon className="cx-text-brand-primary-regular cx-w-11/12 cx-h-11/12" />
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileCard;
