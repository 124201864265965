import { useFormik } from "formik";
import { useContext, useMemo } from "react";
import { CardRadioOptions, StepNextButton } from "../../components";
import { SignModeType, StepperContext, useLoanOffer } from "../../contexts";
import {
  LoanOfferSignTypeFields,
  LOAN_OFFER_SIGN_TYPE_INITIAL_VALUES,
  LOAN_OFFER_SIGN_TYPE_VALIDATION_SCHEMA,
} from "./service";
import { CameraIcon2, PencilWaveIcon, MouseIcon } from "../../assets/icons";
import { ExistingShareholderSignature } from "./ExistingShareholderSignature";

const options = [
  {
    icon: <MouseIcon />,
    label: "Mouse or trackpad",
    value: SignModeType.MOUSE_TRACKPAD,
    displayValue: "Create your signature using mouse or on trackpad",
    showDisplayValue: true,
  },
  {
    icon: <PencilWaveIcon className="cx-text-text-brand" />,
    label: "Manual signature on form",
    value: SignModeType.PRINT_SCAN,
    displayValue: "Download direct debit form, sign and upload it.",
    showDisplayValue: true,
  },
  {
    icon: <CameraIcon2 className="cx-text-text-brand" />,
    label: "Upload a photo of your signature",
    value: SignModeType.UPLOAD_SIGNATURE,
    displayValue: "Share a clear image of your signature.",
    showDisplayValue: true,
  },
];

const LoanOfferSign = () => {
  const { nextStep } = useContext(StepperContext);
  const {
    state,
    actions: { update },
  } = useLoanOffer();

  const { values, touched, errors, handleChange, handleSubmit } =
    useFormik<LoanOfferSignTypeFields>({
      initialValues: {
        ...LOAN_OFFER_SIGN_TYPE_INITIAL_VALUES,
        signMode: state.signMode,
      },
      validationSchema: LOAN_OFFER_SIGN_TYPE_VALIDATION_SCHEMA,
      onSubmit: (values) => {
        update({
          signMode: values.signMode,
        })
          .then(() => {
            nextStep();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });

  const selectOptions = useMemo(() => {
    if (state.preSelectedSignMode === SignModeType.PRINT_SCAN) {
      return [
        {
          icon: <PencilWaveIcon className="cx-text-text-brand" />,
          label: "Manual signature on form",
          value: SignModeType.PRINT_SCAN,
          displayValue: "Download direct debit form, sign and upload it.",
          showDisplayValue: true,
        },
      ];
    }
    return options;
  }, [state.preSelectedSignMode]);

  if (state.existingShareholderSignatureSignedUrl) {
    return <ExistingShareholderSignature />;
  }

  return (
    <div className="cx-max-w-[960px] cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        Authorize direct debit
      </div>
      <div className="cx-text-text-secondary cx-text-center">
        Please authorize bank direct debit using your signature.
        <br />
        Your signature must match your existing bank signature to avoid
        rejection.
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <form onSubmit={handleSubmit}>
          <div className="cx-w-full cx-py-8">
            <div className="cx-text-text-primary cx-font-semibold cx-pb-2">
              Select signature method
            </div>
            <CardRadioOptions
              options={selectOptions}
              keyPropName="value"
              inputProps={{
                name: "signMode",
                value: values.signMode,
                onChange: handleChange,
              }}
              externalOptionItemStyles="cx-bg-background-default"
            />
            {touched.signMode && !!errors.signMode && (
              <div className="cx-text-xs cx-py-1 cx-text-text-error">
                {errors.signMode}
              </div>
            )}
          </div>
          <StepNextButton label="Next" />
        </form>
      </div>
    </div>
  );
};

export default LoanOfferSign;
