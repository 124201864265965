import { useContext } from "react";
import { Button, Modal } from "../../components";
import info from "../../constants/info.json";
import { SDKContext } from "../../contexts";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const PrivacyPolicyModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { options } = useContext(SDKContext);
  return (
    <Modal title="Privacy Policy" isOpen={isOpen} onClose={onClose}>
      <div className="cx-flex cx-flex-col cx-items-center">
        <div className="cx-w-full cx-border-2 cx-border-lightgray-400 cx-rounded-lg cx-p-6 cx-overflow-y-auto cx-max-h-96">
          <div className="cx-text-lg cx-font-bold">1. Introduction</div>
          <div className="cx-text-sm cx-mt-4">
            CredibleX Limited ("we," "our," or "the Company") is committed to
            protecting the privacy and personal data of our users and customers.
            This Privacy Policy outlines how we collect, use, disclose, and
            protect your personal information in compliance with the General
            Data Protection Regulation (GDPR) and other relevant data protection
            laws.
          </div>
          <div className="cx-text-lg cx-font-bold cx-mt-4">
            2. Information We Collect
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            2.1 Personal Information
          </div>
          <div className="cx-text-sm">
            We may collect personal information from you, such as your name,
            email address, postal address, phone number, and other identifiable
            data when you interact with us through our website, services, or
            communication channels.
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            2.2 Usage of Data
          </div>
          <div className="cx-text-sm">
            We automatically collect certain information about your usage of our
            website and services, including IP addresses, browser type, device
            identifiers, operating system, pages viewed, and time and date of
            access.
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            2.3 Cookies and Similar Technologies
          </div>
          <div className="cx-text-sm">
            We may use cookies and similar technologies to enhance your
            experience, analyze usage patterns, and personalize content and
            advertisements. You can manage your cookie preferences through your
            browser settings.
          </div>
          <div className="cx-text-lg cx-font-bold cx-mt-4">
            3. How We Use Your Information
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            3.1 Provide and Improve Services
          </div>
          <div className="cx-text-sm">
            We use your personal information to deliver and enhance our
            services, respond to your inquiries, and improve the functionality
            and performance of our website.
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            3.2 Communication
          </div>
          <div className="cx-text-sm">
            We may use your contact information to send you relevant updates,
            newsletters, and marketing communications based on your preferences
            and applicable laws.
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            3.3 Legal Compliance
          </div>
          <div className="cx-text-sm">
            We process personal data as required by law and to enforce our legal
            rights, protect against fraudulent activities, and ensure compliance
            with applicable regulations.
          </div>
          <div className="cx-text-lg cx-font-bold cx-mt-4">
            4. Data Sharing and Disclosure
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            4.1 Third-Party Service Providers
          </div>
          <div className="cx-text-sm">
            We may share your personal information with third-party service
            providers who assist us in delivering our services, maintaining our
            website and applications, and conducting business operations. These
            providers are bound by contractual agreements to protect your data.
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            4.2 Business Transactions
          </div>
          <div className="cx-text-sm">
            In the event of a merger, acquisition, or sale of assets, your
            personal data may be transferred as part of the transaction.
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            4.3 Legal Obligations
          </div>
          <div className="cx-text-sm">
            We may disclose your personal information to comply with legal
            obligations, enforce our rights, protect our users' safety, or
            investigate fraud or security breaches.
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            4.3 Data Sharing
          </div>
          <div className="cx-text-sm">
            By accepting the terms and conditions, privacy and cookies policies,
            you consent and acknowledge that your data will be passed through
            the {options?.name} platform, in their capacity as a service
            processor and your data will reside with us.
          </div>
          <div className="cx-text-lg cx-font-bold cx-mt-4">
            5. Data Security
          </div>
          <div className="cx-text-sm cx-mt-2">
            We implement appropriate technical and organizational measures to
            safeguard your personal data from unauthorized access, disclosure,
            alteration, or destruction. However, no data transmission over the
            internet can be entirely secure. Therefore, we cannot guarantee the
            absolute security of your information.
          </div>
          <div className="cx-text-lg cx-font-bold cx-mt-4">6. Your Rights</div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            6.1 Access and Rectification
          </div>
          <div className="cx-text-sm">
            You have the right to access the personal information we hold about
            you and request corrections or updates if inaccuracies exist.
          </div>
          <div className="cx-text-sm cx-font-bold cx-mt-2">
            6.2 Marketing Communications
          </div>
          <div className="cx-text-sm">
            You can opt-out of receiving marketing communications by following
            the instructions provided in the emails or contacting us directly.
          </div>
          <div className="cx-text-lg cx-font-bold cx-mt-4">
            7. Data Retention
          </div>
          <div className="cx-text-sm cx-mt-2">
            We will retain your personal data only for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy or as required
            by law.
          </div>
          <div className="cx-text-lg cx-font-bold cx-mt-4">
            8. Changes to this Privacy Policy
          </div>
          <div className="cx-text-sm cx-mt-2">
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or applicable laws. The updated version
            will be published on our website, application and/or dashboard with
            the effective date indicated.
          </div>
          <div className="cx-text-lg cx-font-bold cx-mt-4">9. Contact Us</div>
          <div className="cx-text-sm cx-mt-2">
            If you have any questions or concerns regarding this Privacy Policy
            or wish to exercise your data protection rights, please contact us
            at:
          </div>
          <div className="cx-text-md cx-mt-4">{info.name}</div>
          <div className="cx-text-md">{info.address.street}</div>
          <div className="cx-text-md">{info.address.city}</div>
          <div className="cx-text-md cx-text-blue-600 cx-underline">
            {info.email}
          </div>
          <div className="cx-text-sm cx-mt-4">
            By using our services, you agree to the terms outlined in this
            Privacy Policy.
          </div>
        </div>
        <div className="cx-mt-6 cx-w-full">
          <Button label="Close" onClick={onClose} fullWidth />
        </div>
      </div>
    </Modal>
  );
};

export default PrivacyPolicyModal;
