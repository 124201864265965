import { useFormik } from "formik";
import { useContext, useMemo, useState } from "react";
import { Button, InputField, Modal, StepNextButton } from "../../../components";
import { StepperContext, useLoanApplication } from "../../../contexts";
import { ArrowLeftIcon } from "../../../assets/icons";
import { useDpConfig } from "../../../contexts/dpConfig";
import {
  AdditionalDPMetadata,
  AdditionalDPMetadataFieldPopup,
} from "../../../contexts/dpConfig/types";
import * as yup from "yup";
import { LoanApplicationStage } from "../../../hooks/api/types";
import { sanitizeHtml } from "../../../utils";

const MoreInformation = () => {
  const {
    actions: { update },
  } = useLoanApplication();
  const { nextStep, previousStep } = useContext(StepperContext);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedFieldHint, setSelectedFieldHint] = useState<
    AdditionalDPMetadataFieldPopup | undefined
  >();
  const {
    state: { settings },
  } = useDpConfig();

  const initialFieldValues = useMemo(() => {
    const initialValues = settings.additionalData?.reduce((acc: any, field) => {
      acc[field.key] = "";
      return acc;
    }, {});
    return initialValues || {};
  }, [settings.additionalData]);

  const validationSchema = useMemo(() => {
    const schema = settings.additionalData?.reduce((acc: any, field) => {
      acc[field.key] = yup
        .string()
        .required(`${field.displayName} is required`)
        .matches(new RegExp(field.regex), "Invalid input");
      return acc;
    }, {});
    return schema ? yup.object().shape(schema) : {};
  }, [settings.additionalData]);

  const { errors, touched, handleBlur, handleChange, values, handleSubmit } =
    useFormik<any>({
      initialValues: initialFieldValues,
      validationSchema: validationSchema,
      onSubmit: () => {
        update({
          additionalDPMetadata: values,
          stage: LoanApplicationStage.REVIEW,
        })
          .then(() => {
            nextStep();
          })
          .catch((err) => {});
      },
    });

  const showHintModal = (field: AdditionalDPMetadata) => {
    setSelectedFieldHint(field.popup);
    setIsOpenModal(true);
  };

  const hideHintModal = () => {
    setSelectedFieldHint(undefined);
    setIsOpenModal(false);
  };

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-width-full">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        More information
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <form onSubmit={handleSubmit}>
          <div>
            {settings.additionalData?.map((field) => (
              <div key={field.key}>
                <InputField
                  key={field.key}
                  label={field.displayName}
                  tick={touched[field.key] && !errors[field.key]}
                  inputProps={{
                    name: field.key,
                    placeholder: field.placeholder,
                    pattern: field.regex,
                    value: values[field.key],
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  error={
                    touched[field.key] ? (errors[field.key] as string) : ""
                  }
                />
                {field.popup && (
                  <div
                    className="cx-w-full cx-mt-4 cx-text-xs cx-font-medium cx-text-text-brand cx-text-center hover:cx-underline cx-cursor-pointer"
                    onClick={() => showHintModal(field)}
                  >
                    Learn more about {field.displayName}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4 cx-mt-10">
            <Button
              outlined
              label={
                <ArrowLeftIcon className="cx-mx-4 hover:cx-text-text-inverse" />
              }
              onClick={previousStep}
            />
            <StepNextButton label="Next" />
          </div>
          <Modal
            isOpen={isOpenModal}
            onClose={hideHintModal}
            title={selectedFieldHint?.label ?? ""}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(selectedFieldHint?.value!),
              }}
            ></div>
            <Button label="Close" fullWidth onClick={hideHintModal} />
          </Modal>
        </form>
      </div>
    </div>
  );
};

export default MoreInformation;
