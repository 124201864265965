import { DateTime } from "luxon";
import { Divider } from "../../components";
import { LoanProductType } from "../../hooks/api/types";
import { roundTo } from "../../utils";
import LoanReviewField from "../../views/LoanApplicationSteps/Review/LoanReviewField";
import { getCopyText } from "./constants";

type Props = {
  loanType: LoanProductType;
  loanConfig: Record<string, any>;
  fixedTermLoan?: Record<string, any>;
  invoiceDiscountingLoan?: {
    invoices: Array<Record<string, any>>;
  };
  payableFinancingLoan?: {
    invoices: Array<Record<string, any>>;
  };
  revenueBasedLoan?: Record<string, any>;
  buyNowPayLaterLoan?: Record<string, any>;
};

const LoanDetailsCard: React.FC<Props> = ({
  loanType,
  loanConfig,
  fixedTermLoan,
  revenueBasedLoan,
  invoiceDiscountingLoan,
  payableFinancingLoan,
  buyNowPayLaterLoan,
}) => {
  const isBusinessLoan = loanType === LoanProductType.FIXED_TERM;
  const isInvoiceFinance = loanType === LoanProductType.INVOICE_DISCOUNTING;
  const isPayableFinancing = loanType === LoanProductType.PAYABLE_FINANCING;
  const isBuyNowPayLaterLoan = loanType === LoanProductType.BNPL;
  const isRevenueBasedLoan =
    loanType === LoanProductType.REVENUE_BASED_FINANCING;
  const isFinanceLoan = isInvoiceFinance || isPayableFinancing;

  const loanInput = isBusinessLoan
    ? fixedTermLoan!
    : isRevenueBasedLoan
    ? revenueBasedLoan!
    : isBuyNowPayLaterLoan
    ? buyNowPayLaterLoan!
    : isInvoiceFinance
    ? invoiceDiscountingLoan?.invoices?.[0]!
    : payableFinancingLoan?.invoices?.[0]!;
  return (
    <>
      <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
        {(isBusinessLoan || isBuyNowPayLaterLoan) && (
          <LoanReviewField
            label="Monthly Payment"
            value={`AED ${roundTo(
              loanInput.totalAmount / loanInput.duration,
              2
            ).toLocaleString()}`}
          />
        )}
        {(isFinanceLoan || isRevenueBasedLoan) && (
          <LoanReviewField
            label={getCopyText(
              "AMOUNT_LABEL",
              isInvoiceFinance ? loanConfig?.repayableByDP : false
            )()}
            value={`AED ${roundTo(
              loanInput.loanAmount * (loanInput?.discountRate ?? 1),
              2
            ).toLocaleString()}`}
          />
        )}
        <Divider />
        <LoanReviewField
          label={getCopyText(
            "INTEREST_LABEL",
            isInvoiceFinance ? loanConfig?.repayableByDP : false
          )()}
          value={`${roundTo((loanInput.interestRate / 12) * 100, 2)}%`}
        />
        {!payableFinancingLoan && (
          <>
            <Divider />
            {fixedTermLoan ? (
              <LoanReviewField
                label="Amount to borrow"
                value={`AED ${roundTo(
                  loanInput.loanAmount,
                  2
                ).toLocaleString()}`}
              />
            ) : (
              <>
                {/*  <LoanReviewField
                   label="Discount rate"
                   value={`${roundTo(loanInput.discountRate * 100, 2)}%`}
                 /> */}
              </>
            )}
          </>
        )}
      </div>
      <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
        <LoanReviewField
          label={getCopyText(
            "DURATION_LABEL",
            isFinanceLoan ? loanConfig?.repayableByDP : false
          )()}
          value={getCopyText(
            "DURATION",
            isFinanceLoan ? loanConfig?.repayableByDP : false
          )(
            isInvoiceFinance && loanConfig?.repayableByDP
              ? DateTime.fromISO(loanInput.invoiceDueDate).toFormat(
                  "dd/MM/yyyy"
                )
              : loanInput.duration,
            loanInput.durationType
          )}
        />
        <Divider />
        <LoanReviewField
          label={getCopyText(
            "REPAYMENT_LABEL",
            isFinanceLoan ? loanConfig?.repayableByDP : false
          )()}
          value={`AED ${roundTo(loanInput.totalAmount, 2).toLocaleString()}`}
        />
        <Divider />
      </div>
    </>
  );
};

export default LoanDetailsCard;
