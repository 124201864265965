import { useState, useEffect, useRef } from "react";

type Time = {
  hours: number;
  minutes: number;
  seconds: number;
};

const useStopwatch = (
  initialHours = 0,
  initialMinutes = 0,
  initialSeconds = 0
): Time & {
  start: (newHours?: number, newMinutes?: number, newSeconds?: number) => void;
  stop: () => void;
} => {
  const [time, setTime] = useState<Time>({
    hours: initialHours,
    minutes: initialMinutes,
    seconds: initialSeconds,
  });
  const intervalRef = useRef<number | null>(null);

  const updateTime = () => {
    setTime((prevTime) => {
      const newTime = { ...prevTime };

      if (newTime.seconds > 0) {
        newTime.seconds--;
      } else if (newTime.minutes > 0) {
        newTime.minutes--;
        newTime.seconds = 59;
      } else if (newTime.hours > 0) {
        newTime.hours--;
        newTime.minutes = 59;
        newTime.seconds = 59;
      } else {
        stop();
      }

      return newTime;
    });
  };

  const start = (
    newHours?: number,
    newMinutes?: number,
    newSeconds?: number
  ): void => {
    if (intervalRef.current === null) {
      intervalRef.current = window.setInterval(updateTime, 1000);
    }

    if (newHours !== undefined) {
      setTime((prevTime) => ({ ...prevTime, hours: newHours }));
    }

    if (newMinutes !== undefined) {
      setTime((prevTime) => ({ ...prevTime, minutes: newMinutes }));
    }

    if (newSeconds !== undefined) {
      setTime((prevTime) => ({ ...prevTime, seconds: newSeconds }));
    }
  };

  const stop = (): void => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      stop();
    };
  }, []);

  return { ...time, start, stop };
};

export default useStopwatch;
