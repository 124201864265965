import Lottie from "react-lottie";
import { SuccessPartyLottie } from "../../assets/lottie";
import { SuccessTickIcon } from "../../assets/icons";

const KYCSuccess = () => {
  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-w-20 cx-h-20 cx-mb-4 cx-relative cx-text-text-brand">
        <div className="cx-absolute cx--top-16 cx--left-20 cx-w-60 cx--z-10">
          <Lottie
            height="100%"
            width="100%"
            options={{
              animationData: SuccessPartyLottie,
              autoplay: true,
            }}
          />
        </div>
        <SuccessTickIcon />
      </div>
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        Congratulations!
      </div>
      <div className="cx-text-text-secondary cx-text-center cx-mb-10">
        You have successfully completed your identity verification.
      </div>
    </div>
  );
};

export default KYCSuccess;
