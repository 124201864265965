import { ReactNode } from "react";
import Button from "../Button/Button";
import { PlusIcon } from "../../assets/icons";
import clsx from "clsx";

type Props = {
  title?: string | ReactNode;
  buttonTitle?: string;
  children: ReactNode;
  onClick?: () => void;
  icon?: ReactNode;
  className?: string;
};

const Card: React.FC<Props> = ({
  title,
  children,
  buttonTitle,
  onClick,
  icon = <PlusIcon className="cx-mr-2" />,
  className = "",
}) => {
  return (
    <div
      className={clsx("cx-w-full cx-max-w-[960px] cx-py-3 cx-relative", {
        [className]: !!className,
      })}
    >
      {title && (
        <div className="cx-flex cx-flex-row cx-justify-between cx-items-center cx-gap-x-2 cx-py-6">
          <div className="cx-grow cx-font-bold cx-text-2xl cx-text-text-primary">
            {title}
          </div>
          {buttonTitle && (
            <Button
              slim
              label={
                <div className="cx-flex cx-items-center">
                  {icon}
                  <div>{buttonTitle}</div>
                </div>
              }
              onClick={onClick}
            />
          )}
        </div>
      )}
      <div className="cx-w-full cx-py-6 cx-rounded-base cx-shadow-base cx-bg-background-default cx-h-full">
        {children}
      </div>
    </div>
  );
};

export default Card;
