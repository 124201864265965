import { useCallback } from "react";
import {
  useGetMedia,
  useMediaFinalizeRequest,
  useMediaUpload,
  useMediaUploadRequest,
} from "./api";
import { MediaPurpose, MediaType } from "./api/types";

export type UploadMediaArgs = {
  loanApplicationId: string;
  file: File;
  type: MediaType;
  purpose: MediaPurpose;
};

type GetMediaArgs = {
  id: string;
};

const useMedia = () => {
  const {
    mutateAsync: getMedia,
    error: getMediaError,
    isLoading: getMediaLoading,
  } = useGetMedia();
  const {
    mutateAsync: uploadRequest,
    isLoading: uploadRequesting,
    error: uploadRequestError,
  } = useMediaUploadRequest();
  const {
    mutateAsync: uploadMedia,
    isLoading: uploading,
    error: uploadMediaError,
  } = useMediaUpload();
  const {
    mutateAsync: uploadFinalize,
    isLoading: uploadFinalizing,
    error: uploadFinalizeError,
  } = useMediaFinalizeRequest();

  const upload = useCallback(
    async (payload: UploadMediaArgs): Promise<string> => {
      const { file, type, purpose, loanApplicationId } = payload;
      const { url, id } = await uploadRequest({
        name: file.name,
        size: file.size,
        contentType: file.type,
        type,
        purpose,
        loanApplicationId: loanApplicationId,
      });

      await uploadMedia({
        url,
        file,
      });

      await uploadFinalize({
        mediaId: id,
      });

      return id;
    },
    [uploadRequest, uploadMedia, uploadFinalize]
  );

  const get = useCallback(
    async (payload: GetMediaArgs) => {
      const { id } = payload;
      if (id) {
        return await getMedia(id);
      }
    },
    [getMedia]
  );

  const isUploading = uploadRequesting || uploading || uploadFinalizing;
  const isLoading = getMediaLoading;
  const isErrored =
    !!uploadRequestError ||
    !!uploadMediaError ||
    !!uploadFinalizeError ||
    !getMediaError;
  const error =
    uploadRequestError ||
    uploadMediaError ||
    uploadFinalizeError ||
    getMediaError;

  return {
    get,
    upload,
    isLoading,
    isUploading,
    isErrored,
    error,
  };
};

export default useMedia;
