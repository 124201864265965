import React from "react";
import { useRouteError } from "react-router-dom";
import Button from "../Button/Button";
import Footer from "../Footer/Footer";
import { CredibleLogoLight } from "../../assets/images";
import { InfoIcon } from "../../assets/icons";
import info from "../../constants/info.json";
import { useSDKContext } from "../../contexts";

type Props = {
  showHeader?: boolean;
  errorText?: string;
};

const RouteErrorUI: React.FC<{ errorText?: string }> = ({ errorText }) => {
  const error: any = useRouteError();

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-max-w-[960px] cx-gap-y-2">
      <div className="cx-w-20 cx-h-20">
        <InfoIcon className="cx-text-stroke-error" />
      </div>
      <div className="cx-text-text-primary cx-text-3xl cx-font-bold">
        {error?.force ? error?.message : "Something went wrong!"}
      </div>
      {!error?.hideDescription && (
        <div className="cx-text-text-secondary cx-text-base cx-font-normal cx-text-center">
          {errorText ||
            ` Oops! it looks like something is not right. Try reloading the page,
          and if the problem persists please contact us at ${info.email}`}
        </div>
      )}
      {!error?.hideActionButton && (
        <Button label="Reload" slim onClick={() => window.location.reload()} />
      )}
    </div>
  );
};

// ErrorBoundary component without route error
const ErrorUI: React.FC<{ errorText?: string }> = ({ errorText }) => {
  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-max-w-[960px] cx-gap-y-2">
      <div className="cx-w-20 cx-h-20">
        <InfoIcon className="cx-text-stroke-error" />
      </div>
      <div className="cx-text-text-primary cx-text-3xl cx-font-bold">
        Something went wrong!
      </div>
      <div className="cx-text-text-secondary cx-text-base cx-font-normal cx-text-center">
        {errorText ||
          ` Oops! it looks like something is not right. Try reloading the page,
          and if the problem persists please contact us at ${info.email}`}
      </div>
      <Button label="Reload" slim onClick={() => window.location.reload()} />
    </div>
  );
};

const ErrorBoundary: React.FC<Props> = ({ showHeader = false, errorText }) => {
  const sdkContext = useSDKContext();

  if (showHeader) {
    return (
      <div className="cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-min-h-screen cx-sticky">
        <div className="cx-flex cx-justify-center cx-items-center cx-w-full cx-border-stroke-primary cx-border-b">
          <div className="cx-flex cx-justify-between cx-items-center cx-w-full cx-max-w-[960px] cx-px-6 cx-py-4">
            <div className="cx-w-36 cx-h-6 cx-flex cx-justify-center cx-items-center">
              <img src={CredibleLogoLight} alt="CredibleX" />
            </div>
          </div>
        </div>
        <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-background-radial-gradient cx-w-full cx-p-8">
          {sdkContext.version == "1" ? (
            <ErrorUI errorText={errorText} />
          ) : (
            <RouteErrorUI errorText={errorText} />
          )}
        </div>
        <div className="cx-w-full cx-p-4">
          <Footer text={info.footer} />
        </div>
      </div>
    );
  }

  return <RouteErrorUI />;
};

export default ErrorBoundary;
