import { FC } from "react";
import clsx from "clsx";
import { Card } from "../../../components";
import {
  HyphenIcon,
  PlusIcon,
  ShareholderUserIcon,
  TickIcon,
} from "../../../assets/icons";

type Props = {
  mode?: "add" | "edit" | "selection";
  name?: string;
  email?: string;
  onClick?: () => void;
  onDelete?: () => void;
  className?: string;
};

const ShareholderCard: FC<Props> = ({
  mode = "add",
  name,
  onClick,
  onDelete,
  email,
  className,
}) => {
  return (
    <div className="cx-w-[250px]">
      <Card className={`cx-h-full ${className} `}>
        {!!onDelete && (
          <div
            className="cx-absolute cx-top-5 cx-right-2 cx-w-6 cx-h-6 cx-text-center cx-rounded-full cx-flex cx-items-center cx-justify-center cx-bg-error-regular cx-text-white cx-cursor-pointer"
            onClick={onDelete}
          >
            <HyphenIcon className="cx-w-4 cx-h-4 cx-text-white" />
          </div>
        )}
        <div
          className="cx-px-4 cx-text-interaction-button-text-default cx-flex cx-flex-col cx-items-center cx-gap-4 cx-min-w-[200px] cx-cursor-pointer cx-h-full"
          onClick={onClick}
        >
          <ShareholderUserIcon className="cx-text-brand-primary-regular cx-w-14 cx-h-14" />
          <p className="cx-text-center cx-grow">{name}</p>

          {mode === "selection" && (
            <p className="cx-text-center cx-text-text-secondary cx-text-sl cx-w-full cx-break-words cx-whitespace-normal">
              {email}
            </p>
          )}

          {mode !== "selection" && (
            <div
              className={clsx(
                "cx-w-8 cx-h-8 cx-text-center cx-rounded-full cx-flex cx-items-center cx-justify-center",
                {
                  "cx-bg-interaction-button-primary-default": mode === "add",
                  "cx-bg-success-regular": mode === "edit",
                }
              )}
            >
              {mode === "add" && (
                <PlusIcon className="cx-text-white cx-w-4 cx-h-4" />
              )}
              {mode === "edit" && (
                <TickIcon className="cx-text-white cx-w-4 cx-h-4" />
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ShareholderCard;
