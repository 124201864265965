import { ReactNode } from "react";
import Button from "../Button/Button";

type Props = {
  label?: string | ReactNode;
  onClick?: () => void;
  type?: "button" | "submit";
};

const StepPrevButton: React.FC<Props> = ({
  label = "Prev",
  onClick,
  type = "button",
}) => {
  return (
    <Button
      type={type}
      outlined
      fullWidth
      label={label}
      {...(onClick ? { onClick } : {})}
    />
  );
};

export default StepPrevButton;
