import clsx from "clsx";
import { ReactNode } from "react";

interface OptionType {
  icon?: ReactNode;
  label: string;
  value?: string;
  isRecommended?: boolean;
  showDisplayValue?: boolean; // true when the value to be stored is different than the value to be display in options
  displayValue?: string | ReactNode;
}

type Props = {
  options: Array<OptionType>;
  keyPropName?: "label" | "value";
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  layout?: "vertical" | "horizontal";
  hideValues?: boolean;
  externalOptionItemStyles?: string;
};

const CardRadioOptions: React.FC<Props> = ({
  options,
  keyPropName = "label",
  inputProps = {},
  layout = "vertical",
  hideValues = false,
  externalOptionItemStyles = "",
}) => (
  <div
    className={clsx(`cx-w-full cx-flex`, {
      "cx-flex-col cx-gap-y-4": layout === "vertical",
      "cx-flex-row cx-gap-x-4 cx-text-center": layout === "horizontal",
    })}
  >
    {options.map((option, index) => (
      <div
        key={option[keyPropName]}
        className={`cx-w-full ${externalOptionItemStyles}`}
      >
        <input
          type="radio"
          id={`${inputProps.name || ""}-radio-option-${index}`}
          name={`${inputProps.name || ""}-radio-option-${index}`}
          className="cx-hidden"
          {...inputProps}
          value={option[keyPropName]}
        />
        <label htmlFor={`${inputProps.name || ""}-radio-option-${index}`}>
          <div
            className={clsx(
              "cx-w-full cx-flex cx-flex-col cx-items-center cx-rounded-lg cx-shadow-base cx-cursor-pointer",
              {
                "cx-border-1.5 cx-border-stroke-brand":
                  inputProps?.value === option[keyPropName],
                "cx-border-1.5 cx-border-stroke-primary":
                  inputProps?.value !== option[keyPropName],
                "cx-opacity-50": inputProps.disabled,
                "cx-p-0.5": option?.isRecommended,
              }
            )}
          >
            {option?.isRecommended && (
              <div className="cx-bg-interaction-button-primary-default cx-w-full cx-rounded-md cx-items-center cx-flex cx-justify-center cx-text-text-inverse cx-text-xs cx-pt-1 cx-pb-1">
                Recommended
              </div>
            )}
            <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4 cx-items-center cx-cursor-pointer cx-p-4">
              {option.icon && (
                <div className="cx-w-6 cx-h-6 cx-flex cx-items-center cx-justify-center cx-rounded cx-overflow-hidden">
                  {option.icon}
                </div>
              )}
              <div className="cx-grow cx-flex cx-flex-col cx-gap-y-1">
                <div className="cx-font-medium cx-text-base cx-text-text-primary">
                  {option.label}
                </div>
                {option.value && !hideValues && (
                  <div className="cx-font-normal cx-text-xs cx-text-text-secondary">
                    {option?.showDisplayValue
                      ? option?.displayValue
                      : option.value}
                  </div>
                )}
              </div>
            </div>
          </div>
        </label>
      </div>
    ))}
  </div>
);

export default CardRadioOptions;
