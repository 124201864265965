import { useContext, useEffect, useState } from "react";
import { StepNextButton } from "../../../components";
import { StepperContext, useLoanApplication } from "../../../contexts";
import {
  LoanApplicationStage,
  LoanProductType,
  ShareholderKYCApplicationStatus,
} from "../../../hooks/api/types";
import {
  InvoiceReviewSection,
  BusinessInformationReviewSection,
  ConnectedBanksReviewSection,
  DocumentsReviewSection,
  LoanInformationReviewSection,
  OwnersInformationReviewSection,
} from "./sections";
import { useIFrameEvents } from "../../../hooks";
import { SumSubKycModal } from "../OwnersInformation";
import ShareHolderDialogModal from "./ShareHolderDialogModal";
import { useDpConfig } from "../../../contexts/dpConfig";

type Props = {};

const ApplicationReview = (props: Props) => {
  const { nextStep } = useContext(StepperContext);
  const {
    actions: { update, refetch },
    state,
  } = useLoanApplication();
  const [kycModalOpen, setKycModalOpen] = useState(false);
  const { postLoanApplicationCompletedEvent } = useIFrameEvents();
  const [shareHolderMessageModalOpen, setShareHolderMessageModalOpen] =
    useState(false);
  const {
    state: { settings },
  } = useDpConfig();

  useEffect(() => {
    refetch();
  }, []);

  const handleNextStep = async () => {
    if (!state?.individualShareholders?.some((s) => s.hasPOA)) {
      setShareHolderMessageModalOpen(true);
    } else {
      // await refetch();
      if (
        [
          ShareholderKYCApplicationStatus.IN_PROGRESS,
          ShareholderKYCApplicationStatus.COMPLETED,
        ].includes(state.applicant.kycApplication?.status) ||
        (!state.applicant.hasPOA && !state.applicant.isStakeholder)
      ) {
        await update({
          ...state,
          stage: LoanApplicationStage.SUCCESS,
        })
          .then(() => {
            nextStep();
            postLoanApplicationCompletedEvent({
              loanApplicationId: state.id,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setKycModalOpen(true);
      }
    }
  };

  return (
    <div className="cx-max-w-[960px] cx-w-full cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-3xl cx-font-bold cx-text-center">
        Review your loan application details
      </div>
      <div className="cx-text-text-secondary cx-text-base cx-text-center cx-mt-2">
        Carefully review all of your details before submitting the application
      </div>
      <LoanInformationReviewSection />
      {(state.loanType === LoanProductType.INVOICE_DISCOUNTING ||
        state.loanType === LoanProductType.PAYABLE_FINANCING) &&
        !settings.disableInvoiceUpload && <InvoiceReviewSection />}
      <BusinessInformationReviewSection />
      <OwnersInformationReviewSection />
      {!settings.disableBankStatementsUpload && <ConnectedBanksReviewSection />}
      <DocumentsReviewSection />
      <div className="cx-w-full cx-max-w-[400px] cx-mt-10 cx-mb-10">
        <StepNextButton
          arrow={"right"}
          label={
            [
              ShareholderKYCApplicationStatus.INITIATED,
              ShareholderKYCApplicationStatus.PENDING,
            ].includes(state.applicant.kycApplication?.status)
              ? "Verify your identity"
              : "Complete"
          }
          // label="Complete"
          loading={state.updating}
          onClick={handleNextStep}
        />
      </div>
      <SumSubKycModal
        isOpen={kycModalOpen}
        onClose={() => setKycModalOpen(false)}
        onSubmit={handleNextStep}
      />
      <ShareHolderDialogModal
        isOpen={shareHolderMessageModalOpen}
        onClose={() => setShareHolderMessageModalOpen(false)}
      />
    </div>
  );
};

export default ApplicationReview;
