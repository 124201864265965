import React from "react";
import { StepConfig } from "../../contexts";

type Props = {
  currentStep: [number, number];
  steps: StepConfig;
};

const ProgressBar = ({ steps, currentStep }: Props) => {
  return (
    <div className="cx-w-full cx-bg-white-80">
      <div className="cx-flex cx-flex-row cx-justify-center cx-w-full cx-px-2 cx-py-4">
        <>
          {steps.map((step, idx) => {
            let status = MilestoneStatusType.CURRENT;
            if (currentStep[0] > idx) status = MilestoneStatusType.COMPLETED;
            if (currentStep[0] < idx) status = MilestoneStatusType.INCOMPLETE;
            return !step.hideProgress ? (
              <Milestone
                key={step.name}
                title={step.name}
                numSubSteps={step.subSteps.length}
                currentSubStep={currentStep[1]}
                status={status}
              />
            ) : null;
          })}
        </>
      </div>
    </div>
  );
};

enum MilestoneStatusType {
  COMPLETED = "completed",
  CURRENT = "current",
  INCOMPLETE = "incomplete",
}

type MilestoneProps = {
  title: string;
  status: MilestoneStatusType;
  numSubSteps?: number;
  currentSubStep?: number;
};

const Milestone = ({
  title,
  numSubSteps,
  currentSubStep,
  status,
}: MilestoneProps) => {
  return (
    <div
      className={`cx-flex-col cx-justify-start cx-items-center cx-m-2 cx-w-47 ${
        status !== "current" ? "cx-hidden sm:cx-flex" : "cx-flex"
      }`}
    >
      <div className="cx-flex cx-flex-row cx-w-full cx-items-center">
        {status === "current" && numSubSteps && numSubSteps > 1 ? (
          <>
            <div className="cx-grow-0 cx-w-1 cx-h-1 cx-bg-brand-primary-regular cx-rounded-checkbox cx-mx-0.5"></div>
            {Array.from({ length: currentSubStep! }, (_, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div className="cx-grow cx-h-0.5 cx-bg-brand-primary-regular cx-rounded-checkbox"></div>
                  <div className="cx-grow-0 cx-w-1 cx-h-1 cx-bg-brand-primary-regular cx-rounded-checkbox cx-mx-0.5"></div>
                </React.Fragment>
              );
            })}
            {Array.from(
              { length: numSubSteps! - currentSubStep! - 1 },
              (_, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <div className="cx-grow cx-h-0.5 cx-bg-background-tertiary cx-rounded-checkbox"></div>
                    <div className="cx-grow-0 cx-w-1 cx-h-1 cx-bg-background-tertiary cx-rounded-checkbox cx-mx-0.5"></div>
                  </React.Fragment>
                );
              }
            )}
          </>
        ) : (
          <>
            {status === "completed" ||
            (numSubSteps === 1 && status === "current") ? (
              <div className="cx-grow cx-h-0.5 cx-bg-brand-primary-regular cx-rounded-checkbox"></div>
            ) : (
              <div className="cx-grow cx-h-0.5 cx-bg-background-tertiary cx-rounded-checkbox"></div>
            )}
          </>
        )}
      </div>
      <div
        className={`cx-text-xs cx-mt-2 cx-text-center cx-uppercase cx-tracking-[1px] ${
          status === "incomplete"
            ? "cx-text-text-tertiary"
            : "cx-text-brand-primary-regular cx-font-semibold"
        }`}
      >
        {title}
      </div>
    </div>
  );
};

export default ProgressBar;
