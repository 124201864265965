import { FC } from "react";
import { Button, Modal } from "../../../components";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ShareHolderDialogModal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Alert">
      <div className="cx-text-text-secondary cx-text-base cx-text-center">
        You have to submit atleast one shareholder with POA to submit the
        application
      </div>
      <div className="cx-flex cx-justify-center cx-mt-10 cx-gap-4">
        <div className="cx-w-1/3">
          <Button label="Ok" outlined fullWidth onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default ShareHolderDialogModal;
