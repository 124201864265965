import { ReactNode, useState } from "react";
import { ConnectIcon, UploadIcon } from "../../../../assets/icons";
import { BoxTabs, Button, Hint } from "../../../../components";

const ConnectBankAccount = ({ selected }: { selected?: boolean }) => {
  return (
    <div
      className={`cx-flex cx-flex-col cx-w-full cx-h-full cx-items-center cx-justify-center cx-rounded-lg cx-overflow-hidden cx-border ${
        selected ? "cx-border-stroke-brand" : "cx-border-stroke-primary"
      }`}
    >
      <div className="cx-bg-background-default cx-rounded-lg cx-p-0.5 cx-w-full">
        <div className="cx-w-full cx-rounded cx-overflow-hidden cx-py-1 cx-px-2 cx-bg-background-brand">
          <div className="cx-text-center cx-text-xs cx-uppercase cx-text-text-inverse">
            Recommended
          </div>
        </div>
        <div className="cx-flex cx-flex-col cx-items-center cx-p-4">
          <div className="cx-w-6 cx-h-6">
            <ConnectIcon className="cx-text-stroke-brand" />
          </div>
          <div className="cx-text-base">Connect bank</div>
        </div>
      </div>
    </div>
  );
};

const UploadStatementTab = ({ selected }: { selected?: boolean }) => {
  return (
    <div
      className={`cx-flex cx-flex-col cx-w-full cx-h-full cx-items-center cx-justify-center cx-rounded-lg cx-overflow-hidden cx-border ${
        selected ? "cx-border-stroke-brand" : "cx-border-stroke-primary"
      }`}
    >
      <div className="cx-w-6 cx-h-6">
        <UploadIcon className="cx-text-interaction-button-primary-default" />
      </div>
      <div className="cx-text-base">Upload statement</div>
    </div>
  );
};

interface FinanceInformationSelectProps {
  title?: string;
  subtitle?: string;
  onSelect?: (selectedTab: "connect" | "upload" | null) => void;
  onClose: () => void;
}
const FinanceInformationSelect: React.FC<FinanceInformationSelectProps> = ({
  title,
  subtitle,
  onSelect,
  onClose,
}) => {
  const [selectedTab, setSelectedTab] = useState<"connect" | "upload" | null>(
    null
  );

  const handleConnectBank = () => {
    setSelectedTab("connect");
  };

  const handleUploadStatement = () => {
    setSelectedTab("upload");
  };

  const handleNextStep = () => {
    if (onSelect) return onSelect(selectedTab);
  };

  return (
    <div className="cx-max-w-[960px] cx-w-full cx-flex cx-flex-col cx-items-center cx-gap-y-10">
      <div>
        <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
          {title}
        </div>
        {subtitle && (
          <div className="cx-text-text-secondary cx-text-sm cx-text-center">
            {subtitle}
          </div>
        )}
      </div>
      <div className="cx-max-w-[400px] cx-w-full">
        <BoxTabs
          tabs={[
            {
              label: "Connect bank",
              component: (
                <ConnectBankAccount selected={selectedTab === "connect"} />
              ),
              onTabClicked: handleConnectBank,
            },
            {
              label: "Upload statement",
              component: (
                <UploadStatementTab selected={selectedTab === "upload"} />
              ),
              onTabClicked: handleUploadStatement,
            },
          ]}
        />
        <div className="cx-mt-4">
          <Hint
            icon
            rounded
            text="Connect your bank to get lower application fees, fast decisioning, and higher credit limit."
          />
        </div>
      </div>
      <div className="cx-max-w-[400px] cx-w-full cx-flex cx-flex-col cx-gap-2">
        <Button
          label="Next"
          fullWidth
          disabled={!selectedTab}
          onClick={handleNextStep}
        />
        <Button label="Cancel" fullWidth outlined onClick={onClose} />
      </div>
    </div>
  );
};

export default FinanceInformationSelect;
