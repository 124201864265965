import { useEffect, useState } from "react";

export type TabProps = {
  label: string;
  labelComponent?: React.ReactNode;
  component: React.ReactNode;
  onTabClicked?: () => void;
};

type Props = {
  startingTab: number;
  tabs: TabProps[];
  disabled?: boolean;
};

const Tabs = ({ startingTab, tabs, disabled }: Props) => {
  const [currentTab, setCurrentTab] = useState(startingTab);

  useEffect(() => {
    if (currentTab !== startingTab) {
      setCurrentTab(startingTab);
    }
  }, [startingTab]);

  return (
    <>
      <div className="cx-flex cx-flex-row cx-rounded-lg cx-bg-neutral-lighter">
        {tabs.map((tab, idx) => {
          let tabStyle: string;
          if (currentTab === idx) {
            tabStyle = disabled
              ? "cx-bg-white cx-text-text-tertiary cx-cursor-not-allowed"
              : "cx-shadow-tab cx-cursor-pointer cx-bg-white";
          } else {
            tabStyle = disabled
              ? "cx-text-text-tertiary cx-cursor-not-allowed"
              : "cx-text-text-secondary cx-cursor-pointer";
          }
          return (
            <div
              key={idx}
              className={`${tabStyle} cx-flex cx-justify-center cx-self-stretch cx-items-center cx-grow cx-basis-0 cx-shrink-0 cx-text-center cx-m-1 cx-rounded-lg cx-p-2`}
              onClick={() => {
                if (disabled) return;
                setCurrentTab(idx);
                if (tab.onTabClicked) {
                  tab.onTabClicked();
                }
              }}
            >
              {tab.labelComponent && currentTab == idx
                ? tab.labelComponent
                : tab.label}
            </div>
          );
        })}
      </div>
      {tabs[currentTab]?.component}
    </>
  );
};

export default Tabs;
