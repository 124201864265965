import { FC, useEffect, useMemo } from "react";
import { InputField, NumericInputField } from "../../../components";
import { FormikProps } from "formik";
import {
  ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER,
  InformationFormFields,
  TAX_PERCENTAGE_MULTIPLIER,
} from "../BusinessInformation/service";

type TaxAndRevenueFormProps = {
  form: FormikProps<InformationFormFields>;
};

export const TaxAndRevenueForm: FC<TaxAndRevenueFormProps> = ({
  form: {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
  },
}) => {
  const shouldApplyTax = useMemo(() => {
    return values.annualRevenue >= ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER;
  }, [values.annualRevenue]);

  useEffect(() => {
    setFieldValue(
      "lastYearPaidTax",
      shouldApplyTax ? values.annualRevenue * TAX_PERCENTAGE_MULTIPLIER : 0
    );
    if (!shouldApplyTax) {
      setFieldValue("taxRegistrationNumber", "");
    }
  }, [shouldApplyTax, values.annualRevenue]);

  return (
    <div className="cx-w-full cx-mt-6 cx-gap-[16px] cx-flex cx-flex-col">
      <div className="cx-w-full">
        <NumericInputField
          label="Previous year annual revenue"
          isValid={touched.annualRevenue && !errors.annualRevenue}
          value={values.annualRevenue}
          error={
            touched.annualRevenue && !!errors.annualRevenue
              ? errors.annualRevenue
              : ""
          }
          setValue={(value) => {
            setFieldValue("annualRevenue", value);
          }}
        />
      </div>

      {shouldApplyTax && (
        <>
          <div className="cx-w-full">
            <InputField
              label="Tax registration number"
              tick={
                touched.taxRegistrationNumber && !errors.taxRegistrationNumber
              }
              error={
                touched.taxRegistrationNumber && !!errors.taxRegistrationNumber
                  ? errors.taxRegistrationNumber
                  : ""
              }
              inputProps={{
                name: "taxRegistrationNumber",
                value: values.taxRegistrationNumber,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
            />
          </div>
          <div className="cx-w-full">
            <NumericInputField
              label="Total VAT Paid in the Previous Year"
              isValid={touched.lastYearPaidTax && !errors.lastYearPaidTax}
              value={values.lastYearPaidTax}
              error={
                touched.lastYearPaidTax && !!errors.lastYearPaidTax
                  ? errors.lastYearPaidTax
                  : ""
              }
              setValue={(value) => {
                setFieldValue("lastYearPaidTax", value);
              }}
              onBlur={() => setFieldTouched("lastYearPaidTax")}
            />
          </div>
        </>
      )}
    </div>
  );
};
