import { LoanApplication, LoanPaymentStatus } from "../../../contexts";
import { FC, useEffect, useMemo } from "react";
import { sortBy } from "lodash";
import { useLoanApplicationPayments } from "../../../hooks";
import { useLoanApplicationPaymentsEnabled } from "../../../hooks/useLoanApplicationPaymentsEnabled";
import Transaction from "../../LoanCalculator/Transaction";
import { DateTime } from "luxon";

type LoanPaymentScheduleProps = {
  loan: LoanApplication;
};

export const LoanPaymentSchedule: FC<LoanPaymentScheduleProps> = ({ loan }) => {
  const loanPaymentsEnabled = useLoanApplicationPaymentsEnabled(loan);

  const { data: loanPayments = [] } = useLoanApplicationPayments(
    loan.id,
    loanPaymentsEnabled
  );

  const sortedPayments = useMemo(() => {
    return loanPayments
      .map((payment) => ({
        ...payment,
        date: DateTime.fromISO(payment.date),
      }))
      .sort((a, b) => a.date.toMillis() - b.date.toMillis());
  }, [loanPayments]);

  if (!loanPayments) return null;

  return (
    <div>
      <div className="cx-w-full cx-flex cx-flex-row cx-items-center cx-justify-between cx-py-4">
        <div className="cx-text-text-primary cx-font-semibold cx-text-lg">
          Payments
        </div>
      </div>

      <div className="cx-w-full cx-flex cx-flex-col cx-rounded-xl cx-border-stroke-primary cx-border cx-p-8">
        <div className="cx-w-full">
          {sortedPayments.map((payment, index) => (
            <Transaction
              key={index}
              installmentNo={index}
              isLast={index === loan?.buyNowPayLaterLoan.duration + 1}
              isFirst={index === 0}
              monthlyPayable={payment.amount}
              currencyUnit={loan?.loanProduct?.config?.amount?.unit}
              date={payment.date.toFormat("dd MMM yyyy")}
              isReviewScreen={true}
              loanPaymentStatus={payment?.status}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoanPaymentSchedule;
