export const LOCALSTORAGE_KEYS = {
  TOKEN: "__cx-token",
  CLIENT_ID: "__cx-client-id",
  SESSION_TOKEN: "__cx-session-token",
  LOAN_APPLICATION_ID: "__cx-loan-application-id",
  LOAN_APPLICATION_EXTERNAL_ID: "__cx-loan-application-external-id",
};

export const TEXT_GRADIENT_STYLE = {
  from: {
    color: "cx-from-brand-tertiary-regular",
    upto: "cx-from-50%",
  },
  to: {
    color: "cx-to-brand-primary-regular",
    upto: "cx-to-80%",
  },
  isText: true,
};
