import { LoanProductType } from "../../hooks/api/types";

type CopyType = keyof {
  repayableByDP: (...args: any[]) => string;
  showAnnualInterestOnOffer: (...args: any[]) => string;
  directDebitRequired: (...args: any[]) => string;
  loanType: (...args: any[]) => string;
};

const PAGE_COPY: {
  [key: string]: {
    default: (...args: any[]) => string;
  } & {
    [K in CopyType]?: (...args: any[]) => string;
  };
} = {
  TOTAL_INTEREST_PAID: {
    default: () => "Total interest paid",
    repayableByDP: () => "Total fees paid",
  },
  INTEREST_RATE: {
    default: () => "Monthly Interest rate",
    showAnnualInterestOnOffer: () => "Annual Interest rate",
    loanType: (loanType: LoanProductType) => {
      if (
        loanType === LoanProductType.PAYABLE_FINANCING ||
        loanType === LoanProductType.INVOICE_DISCOUNTING ||
        loanType === LoanProductType.BNPL
      ) {
        return "Monthly Interest rate";
      } else {
        return "Annual Interest rate";
      }
    },
  },
  TOTAL_REPAYMENT: {
    default: () => "Total disbursal amount",
    repayableByDP: () => "Total payable amount",
    loanType: (loanType: LoanProductType) =>
      loanType === LoanProductType.PAYABLE_FINANCING
        ? "Repayment Amount"
        : "Total disbursal amount",
  },
  SELECT_ACCOUNT: {
    default: () => "Select bank account for loan transfer",
    directDebitRequired: () => "Select bank account",
  },
  LOAN_TRANSFER: {
    default: () =>
      "Please specify your primary bank account for loan disbursement and payment collection",
    directDebitRequired: () =>
      `Please specify your primary bank account for loan disbursement and repayments`,
  },
  ADD_ACCOUNT: {
    default: () => "Add direct debit account",
    directDebitRequired: () => "Add bank account to transfer funds",
  },
  LOAN_TRANSFER_ADD: {
    default: () =>
      "Please add your primary bank account for loan disbursement and payment collection",
    directDebitRequired: () =>
      `Please add your primary bank account for loan transfer`,
  },
};

export function getCopyText(
  key: string,
  flagName: CopyType,
  flagValue: boolean
) {
  return PAGE_COPY[key][flagValue ? flagName : "default"];
}
