import { FC, useRef } from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { DatePickerIcon, TickIcon } from "../../assets/icons";
import clsx from "clsx";
import { createPortal } from "react-dom";

type Props = {
  onChange: (date: Date) => void;
  onBlur?: () => void;
  selectedDate?: Date;
  error?: string;
  className?: string;
  min?: Date;
  max?: Date;
  showDatePickerIcon?: boolean;
  tick?: boolean;
  placeholderText?: string;
  textAlignment?: "right" | "left" | "center";
};

const DatePicker: FC<Props> = ({
  selectedDate,
  onChange,
  onBlur,
  error,
  className = "",
  min,
  max,
  showDatePickerIcon = true,
  tick = false,
  placeholderText = "Select date",
  textAlignment = "right",
}) => {
  const ref = useRef<any>(null);
  return (
    <div className="cx-flex cx-flex-col">
      <div
        className={clsx(
          "cx-w-full cx-rounded-base cx-border-2 cx-bg-background-default cx-border-stroke-primary cx-flex cx-items-center cx-pl-3 cx-gap-2 cx-py-4 cx-pr-3",
          { "cx-border-stroke-error": !!error, [className]: !!className }
        )}
      >
        {showDatePickerIcon && (
          <DatePickerIcon
            className="cx-text-text-brand cx-w-6 cx-h-6"
            onClick={() => {
              console.log(ref.current);
              ref.current?.toggleCalendar();
            }}
          />
        )}
        <ReactDatePicker
          className={`cx-bg-transparent cx-appearance-none cx-outline-none cx-w-full cx-rounded-base cx-text-${textAlignment}`}
          selected={selectedDate}
          onChange={(date) => onChange(date!)}
          onBlur={onBlur}
          placeholderText={placeholderText}
          startDate={new Date()}
          dateFormat="dd/MM/yyyy"
          ref={ref as any}
          wrapperClassName="cx-w-full"
          popperClassName="cx-z-[100]"
          minDate={min}
          maxDate={max}
          showIcon={false}
          popperContainer={({ children }) =>
            createPortal(children, document.body)
          }
        />
        {tick && (
          <TickIcon height="100%" className="cx-stroke-success-regular" />
        )}
      </div>
      {error && (
        <div className="cx-text-xs cx-py-1 cx-text-text-error">{error}</div>
      )}
    </div>
  );
};

export default DatePicker;
