import { FC, useContext, useEffect, useState } from "react";
import {
  IndividualShareholder,
  StepperContext,
  useLoanApplication,
} from "../../../contexts";
import { LoanApplicationStage } from "../../../hooks/api/types";
import SumSubKyc from "./SumSubKyc";
import ShareHolderSumSubKyc from "./ShareHolderSumSubKyc";
import { Modal } from "../../../components";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  shareholder?: IndividualShareholder;
};

const ShareholderKycFormModal: FC<Props> = ({
  isOpen,
  onClose,
  shareholder,
}) => {
  const {
    state: { individualShareholders },
    actions: { update },
  } = useLoanApplication();

  useEffect(() => {
    if (individualShareholders) {
      const addedShareholder = individualShareholders.find(
        (sh) => shareholder?.email === sh.email
      );
      update(
        {
          shareHolderPendingKYC: addedShareholder,
        },
        {
          local: true,
        }
      );
    }
  }, [individualShareholders]);

  const handleNext = () => {
    update({
      stage: LoanApplicationStage.SHAREHOLDER_INFORMATION,
    }).then(() => {
      onClose();
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className=" cx-max-w-[600px] cx-w-[600px]"
      title="ShareHolder KYC"
    >
      <ShareHolderSumSubKyc onSubmit={handleNext} />;
    </Modal>
  );
};

export default ShareholderKycFormModal;
