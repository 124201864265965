import { FC, useEffect, useState } from "react";
import { Button, Card } from "../../components";
import { formatAmount } from "../../utils";
import { LoanDashboardFormCard } from "./LoanDashboardForm";
import { InfoIcon } from "../../assets/icons";
import { PaymentHistoryHistogram } from "./PaymentHistoryHistogram";
import { useLoanDashboard } from "../../contexts";
import { usePaymentSummary } from "../../hooks";
import {
  LoanContractLoanFrequency,
  PaymentSummaryResponseDTO,
} from "../../hooks/api/types";

const LoanFrequencyDurationMapping = new Map([
  [LoanContractLoanFrequency.MONTHLY, "months"],
  [LoanContractLoanFrequency.BIWEEKLY, "fortnights"],
  [LoanContractLoanFrequency.DAYS, "days"],
]);

//todo: refactor to use API to get and pass data or use value in state
const LoanPaymentHistoryDashboard: FC = () => {
  const {
    state: { currentLoanApplicationId },
    actions: { update },
  } = useLoanDashboard();
  const { data: paymentSummaryData, isLoading } = usePaymentSummary(
    currentLoanApplicationId
  );
  const [paymentSummary, setPaymentSummary] =
    useState<PaymentSummaryResponseDTO>();

  useEffect(() => {
    if (paymentSummaryData) {
      setPaymentSummary(paymentSummaryData);
    }
  }, [paymentSummaryData, isLoading]);

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-background-radial-gradient cx-w-full cx-p-8">
      <Card
        title={
          <div className="cx-flex cx-flex-row cx-content-center cx cx-items-center">
            <Button
              label="Back"
              arrow="left"
              invertArrowDirection={true}
              arrowClassName="cx-stroke-2 cx-h-4 cx-w-4"
              slim={true}
              onClick={() => update({ showPaymentHistory: false })}
              className="cx-text-s cx-font-semibold cx-py-2 cx-px-3 cx-h-8 cx-mr-2"
            />
            <div className="cx-text-3xl">Summary</div>
          </div>
        }
      >
        <div className="sm:cx-flex cx-flex-wrap cx-items-center cx-py-4 cx-px-2 sm:cx-divide-x sm:cx-divide-neutral-light">
          <LoanDashboardFormCard label="Due this month">
            <div className="cx-grow cx-flex cx-flex-col cx-justify-center">
              <div className="cx-grow cx-flex cx-items-baseline cx-justify-center">
                <div className="cx-text-text-primary cx-font-medium cx-text-xl cx-mr-1">
                  AED
                </div>
                <div className="cx-text-text-primary cx-font-bold cx-text-3xl">
                  {isLoading || !paymentSummary?.monthDueAmount
                    ? "--"
                    : formatAmount(paymentSummary.monthDueAmount)}
                </div>
              </div>
              <div className="cx-text-text-secondary cx-font-medium cx-text-base cx-text-center">
                of AED{" "}
                {isLoading || !paymentSummary?.monthInstallmentAmount
                  ? "--"
                  : formatAmount(paymentSummary.monthInstallmentAmount)}
              </div>
            </div>
          </LoanDashboardFormCard>
          <LoanDashboardFormCard label="Total outstanding">
            <div className="cx-grow cx-flex cx-flex-col cx-justify-center">
              <div className="cx-grow cx-flex cx-items-baseline cx-justify-center">
                <div className="cx-text-text-primary cx-font-medium cx-text-xl cx-mr-1">
                  AED
                </div>
                <div className="cx-text-text-primary cx-font-bold cx-text-3xl">
                  {isLoading || !paymentSummary?.totalInstallmentAmount
                    ? "--"
                    : formatAmount(paymentSummary.totalOutstandingAmount)}
                </div>
              </div>
              <div className="cx-text-text-secondary cx-font-medium cx-text-base cx-text-center">
                of AED{" "}
                {isLoading || !paymentSummary?.totalInstallmentAmount
                  ? "--"
                  : formatAmount(paymentSummary.totalInstallmentAmount)}
              </div>
            </div>
          </LoanDashboardFormCard>
        </div>
      </Card>
      <Card title={<div className="cx-text-3xl">Payment history</div>}>
        <div className="cx-flex cx-flex-col cx-items-center cx-content-center">
          <div className="cx-w-full cx-py-3 cx-px-6">
            <div className="cx-w-full cx-flex cx-place-content-between">
              {paymentSummary?.loanFrequency && paymentSummary.tenure && (
                <div className="cx-flex">
                  Loan duration {paymentSummary?.tenure}{" "}
                  {LoanFrequencyDurationMapping.get(
                    paymentSummary?.loanFrequency
                  )}
                </div>
              )}
              <div className="cx-flex cx-items-center">
                <div className="cx-px-2 cx-cursor-pointer">
                  <InfoIcon width={16} height={16} color="#0064FF" />
                </div>
                <div className="cx-grow cx-text-text-secondary cx-text-sm cx-font-normal">
                  Click on a month to view detailed transaction history
                </div>
              </div>
            </div>
          </div>
          <PaymentHistoryHistogram />
        </div>
      </Card>
    </div>
  );
};

export default LoanPaymentHistoryDashboard;
