import * as yup from "yup";
import { LoanProductReason, LoanProductType } from "../../../hooks/api/types";

export type PhoneNumberFormFields = {
  countryCode: string;
  localNumber: string;
};

export type EmailFormFields = {
  email: string;
};

export type OTPFormFields = {
  otp: string;
};

export const PHONE_NUMBER_FORM_INITIAL_VALUES: PhoneNumberFormFields = {
  countryCode: "+971",
  localNumber: "",
};

export const EMAIL_FORM_INITIAL_VALUES: EmailFormFields = {
  email: "",
};

export const OTP_FORM_INITIAL_VALUES: OTPFormFields = {
  otp: "",
};

export const PHONE_NUMBER_SCHEMA = yup
  .string()
  .required("Please enter a valid UAE phone number")
  .test("is-UAE-phone", "Please enter a valid UAE phone number", (value) => {
    const phone = value.replace(/\s/g, "");
    return /^5\d{8}$/.test(phone);
  });

export const PHONE_NUMBER_FORM_VALIDATION_SCHEMA = yup.object().shape({
  countryCode: yup
    .string()
    .required("Required")
    .oneOf(["+971"], "Must be a valid UAE number"),
  localNumber: PHONE_NUMBER_SCHEMA,
});

export const EMAIL_FORM_VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().required("Required").email("Must be a valid Email"),
});

export const OTP_FORM_VALIDATION_SCHEMA = yup.object().shape({
  otp: yup
    .string()
    .required("Please enter the SMS OTP to proceed.")
    .matches(/^\d+$/, "Please enter a valid OTP")
    .min(6, "Please enter a valid OTP")
    .max(6, "Please enter a valid OTP"),
});

export const LoanReasonMap: Map<string, { type: string; label: string }> =
  new Map([
    [
      LoanProductReason.PAY_SUPPLIER,
      {
        type: LoanProductType.PAYABLE_FINANCING,
        label: "I need to pay my supplier",
      },
    ],
    [
      LoanProductReason.PAY_UNEXPECTED_BILL,
      {
        type: LoanProductType.PAYABLE_FINANCING,
        label: "I received some unexpected bills",
      },
    ],
    [
      LoanProductReason.GROW_BUSINESS,
      {
        type: LoanProductType.REVENUE_BASED_FINANCING,
        label: "I want to grow my business",
      },
    ],
    [
      LoanProductReason.EXPAND_NEW_LOCATION,
      {
        type: LoanProductType.REVENUE_BASED_FINANCING,
        label: "I am expanding to a new locations",
      },
    ],
    [
      LoanProductReason.BUY_NEW_EQUIPMENT,
      {
        type: LoanProductType.REVENUE_BASED_FINANCING,
        label: "I am planning to purchase new equipment or software",
      },
    ],
    [
      LoanProductReason.COVER_CASH_FLOW_GAP,
      {
        type: LoanProductType.PAYABLE_FINANCING,
        label: "I am covering cash flow gaps",
      },
    ],
    [
      LoanProductReason.COVER_OPERATIONAL_EXPENSE,
      {
        type: LoanProductType.REVENUE_BASED_FINANCING,
        label: "I need to cover day to day operational expense",
      },
    ],
    [
      LoanProductReason.BUY_NEW_INVENTORY,
      {
        type: LoanProductType.REVENUE_BASED_FINANCING,
        label: "I am buying new inventory",
      },
    ],
    [
      LoanProductReason.NEED_WORKING_CAPITAL,
      {
        type: LoanProductType.REVENUE_BASED_FINANCING,
        label: "I need working capital",
      },
    ],
    [
      LoanProductReason.RECRUIT_STAFF,
      {
        type: LoanProductType.REVENUE_BASED_FINANCING,
        label: "Recruiting staff",
      },
    ],
    [
      LoanProductReason.UNPAID_INVOICES,
      {
        type: LoanProductType.INVOICE_DISCOUNTING,
        label: "I have unpaid invoices",
      },
    ],
  ]);
