import { useEffect, useState } from "react";
import { AppThemeConfig, DeepPartial } from "../../types";
import { createTheme } from "../../utils";

const CSS_BG_VARS = {
  PRIMARY: "--cx-color-brand-primary-regular",
  SECONDARY: "--cx-color-brand-secondary-regular",
  TERTIARY: "--cx-color-brand-tertiary-regular",
};

const CSS_RADIAL_VARS = {
  BACKGROUND: {
    START: "--cx-color-radial-start",
    END: "--cx-color-radial-end",
  },
  CALCULATOR: {
    START: "--cx-color-calculator-radial-start",
    END: "--cx-color-calculator-radial-end",
  },
};

export const useTheme = () => {
  const [themeLoading, setThemeLoading] = useState(false);

  const applyTheme = (themeConfig: AppThemeConfig) => {
    const root = document.documentElement;
    const theme = createTheme(themeConfig);
    Object.entries(theme).forEach(([key, value]) => {
      if (value) {
        root.style.setProperty(key, value);
      }
    });
  };

  const applyThemeBackground = (themeConfig?: AppThemeConfig) => {
    const brandPrimaryReg =
      themeConfig?.colors?.primary?.regular ||
      window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(CSS_BG_VARS.PRIMARY);
    const brandTertiaryReg =
      themeConfig?.colors?.primary?.tertiary ||
      window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(CSS_BG_VARS.TERTIARY);

    // Configure for radial background
    const radialBgStart = brandPrimaryReg + "33";
    const radialBgEnd = brandTertiaryReg + "00";

    document.documentElement.style.setProperty(
      CSS_RADIAL_VARS.BACKGROUND.START,
      radialBgStart
    );
    document.documentElement.style.setProperty(
      CSS_RADIAL_VARS.BACKGROUND.END,
      radialBgEnd
    );

    const radialCalculatorStart = brandPrimaryReg + "15";
    const radialCalculatorEnd = brandTertiaryReg + "00";

    document.documentElement.style.setProperty(
      CSS_RADIAL_VARS.CALCULATOR.START,
      radialCalculatorStart
    );

    document.documentElement.style.setProperty(
      CSS_RADIAL_VARS.CALCULATOR.END,
      radialCalculatorEnd
    );
  };

  const setTheme = (themeConfig?: DeepPartial<AppThemeConfig>) => {
    setThemeLoading(true);
    applyThemeBackground(themeConfig);
    if (themeConfig) {
      applyTheme(themeConfig);
    }
    setThemeLoading(false);
  };

  return { themeLoading, setTheme };
};
