import { useNavigate } from "react-router-dom";
import { StepNextButton } from "../../components";

const LoanOfferDeclined = () => {
  const navigate = useNavigate();

  const handleGotoDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div className="cx-grow cx-w-full cx-max-w-[960px] cx-flex cx-flex-col cx-items-center cx-justify-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-my-2">
        Loan offer is declined
      </div>
      <div className="cx-text-text-secondary cx-text-center cx-mb-10">
        Your loan application is declined and will be removed from your account.
      </div>
      {/* <div className="cx-w-full cx-max-w-[400px]">
        <StepNextButton label="Go to dashboard" onClick={handleGotoDashboard} />
      </div> */}
    </div>
  );
};

export default LoanOfferDeclined;
