import React from "react";
import Popup from "reactjs-popup";
import { EventType } from "reactjs-popup/dist/types";

type Props = {
  content: string | JSX.Element;
  trigger: JSX.Element;
  open?: boolean;
  openOn?: EventType | EventType[];
  closeOnDocumentClick?: boolean;
  isEnabled?: boolean;
  position?:
    | "top left"
    | "top center"
    | "top right"
    | "right top"
    | "right center"
    | "right bottom"
    | "bottom left"
    | "bottom center"
    | "bottom right"
    | "left top"
    | "left center"
    | "left bottom"
    | "center center";
};

const Tooltip: React.FC<Props> = ({
  content,
  trigger,
  open = false,
  closeOnDocumentClick = true,
  openOn,
  position = "bottom left",
  isEnabled = true,
}) => {
  if (isEnabled) {
    return (
      <Popup
        className="credible-tooltip"
        trigger={trigger}
        open={open}
        closeOnDocumentClick={closeOnDocumentClick}
        on={openOn}
        position={position}
      >
        <div className="cx-text-text-inverse cx-font-normal cx-text-xs">
          {content}
        </div>
      </Popup>
    );
  } else {
    return <>{trigger}</>;
  }
};

export default Tooltip;
