import { FC, useEffect } from "react";
import {
  CompanyShareholderFields,
  COMPANY_SHAREHOLDER_INITIAL_VALUES,
  COMPANY_SHAREHOLDER_VALIDATION_SCHEMA,
  isEmailUniqueInShareholders,
  BOOLEAN_CARD_OPTIONS,
} from "../service";
import { useFormik } from "formik";
import {
  Button,
  CardRadioOptions,
  Divider,
  InputField,
  StepNextButton,
} from "../../../../components";
import { useLoanApplication } from "../../../../contexts";

type Props = {
  shareholder?: CompanyShareholderFields;
  onSubmit: (values: CompanyShareholderFields) => void;
  onCancel: () => void;
};

const CompanyShareholderForm: FC<Props> = ({
  shareholder,
  onSubmit,
  onCancel,
}) => {
  const {
    state: { individualShareholders, companyShareholders },
    actions: { update, refetch },
  } = useLoanApplication();

  const {
    values,
    errors,
    handleSubmit,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik<CompanyShareholderFields>({
    enableReinitialize: true,
    initialValues: shareholder ?? COMPANY_SHAREHOLDER_INITIAL_VALUES,
    validationSchema: COMPANY_SHAREHOLDER_VALIDATION_SCHEMA,
    onSubmit: async (values, { setFieldError }) => {
      const shareholders = [...individualShareholders, ...companyShareholders];
      const uniqueEmail = isEmailUniqueInShareholders(
        values.email,
        shareholders,
        shareholder
      );
      if (!uniqueEmail) {
        setFieldError("email", "Email already exists for another shareholder");
        return;
      }

      await update({
        companyShareholders: [...companyShareholders, values],
      });
      await refetch();
      onSubmit(values);
    },
  });
  return (
    <div className="cx-flex cx-flex-col">
      <form onSubmit={handleSubmit}>
        <div className="cx-mb-4">
          <InputField
            label="Name"
            tick={touched.name && !errors.name}
            inputProps={{
              name: "name",
              value: values.name,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            error={touched.name && !!errors.name ? errors.name : ""}
          />
        </div>
        <div className="cx-flex cx-mb-4">
          <div className="cx-max-w-[120px] cx-mr-1">
            <InputField
              label="Country code"
              inputProps={{
                name: "countryCode",
                value: "🇦🇪 " + values.countryCode,
                readOnly: true,
              }}
              error={touched.countryCode ? errors.countryCode : ""}
            />
          </div>
          <div className="cx-ml-1 cx-w-full">
            <InputField
              label="Phone number"
              inputProps={{
                name: "localNumber",
                value: values.localNumber,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              error={touched.localNumber ? errors.localNumber : ""}
            />
          </div>
        </div>
        <div className="cx-mb-4">
          <InputField
            label="Business email"
            tick={touched.email && !errors.email}
            error={touched.email && !!errors.email ? errors.email : ""}
            inputProps={{
              name: "email",
              value: values.email,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
          />
        </div>
        <div className="cx-my-10">
          <div className="cx-font-semibold cx-text-text-primary cx-text-base cx-mb-4 cx-mt-10">
            Has authority to take out loan on behalf of the company?
          </div>
          <div className="cx-flex cx-justify-between cx-mb-4">
            <CardRadioOptions
              options={BOOLEAN_CARD_OPTIONS}
              inputProps={{
                name: "hasPOA",
                value: `${values.hasPOA}`,
                onChange: (e) => {
                  setFieldValue("hasPOA", e.target.value === "true");
                },
              }}
              layout="horizontal"
              keyPropName="value"
              hideValues
            />
          </div>
          <div className="cx-my-10">
            <Divider />
          </div>
        </div>
        <div className="cx-mt-4 cx-w-full cx-flex cx-items-center cx-gap-4">
          <div className="cx-w-1/3">
            <Button
              outlined
              fullWidth
              label="X"
              type="button"
              onClick={onCancel}
            />
          </div>
          <StepNextButton
            label={`${shareholder ? "Update" : "Add"} shareholder`}
          />
        </div>
      </form>
    </div>
  );
};

export default CompanyShareholderForm;
