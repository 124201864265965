import * as yup from "yup";
import { FIVE_MB_IN_BYTES } from "./CONSTANTS";
import { validateFileSize } from "../../../utils";

export interface CustomFileType extends File {
  id?: string;
  error?: string;
}

export class CustomFile extends File implements CustomFileType {
  id?: string;
  error?: string;

  constructor(file: File, id?: string, error?: string) {
    super([file], file.name, {
      type: file.type,
      lastModified: file.lastModified,
    });
    this.id = id;
    this.error = error;
  }
}

export type DocumentsUploadFormFields = {
  vatStatements: CustomFile[];
};

export type MissingDocumentsUploadFormFields = {
  documents: CustomFile[];
};

export type GenericDocumentsUploadFormFields = {
  genericDocuments: CustomFile[];
};

export type UploadStatementsFormFields = {
  statements: CustomFileType[];
};

export type AllDocumentsFormFields = {
  documents: CustomFile[];
  mediaPurpose: string;
};

export const DOCUMENTS_UPLOAD_FORM_INITIAL_VALUES: DocumentsUploadFormFields = {
  vatStatements: [],
};

export const MISSING_DOCUMENTS_UPLOAD_FORM_INITIAL_VALUES: MissingDocumentsUploadFormFields =
  {
    documents: [],
  };

export const UPLOAD_STATEMENTS_FORM_INITIAL_VALUES: UploadStatementsFormFields =
  {
    statements: [],
  };

export const DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA = yup.object().shape({
  vatStatements: yup
    .array()
    .of(
      yup
        .mixed()
        .test("fileType", "Please provide a valid PDF file", (value: any) => {
          return value?.type === "application/pdf";
        })
        .test("fileSize", "File is larger than 5 MB", validateFileSize(5))
    )
    .min(1, "At least one statement is required")
    .max(6, "Maximum 6 vat statements are allowed")
    .required("Vat Statement is required"),
});

export const MISSING_DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    documents: yup
      .array()
      .of(
        yup
          .mixed()
          .test("fileType", "Please provide a valid PDF file", (value: any) => {
            return value?.type === "application/pdf";
          })
          .test("fileSize", "File is larger than 5 MB", validateFileSize(5))
      )
      .min(1, "At least one document is required")
      .max(6, "Maximum 6 documents are allowed")
      .required("Document is required"),
  });
export const GENERIC_DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    genericDocuments: yup
      .array()
      .of(
        yup
          .mixed()
          .test("fileSize", "File is larger than 5 MB", validateFileSize(5))
      )
      .min(0)
      .optional(),
  });

export const ALL_DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA = yup.object().shape({
  documents: yup.array().of(
    yup
      .mixed()
      .test("fileType", "Please provide a valid PDF file", (value: any) => {
        return value?.type === "application/pdf";
      })
      .test("fileSize", "File is larger than 5 MB", validateFileSize(5))
  ),
  mediaPurpose: yup.string().required("Document purpose is required"),
});

export const UPLOAD_STATEMENTS_FORM_VALIDATION_SCHEMA = yup.object().shape({
  statements: yup
    .array()
    .of(
      yup
        .mixed()
        .test("fileType", "Please provide a valid PDF file", (value: any) => {
          return value?.type === "application/pdf";
        })
        .test("fileSize", "File is larger than 5 MB", validateFileSize(5))
    )
    .min(1, "At least one statement is required")
    .required("Statement is required"),
});
