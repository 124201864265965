import { useMemo } from "react";

type OTPInputFieldProps = {
  value: string;
  handleChange: (value: string) => void;
  error?: boolean;
};

const OTPInputField: React.FC<OTPInputFieldProps> = ({
  value,
  handleChange,
  error = false,
}) => {
  return (
    // <div className="cx-flex cx-items-center cx-max-w-[56px]">
    <input
      className={`cx-h-14 cx-w-full cx-max-w-[56px] cx-px-2 cx-py-4 cx-text-text-primary cx-font-normal cx-text-center cx-rounded-base cx-border-2 ${
        error ? "cx-border-stroke-error" : "cx-border-stroke-primary"
      }`}
      value={value}
      maxLength={1}
      type="tel"
      // Changed from onKeyDown to onKeyUp, so that the value is updated before the focus is changed
      onKeyUp={(e) => {
        if (e.key === "Backspace") {
          const previousSibling = e.currentTarget
            .previousElementSibling as HTMLInputElement | null;
          if (previousSibling) previousSibling.focus();
          handleChange("");
        } else if (e.key === e.currentTarget.value) {
          const nextElementSibling = e.currentTarget
            .nextElementSibling as HTMLInputElement | null;
          if (nextElementSibling) nextElementSibling.focus();
        } else {
          e.currentTarget.select();
        }
      }}
      onChange={(e) => {
        if (/^\d+$/.test(e.target.value)) {
          const nextSibling = e.currentTarget
            .nextElementSibling as HTMLInputElement | null;
          // if (e.currentTarget.value === "")
          if (nextSibling && e.currentTarget.value !== "") nextSibling.focus();
          handleChange(e.target.value);
        }
      }}
      onFocus={(e) => {
        e.target.select();
      }}
    />
  );
};

type Props = {
  value: string;
  onChange: (value: string) => void;
  length?: number;
  error?: string;
};

const OTPInput: React.FC<Props> = ({ value, onChange, error, length = 6 }) => {
  const valueArray = useMemo(() => {
    return Array.from({ length }, (_, i) => value[i] || "");
  }, [value, length]);
  return (
    <>
      <div className="cx-flex cx-justify-center cx-items-center cx-gap-2">
        {valueArray.map((num, i) => (
          <OTPInputField
            key={i}
            value={num}
            handleChange={(val) => {
              const newValue = value.slice(0, i) + val + value.slice(i + 1);
              onChange(newValue);
            }}
            error={!!error}
          />
        ))}
      </div>
      {!!error && (
        <div className="cx-text-xs cx-text-text-error cx-mt-2 cx-ml-4">
          {error}
        </div>
      )}
    </>
  );
};

export default OTPInput;
