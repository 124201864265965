import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppOptions } from "../../types";
import { LOCALSTORAGE_KEYS } from "../../constants";
import { usePostVerifyOrigin, useTheme } from "../../hooks";

type SDKDisplayMode = "loan-application" | "dashboard";

type SDKContextValues = {
  loading: boolean;
  origin: string;
  clientId: string;
  sessionToken: string;
  options: AppOptions;
  waitForPrefill: boolean;
  prefilling: boolean;
  prefillingCompleted: boolean;
  displayMode: SDKDisplayMode;
  updateSDKContext: (data: Partial<SDKContextValues>) => void;
  switchDisplayMode: (displayMode: SDKDisplayMode) => void;
  onApplicationStarted?: (loanApplicationId: string) => void;
  onApplicationCompleted?: (loanApplicationId: string) => void;
  onApplicationError?: (loanApplicationId: string, error: Error) => void;
  version?: "1" | "2";
};

const INITIAL_STATE: SDKContextValues = {
  loading: true,
  origin: "",
  clientId: "",
  sessionToken: "",
  options: {},
  waitForPrefill: false,
  prefilling: false,
  prefillingCompleted: false,
  updateSDKContext: () => {},
  version: "2",
  displayMode: "loan-application",
  switchDisplayMode: () => {},
};

export const SDKContext = createContext(INITIAL_STATE);

export const SDKContextProvider: React.FC<{
  children: ReactNode;
  value?: Partial<SDKContextValues>;
}> = ({ children, value = {} }) => {
  const [state, setState] = useState({ ...INITIAL_STATE, ...value });

  const { mutateAsync: verifyOrigin } = usePostVerifyOrigin();

  useEffect(() => {
    if (state.clientId) {
      window.localStorage.setItem(LOCALSTORAGE_KEYS.CLIENT_ID, state.clientId);
    }
    if (state.sessionToken) {
      window.localStorage.setItem(
        LOCALSTORAGE_KEYS.SESSION_TOKEN,
        state.sessionToken
      );
    }
    if (state.version === "2") {
      if (state.sessionToken && state.origin) {
        const verify = async () => {
          try {
            const { allowed } = await verifyOrigin({ origin: state.origin });
            if (allowed) {
              updateSDKContext({ loading: false });
            }
          } catch (err) {
            updateSDKContext({ loading: false });
          }
        };
        verify();
      }
    } else {
      updateSDKContext({ loading: false });
    }
  }, [state.clientId, state.sessionToken, state.origin, state.version]);

  const updateSDKContext = useCallback((data: Partial<SDKContextValues>) => {
    setState((prevState) => ({ ...prevState, ...data }));
  }, []);

  const switchDisplayMode = useCallback((displayMode: SDKDisplayMode) => {
    updateSDKContext({ displayMode });
  }, []);

  const contextValue = useMemo(
    () => ({ ...state, updateSDKContext, switchDisplayMode }),
    [state, updateSDKContext, switchDisplayMode]
  );

  return (
    <SDKContext.Provider value={contextValue}>{children}</SDKContext.Provider>
  );
};

export const useSDKContext = () => useContext(SDKContext);
