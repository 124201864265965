import Button from "../Button/Button";

type Props = {
  loading?: boolean;
  label?: string;
  disabled?: boolean;
  onClick?: () => void;
  arrow?: "left" | "right";
  type?: "button" | "submit";
};

const StepNextButton: React.FC<Props> = ({
  loading = false,
  label = "Next",
  disabled = false,
  arrow,
  onClick,
  type = "submit",
}) => {
  return (
    <Button
      type={type}
      fullWidth
      arrow={arrow}
      label={loading ? "" : label}
      disabled={loading || disabled}
      {...(loading ? { loader: "left" } : {})}
      {...(onClick ? { onClick } : {})}
    />
  );
};

export default StepNextButton;
