import { useContext, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { StepperContext, useLoanOffer } from "../../contexts";
import { Button, CardRadioOptions } from "../../components";
import {
  SelectBankAccountFields,
  SELECT_BANK_ACCOUNT_INITIAL_VALUES,
  SELECT_ACCOUNT_VALIDATION_SCHEMA,
} from "./service";
import {
  useDirectDebitBankAccounts,
  useSaveDirectDebitBankAccount,
  useUpdateLoanOfferStage,
} from "../../hooks";
import {
  DirectDebitBankAccountSourceType,
  LoanOfferStage,
} from "../../hooks/api/types";
import { getCopyText } from "./constants";

const LoanOfferSelectAccount = () => {
  const { gotoStep } = useContext(StepperContext);
  const {
    state,
    actions: { update },
  } = useLoanOffer();

  const { mutateAsync: getBankAccounts } = useDirectDebitBankAccounts();
  const { mutateAsync: saveDirectDebitBankAccount } =
    useSaveDirectDebitBankAccount();
  const { mutateAsync: updateLoanOfferStage } = useUpdateLoanOfferStage();

  useEffect(() => {
    getBankAccounts(state.id).then((bankAccounts) => {
      update({
        banklist: bankAccounts.map((account: any) => ({
          bankName: account.bankName,
          bankLogo: account.bankLogo,
          iban: account.iban,
          accountName: account.accountName,
          accountId: account.accountId,
          sourceType: account.sourceType,
        })),
      });
    });
  }, []);

  const options = useMemo(() => {
    return state.banklist.map((bank) => ({
      icon: (
        <img src={bank.bankLogo} className="cx-bg-black" alt={bank.bankName} />
      ),
      label: bank.bankName,
      value: bank.iban,
      showDisplayValue: true,
      displayValue: (
        <div className="cx-flex cx-flex-row">
          {bank.accountName}{" "}
          {bank.accountName && (
            <div className="cx-ml-2 cx-mr-2 cx-border-stroke-primary cx-border-0.5"></div>
          )}{" "}
          IBAN {bank.iban}{" "}
        </div>
      ),
    }));
  }, [state.banklist]);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldError,
    isSubmitting,
  } = useFormik<SelectBankAccountFields>({
    initialValues: {
      ...SELECT_BANK_ACCOUNT_INITIAL_VALUES,
      bankName: state.bankName,
    },
    validationSchema: SELECT_ACCOUNT_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      const selected = state.banklist.find(
        (bank) => bank.iban === values.bankName
      );

      try {
        await saveDirectDebitBankAccount({
          loanOfferId: state.id,
          bankName: selected?.bankName ?? "",
          iban: selected?.iban ?? "",
          accountId: selected?.accountId ?? "",
          accountName: selected?.accountName ?? "",
          sourceType:
            selected?.sourceType ?? DirectDebitBankAccountSourceType.MANUAL,
        });
        await updateLoanOfferStage({
          id: state.id,
          stage: state.dpSettings?.directDebitRequired
            ? LoanOfferStage.SELECT_SIGN_MODE
            : LoanOfferStage.COMPLETED,
        });
        await update({
          bankName: values.bankName,
          bankIban: selected?.iban ?? "",
        });
        state.dpSettings?.directDebitRequired
          ? gotoStep([2, 0])
          : gotoStep([3, 0]);
      } catch (err: any) {
        console.log(err);
        setFieldError("bankName", err?.message);
      }
    },
  });

  useEffect(() => {
    if (state.banklist?.length === 1) {
      handleChange("bankName")(state.banklist[0].iban as string);
    }
  }, [state.banklist]);

  return (
    <div className="cx-grow cx-w-full cx-max-w-[960px] cx-flex cx-flex-col cx-items-center cx-justify-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        {getCopyText(
          "SELECT_ACCOUNT",
          "directDebitRequired",
          state.dpSettings?.directDebitRequired
        )!()}
      </div>
      <div className="cx-text-text-secondary cx-text-center">
        {getCopyText(
          "LOAN_TRANSFER",
          "directDebitRequired",
          state.dpSettings?.directDebitRequired
        )!()}
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <form onSubmit={handleSubmit}>
          <div className="cx-w-full cx-py-10">
            <CardRadioOptions
              options={options}
              keyPropName="value"
              inputProps={{
                name: "bankName",
                value: values.bankName,
                onChange: handleChange,
              }}
            />
            {touched.bankName && !!errors.bankName && (
              <div className="cx-text-xs cx-py-1 cx-text-text-error">
                {errors.bankName}
              </div>
            )}
          </div>
          <Button
            fullWidth
            type="submit"
            label="Next"
            disabled={isSubmitting}
            {...(isSubmitting && { loader: "left" })}
          />
        </form>
      </div>
    </div>
  );
};

export default LoanOfferSelectAccount;
