import React from "react";
import { roundTo } from "../../utils";
import clsx from "clsx";
import { LoanPaymentStatus } from "../../contexts";

type TransactionProps = {
  date: string;
  isLast: boolean;
  isFirst: boolean;
  currencyUnit: string;
  installmentNo: number;
  monthlyPayable: number;
  isReviewScreen?: boolean;
  loanPaymentStatus?: LoanPaymentStatus;
};

const Transaction: React.FC<TransactionProps> = ({
  date,
  isLast,
  isFirst,
  currencyUnit,
  installmentNo,
  monthlyPayable,
  isReviewScreen = true,
  loanPaymentStatus = LoanPaymentStatus.SCHEDULED,
}) => {
  return (
    <div className="cx-container cx-relative cx-flex cx-items-center cx-justify-center cx-py-5 cx-w-full">
      {isReviewScreen && (
        <div className="cx-absolute cx-inset-0 cx-flex cx-items-center cx-justify-center cx-left-[20%]">
          {loanPaymentStatus === LoanPaymentStatus.PAID ? (
            <div className="cx-py-1 cx-px-6 cx-rounded-btn cx-text-success-darker cx-bg-success-lighter">
              Paid
            </div>
          ) : (
            <div className="cx-py-1 cx-px-3 cx-rounded-btn cx-text-neutral-darker cx-bg-neutral-lighter">
              Pending
            </div>
          )}
        </div>
      )}
      <div className="cx-flex cx-items-start cx-justify-between cx-w-full">
        <div className="cx-flex cx-items-start cx-space-x-3 cx-w-full">
          <div className="cx-relative">
            <div
              className={clsx(
                "cx-h-2.5 cx-w-2.5 cx-mt-2 cx-rounded-full cx-bg-brand-primary-dark",
                {
                  "cx-bg-success-dark":
                    loanPaymentStatus === LoanPaymentStatus.PAID,
                }
              )}
            ></div>
            <div
              className={clsx(
                "cx-absolute cx-left-1 cx-transform cx-[-translate-x-1/2] cx-border-l-2 cx-border-neutral-lighter",
                {
                  "cx-h-[calc(100%+75px)]": isFirst,
                  "cx-h-[calc(50%px)]": isLast,
                  "cx-h-[calc(100%+45px)]": !isFirst && !isLast,
                  "cx-border-success-light":
                    loanPaymentStatus === LoanPaymentStatus.PAID,
                }
              )}
            ></div>
          </div>
          <div className="cx-flex cx-flex-col">
            <div className="cx-flex cx-items-center">
              <div
                className={clsx("cx-text-base cx-font-semibold", {
                  "cx-text-success-dark":
                    (isFirst && loanPaymentStatus === LoanPaymentStatus.PAID) ||
                    (!isFirst && loanPaymentStatus === LoanPaymentStatus.PAID),
                  "cx-text-neutral-dark": !isFirst,
                  "cx-text-brand-primary-dark": isFirst,
                })}
              >
                {isFirst
                  ? "Due on approval"
                  : `Due in ${installmentNo} month${
                      installmentNo > 1 ? "s" : ""
                    }`}
              </div>
              <div className="cx-text-lg cx-font-normal cx-text-base cx-ml-2">
                {currencyUnit} {roundTo(monthlyPayable, 2).toLocaleString()}
              </div>
            </div>
            <div
              className={clsx("cx-text-sm cx-font-medium", {
                "cx-text-success-dark":
                  loanPaymentStatus === LoanPaymentStatus.PAID,
                "cx-text-neutral-darker":
                  loanPaymentStatus !== LoanPaymentStatus.PAID,
              })}
            >
              {isFirst &&
                loanPaymentStatus !== LoanPaymentStatus.PAID &&
                "To be debited once the contract is signed"}
            </div>
          </div>
        </div>
        {(!isFirst || loanPaymentStatus === LoanPaymentStatus.PAID) && (
          <div className="cx-absolute cx-right-0 cx-flex cx-items-center">
            <div className="cx-text-base cx-font-semibold cx-text-brand-primary-dark cx-pt-1 cx-text-right">
              {date}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Transaction;
