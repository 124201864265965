import Lottie from "react-lottie";
import { BulletIcon, CardList, StepNextButton } from "../../components";
import { SuccessPartyLottie } from "../../assets/lottie";
import { SuccessTickIcon } from "../../assets/icons";
import { CardVariantType } from "../../components/CardList/CardList";
import { useInvoiceFinanceSettings } from "../../hooks";
import { useLoanApplication, useSDKContext } from "../../contexts";
import { LoanProductType } from "../../hooks/api/types";
import { usePostHog } from "posthog-js/react";
import { useStepper } from "./Stepper";

const SuccessScreen = () => {
  const {
    state: { loanType },
  } = useLoanApplication();
  const { switchDisplayMode } = useSDKContext();
  const { repayableByDPFlag } = useInvoiceFinanceSettings();
  const posthog = usePostHog();
  const { reset } = useStepper();
  const {
    actions: { reset: applicationReset },
  } = useLoanApplication();

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-w-20 cx-h-20 cx-mb-4 cx-relative cx-text-text-brand">
        <div className="cx-absolute cx--top-16 cx--left-20 cx-w-60 cx--z-10">
          <Lottie
            height="100%"
            width="100%"
            options={{
              animationData: SuccessPartyLottie,
              autoplay: true,
            }}
          />
        </div>
        <SuccessTickIcon />
      </div>
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        Congratulations!
      </div>
      <div className="cx-text-text-secondary cx-text-center cx-mb-10">
        <p>Your loan application is submitted. </p>
        <p>What are the next steps?</p>
      </div>
      <div className="cx-max-w-[400px]">
        <div className="cx-flex cx-flex-col cx-w-full">
          <div className="cx-mb-6">
            <CardList
              title="Application review"
              description={`We will review your loan application and you will receive an email update ${
                (loanType === LoanProductType.PAYABLE_FINANCING ||
                  loanType === LoanProductType.INVOICE_DISCOUNTING) &&
                repayableByDPFlag
                  ? "shortly."
                  : "within 10 minutes."
              }`}
              variant={CardVariantType.SIMPLE}
              icon={<BulletIcon text="1" />}
            />
          </div>
          <div className="cx-mb-6">
            <CardList
              title="Shareholders identity verification"
              description="All applicable shareholders will receive an email to verify their identity documents."
              variant={CardVariantType.SIMPLE}
              icon={<BulletIcon text="2" />}
            />
          </div>
          <div className="cx-mb-6">
            <CardList
              title="Loan agreement"
              description="CredibleX team will send you a loan offer and next steps."
              variant={CardVariantType.SIMPLE}
              icon={<BulletIcon text="3" />}
            />
          </div>
          <div className="cx-text-text-secondary cx-text-sm cx-mb-10 cx-text-center">
            Your loan will be paid to you when each step is successfully
            completed.
          </div>
          <StepNextButton
            label="Go to dashboard"
            onClick={() => {
              posthog.reset(true);
              reset();
              applicationReset();
              switchDisplayMode("dashboard");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
