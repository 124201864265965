// import GradientBox from "../GradientBox/GradientBox";

type Props = {
  text: string;
  image: string;
};

const style = {
  from: {
    color: "cx-from-brand-primary-regular/[.1]",
    upto: "cx-from-50%",
  },
  via: {
    color: "cx-via-brand-tertiary-regular/[.1]",
    upto: "cx-via-100%",
  },
  to: {
    color: "cx-to-brand-secondary-regular/[.1]",
    upto: "cx-to-100%",
  },
  isText: false,
};

const GradientCard: React.FC<Props> = ({ text, image }) => {
  return (
    <div className="cx-max-w-max cx-py-3">
      <div className="cx-w-max cx-rounded-base cx-shadow-base cx-bg-background-default">
        <div className="cx-grow cx-b cx-py-4 cx-font-bold cx-text-lg cx-text-center cx-text-text-primary">
          {text}
        </div>
        {/* <GradientBox angle={"cx-bg-gradient-to-tl"} {...style}> */}
        <div className="cx-flex cx-justify-center cx-background-radial-gradient">
          <img src={image} alt="image" />
        </div>
        {/* </GradientBox> */}
      </div>
    </div>
  );
};

export default GradientCard;
