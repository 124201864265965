import { FC, ReactNode } from "react";
import clsx from "clsx";

type IconCardProps = {
  icon: ReactNode;
  title: string;
  subtitle?: string;
  onClick?(): void;
};
export const IconCard: FC<IconCardProps> = ({
  icon,
  title,
  subtitle,
  onClick,
}) => {
  return (
    <div
      className={clsx(
        "cx-border-0.5 cx-border-muted-3 cx-p-4 cx-rounded-xl cx-flex cx-flex-col cx-gap-6 cx-items-center sm:cx-items-stretch cx-h-full cx-cursor-default",
        {
          "cx-bg-brand-primary-regular cx-cursor-pointer": onClick,
          "cx-transition cx-ease-in-out cx-delay-150 hover:cx-translate-y-1 hover:cx-cursor-pointer hover:cx-scale-110 cx-duration-300":
            onClick,
        }
      )}
      key={title}
      role={"button"}
      tabIndex={0}
      onKeyDown={onClick}
      onClick={onClick}
    >
      <div className={"cx-flex"}>
        <div>{icon}</div>
      </div>
      <div className={"cx-flex cx-flex-col cx-gap-1"}>
        <p
          className={clsx("cx-font-normal cx-text-xl", {
            "cx-text-white": onClick,
          })}
        >
          {title}
        </p>

        <p
          className={clsx("cx-font-normal cx-text-sm cx-text-neutral-darker", {
            "cx-text-white": onClick,
          })}
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
};
export default IconCard;
