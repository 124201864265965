import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { SDKContextProvider } from "./contexts";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { PostHogProvider } from "posthog-js/react";

const queryClient = new QueryClient();

type Props = {};

const App: React.FC<Props> = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <SDKContextProvider>
        <PostHogProvider
          apiKey={import.meta.env.VITE_POSTHOG_KEY}
          options={{
            api_host: import.meta.env.VITE_POSTHOG_HOST,
            persistence: "sessionStorage",
            autocapture: false,
          }}
        >
          <RouterProvider router={router} />
        </PostHogProvider>
      </SDKContextProvider>
    </QueryClientProvider>
  );
};

export default App;
