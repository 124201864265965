export const loadLeanTechScript = (callback?: Function) => {
  const existingScript = document.getElementById("lean-tech-script");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://cdn.leantech.me/link/sdk/web/latest/Lean.min.js";
    script.id = "lean-tech-script";
    document.body.appendChild(script);
    const div = document.createElement("div");
    div.id = "lean-link";
    document.body.appendChild(div);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
