import { forwardRef, useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  CardList,
  Dropzone,
  Hint,
  Modal,
  StepNextButton,
  Tabs,
} from "../../../components";
import {
  CustomFile,
  UPLOAD_STATEMENTS_FORM_INITIAL_VALUES,
  UPLOAD_STATEMENTS_FORM_VALIDATION_SCHEMA,
  UploadStatementsFormFields,
} from "./service";
import {
  useDeleteMedia,
  useMedia,
  useGetLatestTranasction,
} from "../../../hooks";
import {
  LoanApplicationStage,
  MediaPurpose,
  MediaType,
} from "../../../hooks/api/types";
import { StepperContext, useLoanApplication } from "../../../contexts";
import {
  AppleRemovePdfPassword,
  GoogleRemovePdfPassword,
} from "./RemovePdfPassword";
import { CardVariantType } from "../../../components/CardList/CardList";
import { ArrowLeftIcon, PdfIcon } from "../../../assets/icons";
import { formatBytes, formatISOString } from "../../../utils";

const VAT_LIMIT = Number(import.meta.env.VITE_SKIP_VAT_LIMIT ?? 375000);

const UploadStatement = () => {
  const [statements, setStatements] = useState<CustomFile[]>([]);
  const [statementIds, setStatementIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isUploadState, setIsUploadState] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isFailedUploadExist, setIsFailedUploadExist] =
    useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean[]>([]);

  const {
    state,
    actions: { update },
  } = useLoanApplication();
  const { nextStep, previousStep, gotoStep } = useContext(StepperContext);

  const { upload, get } = useMedia();
  const { mutateAsync: deleteMediaById } = useDeleteMedia();
  const { data: latestTransaction, refetch: getLatestTransactions } =
    useGetLatestTranasction(state.sourceId ?? "");

  useEffect(() => {
    if (state.docBankStatements.length && !state.docBankStatementFiles.length)
      getMediaFromStatementIds(statementIds);
    else if (state.docBankStatementFiles.length)
      setStatements(state.docBankStatementFiles);
  }, [statementIds]);

  useEffect(() => {
    let isUploadError = false;
    statements.forEach((statement) => {
      if (statement.error) isUploadError = true;
    });
    if (!isUploadError) setIsFailedUploadExist(false);
  }, [isFailedUploadExist, statements]);

  const { values, touched, errors, handleSubmit, validateForm, setErrors } =
    useFormik<UploadStatementsFormFields>({
      initialValues: {
        ...UPLOAD_STATEMENTS_FORM_INITIAL_VALUES,
        statements: [],
      },
      validationSchema: UPLOAD_STATEMENTS_FORM_VALIDATION_SCHEMA,
      onSubmit: async () => {
        if (!values.statements.length) return;

        setUploading((prev) => {
          let next = [...prev];
          values.statements.forEach((item, index) => {
            next[index] = true;
          });
          return next;
        });
        setLoading(true);
        const statementPromises = values.statements.map((file, index) =>
          upload({
            file,
            type: MediaType.DOCUMENT,
            purpose: MediaPurpose.BANK_STATEMENT,
            loanApplicationId: state.id,
          }).catch((err) => {
            file.error = "Upload failed";
            setIsFailedUploadExist(true);
            setLoading(false);
          })
        );

        const newStatementIds = await Promise.all(statementPromises);
        setStatements((prev) => {
          const next = prev.map((statement: any, index: number) => {
            if (!statement.id) {
              statement.id = newStatementIds[index];
            }
            return statement as CustomFile;
          });
          return next;
        });
        setUploading((prev) => {
          let next = [...prev];
          next = next.map(() => false);
          return next;
        });

        update({
          docBankStatementFiles: getFilesToUpdate(),
          stage:
            state.annualRevenue < VAT_LIMIT
              ? LoanApplicationStage.GENERIC_DOCUMENTS
              : LoanApplicationStage.VAT_STATEMENTS,
        })
          .then(() => {
            setLoading(false);
            if (!isFailedUploadExist) nextStep();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });

  useEffect(() => {
    if (state.docBankStatements) {
      setStatementIds(state.docBankStatements);
      setIsUploadState(!state.docBankStatements.length);
    }
  }, [state.docBankStatements]);

  const getFilesToUpdate = () =>
    state.docBankStatements.length
      ? [...state.docBankStatementFiles, ...values.statements]
      : [...statements];

  const getMediaFromStatementIds = (statementIds: string[]) => {
    const retreivedMedia: CustomFile[] = [];
    statementIds.map((statementId) => {
      get({ id: statementId })
        .then((res) => {
          const file = new CustomFile(
            new File([], res.media.fileName),
            res.media.id
          );
          Object.defineProperty(file, "size", {
            value: res.media.size,
          });
          retreivedMedia.push(file);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setStatements((prev) => [...retreivedMedia]);
          update({
            docBankStatementFiles: [...retreivedMedia],
          });
        });
    });
  };

  useEffect(() => {
    const fetchTrasactions = async () => {
      await getLatestTransactions();
    };

    if (state.sourceId && state.sourceId !== "") {
      fetchTrasactions();
    }
  }, [state.sourceId]);

  const handleFileUpload = async (files: File[]) => {
    setErrors({});
    if (!files) return;

    const updatedStatements = [...values.statements];

    files.forEach((file: File) => {
      let allFilesUploaded = values.statements.concat(statements);
      let isFileAlreadyAdded = allFilesUploaded.some(
        (existingFile) => existingFile.name === file.name
      );
      if (!isFileAlreadyAdded) {
        setUploading((prev) => {
          const next = [...prev];
          next.push(false);
          return next;
        });
        updatedStatements.push(new CustomFile(file));
      }
    });
    values.statements = [...updatedStatements];

    state.docBankStatementFiles.length
      ? setStatements((prev) => [...updatedStatements, ...prev])
      : setStatements([...updatedStatements]);
  };

  const handleFileClose = async (fileName: string, id?: string) => {
    if (id) {
      deleteMediaById(id)
        .then((res) => {
          setStatements((prev) =>
            prev.filter((statement: CustomFile) => statement.id !== id)
          );
          update({
            docBankStatementFiles: state.docBankStatementFiles.filter(
              (bankStatementFile) => bankStatementFile.id !== id
            ),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (fileName) {
      values.statements = values.statements.filter(
        (statement) => fileName !== statement.name
      );
      setStatements((prev) => {
        const next = prev.filter((statement) => fileName !== statement.name);
        return next;
      });
      values.statements = values.statements.filter(
        (statement) => fileName !== statement.name
      );
      validateForm();
    }
  };

  const handleRetryUpload = (fileName: string) => {
    const file = values.statements.find(
      (statement: File) => statement.name === fileName
    );
    if (file) {
      let statementIndex: number;
      statements.forEach((statement, index) => {
        if (statement.name === fileName) statementIndex = index;
      });
      setUploading((prev) => {
        let next = [...prev];
        next = next.map((item, index) => {
          if (statementIndex === index) return true;
          else return false;
        });
        return next;
      });
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.BANK_STATEMENT,
        loanApplicationId: state.id,
      })
        .then((res: string) => {
          setUploading((prev) => {
            let next = [...prev];
            next = next.map((item, index) => {
              if (statementIndex === index) return false;
              else return false;
            });
            return next;
          });
          values.statements = values.statements.filter(
            (statement) => statement.name !== fileName
          );
          setStatements((prev) => {
            let next = [...prev];
            next = next.map((statement, index) => {
              if (statementIndex === index) {
                statement.error = "";
                statement.id = res;
                return statement;
              } else {
                return statement;
              }
            });
            return next;
          });
          update({
            docBankStatementFiles: [...state.docBankStatementFiles, file],
          });
        })
        .catch((err) => {
          setUploading((prev) => {
            let next = [...prev];
            next = next.map((item, index) => {
              if (statementIndex === index) return false;
              else return false;
            });
            return next;
          });
        });
    }
  };

  const showPdfPasswordHintModal = () => {
    setIsOpenModal(true);
  };

  const hidePdfPasswordHintModal = () => {
    setIsOpenModal(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="cx-flex cx-flex-col cx-w-full cx-items-center cx-gap-10">
        <div className="cx-w-full cx-flex cx-flex-col cx-gap-y-2">
          <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
            Upload bank statements
          </div>
          {latestTransaction ? (
            <div className="cx-text-text-secondary cx-text-base cx-text-center">
              {`Upload statements from ${formatISOString(
                latestTransaction.date
              )} onwards`}
            </div>
          ) : (
            <div className="cx-text-text-secondary cx-text-base cx-text-center">
              Upload the last 6 months company bank statements
            </div>
          )}
        </div>

        <div className="cx-w-full cx-max-w-[400px]">
          <div className="cx-mb-10">
            <Dropzone
              variant="simple"
              extensions={["PDF"]}
              accept={{ "application/pdf": [".pdf"] }}
              onDrop={handleFileUpload}
            />
          </div>
          {statements.map((statement: CustomFile, index) => {
            return (
              <div key={index} className="cx-mb-2">
                <CardList
                  variant={
                    statement.error
                      ? CardVariantType.UPLOAD_FAILED
                      : CardVariantType.UPLOADED
                  }
                  title={statement.name}
                  description={
                    statement.error
                      ? statement.error
                      : touched.statements &&
                        values.statements.length &&
                        !!errors.statements?.[index]
                      ? (errors.statements[index] as string)
                      : formatBytes(statement.size, 2)
                  }
                  fullWidth
                  icon={
                    <PdfIcon
                      width="100%"
                      height="100%"
                      className="cx-text-brand-primary-regular"
                    />
                  }
                  inputProps={{ accept: ".pdf,application/pdf" }}
                  error={
                    statement.error
                      ? true
                      : touched.statements &&
                        !!errors.statements?.[index] &&
                        !!values.statements.length
                  }
                  handleFileClose={handleFileClose}
                  handleRetryUpload={handleRetryUpload}
                  uploading={uploading[index]}
                  documentId={statement.id}
                  truncateTitleLength={35}
                  fileNewDesign={true}
                />
              </div>
            );
          })}
          {isUploadState && !statements.length && (
            <>
              {values.statements.length === 0 && (
                <div className="cx-w-full cx-mt-4">
                  <Hint
                    icon
                    rounded
                    type="tertiary"
                    text="Please ensure that your bank statements are uploaded without password protection."
                  />
                  <div
                    className="cx-w-full cx-mt-4 cx-text-xs cx-font-medium cx-text-text-brand cx-text-center hover:cx-underline cx-cursor-pointer"
                    onClick={showPdfPasswordHintModal}
                  >
                    Learn how to remove password from a PDF file
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="cx-w-full cx-max-w-[400px] cx-flex cx-flex-row cx-gap-x-4">
          <Button
            outlined
            label={
              <ArrowLeftIcon className="cx-mx-4 hover:cx-text-text-inverse" />
            }
            onClick={previousStep}
          />
          <StepNextButton
            label="Next"
            disabled={
              isFailedUploadExist
                ? true
                : values.statements.length === 0 && statements.length === 0
            }
            loading={isFailedUploadExist ? false : loading}
            onClick={() => {
              if (!values.statements.length && statements.length) nextStep();
            }}
          />
        </div>
        <Modal
          isOpen={isOpenModal}
          onClose={hidePdfPasswordHintModal}
          title="Removing security password"
        >
          <Tabs
            startingTab={0}
            tabs={[
              {
                component: <GoogleRemovePdfPassword />,
                label: "Google Chrome",
              },
              {
                component: <AppleRemovePdfPassword />,
                label: "Apple Preview",
              },
            ]}
          />
          <Button label="Close" fullWidth onClick={hidePdfPasswordHintModal} />
        </Modal>
      </div>
    </form>
  );
};

export default forwardRef(UploadStatement);
