import { Button, Modal } from "../../components";

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const LoanOfferRejectModel: React.FC<Props> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel} title="Decline loan offer">
      <div className="cx-flex cx-flex-col cx-items-center cx-gap-6">
        <div className="cx-text-center cx-text-text-secondary cx-text-base">
          Are you sure you would like to decline this offer? When offer is
          declined it will be permanently removed from your account.
        </div>
        <div className="cx-flex cx-gap-4 cx-w-full">
          <Button label="Decline" danger fullWidth onClick={onConfirm} />
          <Button label="Cancel" outlined fullWidth onClick={onCancel} />
        </div>
      </div>
    </Modal>
  );
};

export default LoanOfferRejectModel;
