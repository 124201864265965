import { FC } from "react";
import { Button, Modal } from "../../../components";
import {
  InvoiceDiscountingInvoice,
  PayableFinancingInvoice,
} from "../../../contexts";
import { useFormik } from "formik";
import {
  LOAN_UPDATE_INVOICE_DETAILS_VALIDATION_SCHEMA,
  LOAN_UPLOAD_INVOICE_INITIAL_VALUES,
  UploadInvoiceFields,
} from "../../LoanCalculator/service";
import UploadInvoiceForm from "../../LoanCalculator/UploadInvoiceForm";

type UploadInvoiceModalProps = {
  isOpen: boolean;
  onClose(): void;
  invoice?: InvoiceDiscountingInvoice | PayableFinancingInvoice;
  finalizeInvoiceFileUpload?: () => void;
  updateInvoiceInfo: (
    updatedInvoice: InvoiceDiscountingInvoice | PayableFinancingInvoice
  ) => void;
};

export const UploadInvoiceModal: FC<UploadInvoiceModalProps> = ({
  isOpen = false,
  onClose = () => {},
  invoice,
  updateInvoiceInfo,
}) => {
  const handleCloseModal = () => {
    formik.setTouched({
      invoiceNo: true,
      invoiceDueDate: true,
      invoiceIssuedDate: true,
    });
    formik.validateForm();
    if (!formik.isValid || !formik.dirty) {
      return;
    }
    onClose();
  };

  const formik = useFormik<UploadInvoiceFields>({
    initialValues: {
      ...LOAN_UPLOAD_INVOICE_INITIAL_VALUES,
      invoiceAmount: invoice?.amount,
      invoiceNo: invoice?.invoiceNo.toString(),
    },
    validationSchema: LOAN_UPDATE_INVOICE_DETAILS_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      const formatInvoice = (
        invoice: InvoiceDiscountingInvoice | PayableFinancingInvoice
      ) => ({
        ...invoice,
        invoiceDueDate: values.invoiceDueDate || new Date(),
        invoiceIssuedDate: values.invoiceIssuedDate || new Date(),
        invoiceNo: values.invoiceNo ? values.invoiceNo : "",
      });
      if (invoice) {
        const updatedInvoice = formatInvoice(invoice);
        updateInvoiceInfo(updatedInvoice);
        handleCloseModal();
      }
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} title="Invoice details">
      <UploadInvoiceForm
        formik={formik}
        handleUpload={() => {}}
        uploading={false}
        onReviewScreen={true}
      />
      <div className="cx-w-full cx-flex cx-flex-col">
        <Button
          label="Confirm"
          fullWidth
          arrow="right"
          disabled={!formik.isValid || !formik.dirty}
          onClick={formik.handleSubmit}
        />
        <Button label="Cancel" fullWidth secondary onClick={handleCloseModal} />
      </div>
    </Modal>
  );
};
