import { KeyboardEvent, useId, useMemo } from "react";
import Hint from "../Hint/Hint";
import { InfoIcon, SearchIcon, TickIcon } from "../../assets/icons";
import clsx from "clsx";

const NON_LATIN_REGEX = /^[-@./#&+\w\s]*$/;

export enum InputFieldVariant {
  normal = "normal",
  slim = "slim",
}

type Props = {
  label?: string;
  optional?: boolean;
  error?: string;
  tooltip?: string;
  tick?: boolean;
  search?: boolean;
  className?: string;
  inputClassName?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  hideErrorMessage?: boolean;
  hint?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  variant?: InputFieldVariant;
};

const InputField = ({
  label,
  error,
  optional,
  tooltip,
  tick,
  search,
  className,
  inputClassName,
  inputProps = {},
  hideErrorMessage = false,
  hint,
  prepend,
  append,
  variant = InputFieldVariant.normal,
}: Props) => {
  const id = useId();

  const inputStyle = useMemo(() => {
    return clsx({
      "cx-placeholder-transparent cx-peer cx-outline-none cx-w-full": label,
      "cx-rounded-base cx-p-4 focus:cx-pb-2 focus:cx-pt-6 [&:not(:placeholder-shown)]:cx-pt-6 [&:not(:placeholder-shown)]:cx-pb-2":
        label && variant === InputFieldVariant.normal,
      "cx-rounded-[100px] cx-py-1.5 cx-pl-3":
        label && variant !== InputFieldVariant.normal,
    });
  }, [label, variant]);

  const InputIcon = useMemo(() => {
    if (tick) {
      return <TickIcon height="100%" className="cx-stroke-success-regular" />;
    }
    if (tooltip) {
      return (
        <InfoIcon height="100%" className="cx-stroke-brand-primary-regular" />
      );
    }
    if (optional) {
      return <div className="cx-text-text-tertiary">Optional</div>;
    }
    if (search) {
      return <SearchIcon height="100%" className="cx-stroke-text-tertiary" />;
    }
    return null;
  }, [tick, tooltip, optional]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (NON_LATIN_REGEX.test(event.key)) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  return (
    <>
      <div
        className={clsx(
          "cx-flex cx-flex-row cx-relative cx-w-full cx-bg-background-default cx-peer",
          {
            "cx-rounded-base cx-border-2": variant === InputFieldVariant.normal,
            "cx-rounded-[100px] cx-border-2":
              variant !== InputFieldVariant.normal,
            "cx-border-stroke-error": error,
            "cx-border-stroke-primary": !error,
            "cx-opacity-50": inputProps.disabled,
          },
          className
        )}
      >
        {prepend && (
          <div className="cx-pl-4 cx-flex cx-items-center cx-font-normal cx-text-text-secondary">
            {prepend}
          </div>
        )}
        <input
          id={id}
          className={clsx(
            "cx-bg-none cx-peer cx-outline-none cx-w-full cx-p-4",
            {
              "cx-rounded-base": variant === InputFieldVariant.normal,
              "cx-rounded-[100px]": variant === InputFieldVariant.slim,
            },
            inputStyle,
            inputClassName
          )}
          placeholder={label}
          onKeyDown={handleKeyDown}
          {...inputProps}
        ></input>
        {label && (
          <label
            htmlFor={id}
            className={clsx(
              "cx-absolute cx-text-xs peer-placeholder-shown:cx-text-label cx-transition-all",
              {
                "cx-px-4 cx-pt-4 cx-pb-2 cx-left-0 -cx-top-2 peer-placeholder-shown:cx-p-4 peer-placeholder-shown:cx-text-label peer-placeholder-shown:cx-top-0 peer-focus:-cx-top-2 peer-focus:cx-text-xs":
                  variant === InputFieldVariant.normal,
                "cx-left-1 -cx-top-0 peer-placeholder-shown:cx-py-1.5 peer-placeholder-shown:cx-px-4 peer-focus:cx-hidden":
                  variant === InputFieldVariant.slim,
                "cx-hidden":
                  variant === InputFieldVariant.slim && inputProps?.value,
                "cx-text-text-error": error,
                "cx-text-text-tertiary": !error,
              }
            )}
          >
            {label}
          </label>
        )}
        {InputIcon && (
          <div
            className={clsx({
              "cx-py-4 cx-pr-4": variant === InputFieldVariant.normal,
              "cx-py-1.5 cx-px-3": variant === InputFieldVariant.slim,
            })}
          >
            {InputIcon}
          </div>
        )}
        {append && (
          <div className="cx-pr-4 cx-flex cx-items-center cx-font-normal cx-text-text-secondary">
            {append}
          </div>
        )}
      </div>
      {error && !hideErrorMessage && (
        <div className="cx-text-xs cx-py-1 cx-text-text-error">{error}</div>
      )}
      {!!hint && (
        <div className="cx-max-h-0 cx-transition-[max-height,opacity] cx-ease-in-out cx-opacity-0 cx-duration-700 cx-overflow-hidden peer-focus-within:cx-overflow-visible peer-focus-within:cx-max-h-52 peer-focus-within:cx-opacity-100">
          <Hint text={hint} className="cx-mt-2" icon rounded />
        </div>
      )}
    </>
  );
};

export default InputField;
