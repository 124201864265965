import { Button, Modal } from "../../../components";
import LinkBankLite from "./LinkBankLite";
import { FC } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ConnectBankAccountModal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal title="" isOpen={isOpen} onClose={onClose} className="cx-py-4">
      <LinkBankLite />
      <Button
        label={"Cancel"}
        fullWidth={true}
        secondary={true}
        onClick={onClose}
      />
    </Modal>
  );
};

export default ConnectBankAccountModal;
