import { useLoanApplication } from "../../../contexts";
import UploadStatement from "./UploadStatement";
import { LinkBankAccountSection } from "./index";

const FinanceInformation = () => {
  const {
    state: { financeInformationOption },
  } = useLoanApplication();

  return (
    <div className="cx-max-w-[960px] cx-flex cx-flex-col cx-items-center cx-gap-y-10">
      {financeInformationOption === "connect" ? (
        <LinkBankAccountSection />
      ) : (
        <UploadStatement />
      )}
    </div>
  );
};

export default FinanceInformation;
