import { ReactNode, useContext } from "react";
import { StepNextButton } from "../../../components";
import { StepperContext, useLoanCalculator } from "../../../contexts";
import {
  ConnectIcon,
  DocumentIcon,
  IDCardIcon,
  UserIcon,
} from "../../../assets/icons";
import { StartLoanApplication } from "../../../assets/images";

const documents = [
  {
    title: "Company Trade License",
    info: "A valid UAE Trade License",
  },
  {
    icon: <IDCardIcon className="cx-text-text-brand" />,
    title: "Emirates ID",
    info: "Valid Emirates ID of the applicant",
  },
  {
    title: "Memorandum of Association",
    info: "Company's formation documentation",
  },
  {
    icon: <UserIcon className="cx-text-text-brand" />,
    title: "Shareholder Information",
    info: "All shareholders with shareholding greater than 25%",
  },
  {
    title: "Power of Attorney",
    info: "Applicant must be a Power of Attorney holder",
  },
  {
    icon: <ConnectIcon className="cx-text-text-brand" />,
    title: "Corporate bank account connection",
    info: "Securely connect your bank account",
  },
];

interface RequiredDocumentPros {
  icon?: ReactNode;
  title: string;
  info?: string;
}

const RequiredDocument = ({
  title,
  info,
  icon = <DocumentIcon className="cx-text-text-brand" />,
}: RequiredDocumentPros) => (
  <div className="cx-w-full cx-flex cx-flex-row cx-items-center cx-gap-x-2">
    <div className="cx-w-6 cx-h-6">{icon}</div>
    <div className="cx-flex cx-flex-col">
      <div className="cx-text-text-primary cx-font-normal cx-text-base">
        {title}
      </div>
      <div className="cx-text-text-secondary cx-font-normal cx-text-xs">
        {info}
      </div>
    </div>
  </div>
);

const PreInformationForm = () => {
  const { nextStep } = useContext(StepperContext);
  return (
    <div className="cx-max-w-[960px] cx-w-full cx-flex cx-flex-col cx-items-center cx-gap-y-2">
      <div className="cx-w-96 cx-h-96">
        <img src={StartLoanApplication} alt={"start you application"} />
      </div>
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
        Start your loan application
      </div>
      <div className="cx-text-text-secondary cx-font-normal cx-text-base cx-text-center">
        Here is what is required to apply for a loan
      </div>
      <div className="cx-w-full cx-block sm:cx-flex cx-flex-wrap cx-mb-8 cx-px-0 sm:cx-py-4 sm:cx-px-0 md:cx-p-6">
        {documents.map((document, index) => (
          <div key={index} className="cx-w-full sm:cx-w-1/2 cx-px-2 sm:cx-px-5">
            <div className="cx-w-full cx-py-4">
              <RequiredDocument
                info={document.info}
                title={document.title}
                icon={document.icon}
              />
            </div>
            {index + 1 !== documents.length && (
              <div
                className={`${
                  index + 2 === documents.length ? "cx-block sm:cx-hidden" : ""
                } cx-w-full cx-h-[1px] cx-bg-background-tertiary`}
              />
            )}
          </div>
        ))}
      </div>
      <div className="cx-w-full cx-max-w-[165px]">
        <StepNextButton label="Next" arrow="right" onClick={nextStep} />
      </div>
    </div>
  );
};

export default PreInformationForm;
