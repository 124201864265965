const googleReviewData = [
  {
    id: 1,
    title:
      "Open Google Chrome and navigate to the location of the password-protected PDF file.",
  },
  { id: 2, title: "Drag and drop the PDF file into a new Chrome tab." },
  {
    id: 3,
    title: "Chrome will prompt you to enter the password to view the file.",
  },
  { id: 4, title: "After entering the password, the PDF file will open." },
  {
    id: 5,
    title:
      'Go to the Print menu by pressing Ctrl+P (or Cmd+P on a Mac) or right-clicking and selecting "Print".',
  },
  {
    id: 6,
    title: 'In the Print dialog, choose the "Destination" as "Save as PDF".',
  },
];

const appleReviewData = [
  {
    id: 1,
    title:
      "Open Preview and navigate to the location of the password-protected PDF file.",
  },
  { id: 2, title: 'Click "File" and then click "Export".' },
  {
    id: 3,
    title:
      'Click on the "Save" button then it will save a new copy of the PDF file without a password.',
  },
];

const RemovePdfPassword = ({
  data,
}: {
  data: { id: number; title: string }[];
}) => (
  <div className="cx-w-full cx-max-w-[352px] cx-flex cx-flex-col cx-my-6 cx-gap-y-6">
    {data.map((item) => (
      <div
        key={item.id}
        className="cx-w-full cx-flex cx-flex-row cx-items-center cx-gap-x-4"
      >
        <div className="cx-w-6 cx-h-6 cx-min-w-[24px] cx-min-h-[24px] cx-flex cx-items-center cx-justify-center cx-rounded-full cx-overflow-hidden cx-bg-background-success cx-text-text-inverse cx-text-sm cx-font-medium">
          {item.id}
        </div>
        <div className="cx-text-text-primary cx-text-sm">{item.title}</div>
      </div>
    ))}
  </div>
);

export const GoogleRemovePdfPassword = () => {
  return <RemovePdfPassword data={googleReviewData} />;
};

export const AppleRemovePdfPassword = () => {
  return <RemovePdfPassword data={appleReviewData} />;
};
