import { useMemo } from "react";
import { useLoanApplication } from "../contexts";

export const SKIP_SHAREHOLDER_BUSINESS_TYPES = ["SOLE_PROPRIETORSHIP"];
export const SKIP_MOA_AND_POA_BUSINESS_TYPES = ["SOLE_PROPRIETORSHIP"];

export const useBusinessType = () => {
  const { state } = useLoanApplication();

  const skipShareholders = useMemo(
    () => SKIP_SHAREHOLDER_BUSINESS_TYPES.includes(state?.businessType),
    [state.businessType]
  );

  const skipMoaAndPoa = useMemo(
    () => SKIP_MOA_AND_POA_BUSINESS_TYPES.includes(state?.businessType),
    [state.businessType]
  );

  return useMemo(
    () => ({
      skipShareholders,
      skipMoaAndPoa,
      businessType: state?.businessType,
    }),
    [skipShareholders, skipMoaAndPoa, state?.businessType]
  );
};
