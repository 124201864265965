import { useEffect, useState } from "react";
import { useIFrameEvents, useLoansSummary } from "../hooks";
import { ButtonLoaderIcon } from "../assets/icons";
import {
  LoanApplicationProvider,
  LoanCalculatorProvider,
  LoanDashboardProvider,
  useSDKContext,
} from "../contexts";
import { LoanDashboardLayout } from "./LoanDashboard";
import { LoanApplicationFlow } from "./LoanApplicationFlow";
import { DpConfigProvider } from "../contexts/dpConfig";
import { ErrorBoundary } from "../components";
import { LoanApplicationStepper } from "./LoanApplicationSteps/Stepper";

const GlobalLoader = () => {
  return (
    <div className="cx-w-full cx-h-screen cx-flex cx-justify-center cx-items-center cx-text-brand-primary-regular">
      <ButtonLoaderIcon className="cx-animate-spin cx-w-6 cx-h-6" />
    </div>
  );
};

const SDKEntryPoint = () => {
  const { postInitEvent } = useIFrameEvents();
  const {
    loading: sdkLoading,
    origin,
    displayMode,
    switchDisplayMode,
  } = useSDKContext();
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { mutateAsync: getLoansSummary, isLoading } = useLoansSummary();

  useEffect(() => {
    if (origin) {
      postInitEvent();
    }
  }, [origin]);

  useEffect(() => {
    if (sdkLoading) return;

    getLoansSummary()
      .then((res) => {
        if (!res?.flags?.isFirstLoanApplication) {
          switchDisplayMode("dashboard");
        }
      })
      .catch((err) => {
        setError(true);
        setErrorMessage("The page you requested cannot be found");
      });
  }, [sdkLoading]);

  if (sdkLoading || isLoading) {
    return <GlobalLoader />;
  }

  return (
    <div className="cx-w-full cx-h-full">
      {error ? (
        <ErrorBoundary showHeader errorText={errorMessage} />
      ) : (
        <DpConfigProvider>
          {displayMode === "dashboard" ? (
            <LoanApplicationProvider>
              <LoanApplicationStepper>
                <LoanDashboardProvider>
                  <LoanDashboardLayout />
                </LoanDashboardProvider>
              </LoanApplicationStepper>
            </LoanApplicationProvider>
          ) : (
            <LoanApplicationProvider>
              <LoanCalculatorProvider>
                <LoanApplicationFlow />
              </LoanCalculatorProvider>
            </LoanApplicationProvider>
          )}
        </DpConfigProvider>
      )}
    </div>
  );
};

export default SDKEntryPoint;
