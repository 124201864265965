import Button from "../Button/Button";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  className?: string;
  actionButton?: string;
  onAction?: () => void;
};

const Modal: React.FC<Props> = ({
  title,
  subtitle,
  isOpen,
  onClose,
  children,
  className,
  actionButton,
  onAction,
}) => {
  return isOpen ? (
    <>
      <div className="cx-fixed cx-inset-0 cx-bg-background-overlay-primary cx-z-40"></div>
      <div className="cx-fixed cx-z-50 cx-inset-0 cx-overflow-y-auto">
        <div className="cx-flex cx-items-center cx-justify-center cx-min-h-screen">
          <div
            className="cx-fixed cx-inset-0 cx-bg-gray-500 cx-opacity-90"
            onClick={onClose}
          ></div>
          <div
            className={`cx-relative cx-bg-background-default cx-rounded-modal cx-max-w-[420px] cx-px-6 cx-py-10 ${className}`}
          >
            <div className="cx-mb-6">
              {title && (
                <h2 className="cx-text-text-primary cx-font-bold cx-text-2xl cx-text-center">
                  {title}
                </h2>
              )}
              {subtitle && (
                <p className="cx-text-text-secondary cx-text-center cx-text-base cx-mt-6">
                  {subtitle}
                </p>
              )}
            </div>
            {children}
            {!!actionButton && (
              <Button label={actionButton} onClick={onAction} fullWidth />
            )}
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default Modal;
