import { useContext } from "react";
import { SelectPills } from "../../../components";
import {
  StepperContext,
  useLoanApplication,
  useLoanCalculator,
} from "../../../contexts";
import { LoanReasonMap } from "./service";
import { LoanProductType } from "../../../hooks/api/types";
import { useDpConfig } from "../../../contexts/dpConfig";

const LoanReasons = () => {
  const { nextStep } = useContext(StepperContext);
  const {
    actions: { update },
  } = useLoanApplication();
  const {
    state: {
      fixedTermLoan,
      invoiceDiscountingLoan,
      payableFinancingLoan,
      revenueBasedLoan,
    },
    actions: {
      setFixedTermLoan,
      setInvoiceDiscountingLoan,
      setPayableFinancingLoan,
      setRevenueBasedLoan,
    },
  } = useLoanCalculator();

  const {
    state: { products },
  } = useDpConfig();

  const availableTypes: string[] = products.map((product) => product.type);

  const loanReasons = Array.from(LoanReasonMap)
    .filter(([, value]) => availableTypes.includes(value.type))
    .map(([key, value]) => ({
      value: key,
      label: value.label,
    }));

  const handleClick = async (selected: string[]) => {
    const selectedLoanReason = selected[0];
    const selectedLoanType = LoanReasonMap.get(selectedLoanReason)
      ?.type as LoanProductType;
    if (selectedLoanType === LoanProductType.FIXED_TERM) {
      setFixedTermLoan(fixedTermLoan);
    } else if (selectedLoanType === LoanProductType.INVOICE_DISCOUNTING) {
      setInvoiceDiscountingLoan(invoiceDiscountingLoan);
    } else if (selectedLoanType === LoanProductType.PAYABLE_FINANCING) {
      setPayableFinancingLoan(payableFinancingLoan);
    } else {
      setRevenueBasedLoan(revenueBasedLoan);
    }
    await update(
      { loanReason: selectedLoanReason, loanType: selectedLoanType },
      { local: true }
    );
    nextStep();
  };

  return (
    <div className="cx-max-w-[960px] cx-w-full cx-flex cx-flex-col cx-items-center cx-gap-y-2">
      <div className={"cx-w-full cx-h-full"}>
        <div className={"cx-flex cx-flex-col cx-h-screen cx-justify-center"}>
          <SelectPills
            title={"What are you trying to achieve?"}
            options={loanReasons}
            multiple={false}
            onChange={handleClick}
          ></SelectPills>
        </div>
      </div>
    </div>
  );
};

export default LoanReasons;
