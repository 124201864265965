import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  Button,
  CardList,
  Dropzone,
  StepNextButton,
} from "../../../../components";
import { CardVariantType } from "../../../../components/CardList/CardList";
import {
  useDeleteMedia,
  useMedia,
  useUpdateLoanAdditionalInfo,
} from "../../../../hooks";
import {
  AdditionalInfo,
  MediaPurpose,
  MediaType,
} from "../../../../hooks/api/types";
import {
  CustomFile,
  MISSING_DOCUMENTS_UPLOAD_FORM_INITIAL_VALUES,
  MISSING_DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA,
  MissingDocumentsUploadFormFields,
} from "../../../LoanApplicationSteps/FinancialInformation/service";
import { PdfIcon } from "../../../../assets/icons";
import { formatBytes } from "../../../../utils";

interface MissingDocumentsUploadProps {
  requestedDocument: AdditionalInfo;
  onSubmit?: (res: any) => void;
  onClose: () => void;
  subtitle: string;
  documentType: MediaPurpose;
}

const MissingDocumentsUpload: React.FC<MissingDocumentsUploadProps> = ({
  onSubmit,
  requestedDocument,
  subtitle,
  documentType,
  onClose,
}) => {
  const [missingDocuments, setMissingDocuments] = useState<CustomFile[]>([]);
  const [documentsIds, setDocumentsIds] = useState<string[]>([]);
  const [allUploaded, setAllUploaded] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    //this use effect is supposed to set allUploaded to true when all documents are uploaded
    //we consider all documents uploaded when documents enqueued i.e. missingDocuments are uploaded and documentsIds are set
    //we consider all documents are uploaded when all documents have an id
    if (
      missingDocuments.length > 0 &&
      missingDocuments.length == documentsIds.length
    ) {
      setAllUploaded(
        missingDocuments.every((missingDocument) => missingDocument.id)
      );
    }
  }, [allUploaded, documentsIds, missingDocuments]);

  useEffect(() => {
    //this use effect is supposed to be called after submit is clicked and when all documents are uploaded, we are ready to update the info
    //specifically in case of all documents are uploaded and there is no failed upload
    //or when all documents are uploaded and last remaining failed file is retried
    //make sure we do have uploaded documents
    //make sure there is no failed upload
    //make sure there is no uploading in progress
    //make sure all documents uploaded
    if (
      uploading.every((upload) => !upload) &&
      allUploaded &&
      documentsIds.length
    ) {
      handleUpdateInfo();
    }
  }, [documentsIds.length, uploading, allUploaded]);

  const { mutateAsync: updateInfo } = useUpdateLoanAdditionalInfo();
  const { mutateAsync: deleteMediaById } = useDeleteMedia();
  const { upload } = useMedia();

  const { errors, touched, values, handleSubmit, validateForm } =
    useFormik<MissingDocumentsUploadFormFields>({
      initialValues: {
        ...MISSING_DOCUMENTS_UPLOAD_FORM_INITIAL_VALUES,
        documents: [],
      },
      validationSchema: MISSING_DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA,
      onSubmit: async () => {
        if (!values.documents.length) return;
        if (allUploaded) {
          await handleUpdateInfo();
          return;
        }

        setUploading(values.documents.map(() => true));
        setLoading(true);
        const documentPromises = values.documents.map(async (file) => {
          if (file.id) return null;

          try {
            const res = await upload({
              file,
              type: MediaType.DOCUMENT,
              purpose: documentType,
              loanApplicationId: requestedDocument.loanApplicationId,
            });

            setDocumentsIds((prev) => [...prev, res]);

            setMissingDocuments((prev) =>
              prev.map((missingDocument) => {
                if (missingDocument.name === file.name) {
                  missingDocument.id = res;
                }
                return missingDocument;
              })
            );
          } catch (err) {
            file.error = "Upload failed";
          }
        });

        await Promise.all(documentPromises);

        setLoading(false);

        values.documents = values.documents.filter((document) => !document.id);

        setUploading(values.documents.map(() => false));
      },
    });

  //this function updates the status of additional info request
  const handleUpdateInfo = async () => {
    updateInfo({
      id: requestedDocument.loanApplicationId,
      infoType: requestedDocument.type,
      values: documentsIds,
    }).then((res) => {
      if (onSubmit) {
        onSubmit(res);
      }
    });
  };

  const handleFileDrop = async (files: File[]) => {
    setAllUploaded(false);
    if (!files) return;
    if (
      documentType === MediaPurpose.TRADE_LICENSE ||
      documentType === MediaPurpose.MEMORANDUM_OF_ASSOCIATION ||
      documentType === MediaPurpose.POWER_OF_ATTORNEY ||
      documentType === MediaPurpose.INVOICE
    ) {
      if (!values.documents.length) files = [files[0]];
      else return;
    }

    const filesToAdd: File[] = [];
    files.forEach((file: File) => {
      let isFileAlreadyAdded = missingDocuments.some(
        (missingDocument) => missingDocument.name === file.name
      );
      if (!isFileAlreadyAdded) {
        setUploading((prev) => {
          const next = [...prev];
          next.push(false);
          return next;
        });
        filesToAdd.push(file);
      }
    });
    values.documents = [...filesToAdd, ...values.documents];
    setMissingDocuments([...filesToAdd, ...missingDocuments]);
  };

  const handleFileClose = async (fileName: string, id?: string) => {
    if (id) {
      deleteMediaById(id)
        .then((res) => {
          setMissingDocuments((prev) =>
            prev.filter(
              (missingDocument: CustomFile) => missingDocument.id !== id
            )
          );
          setDocumentsIds((prev) =>
            prev.filter((documentId) => documentId !== id)
          );
          values.documents = values.documents.filter(
            (document) => document.id !== id
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (fileName) {
      values.documents = values.documents.filter(
        (missingDocument) => fileName !== missingDocument.name
      );
      setMissingDocuments((prev) => {
        const next = prev.filter(
          (missingDocument) => fileName !== missingDocument.name
        );
        return next;
      });
      validateForm();
    }
  };

  const handleRetryUpload = (fileName: string) => {
    setAllUploaded(false);
    const file = values.documents.find(
      (document: File) => document.name === fileName
    );
    if (file) {
      let documentIndex: number;
      missingDocuments.forEach((missingDocument, index) => {
        if (missingDocument.name === fileName) documentIndex = index;
      });
      setUploading((prev) => {
        let next = [...prev];
        next = next.map((item, index) => {
          return documentIndex === index;
        });
        return next;
      });
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: documentType,
        loanApplicationId: requestedDocument.loanApplicationId,
      })
        .then((res: string) => {
          setUploading((prev) => {
            let next = [...prev];
            next = next.map((item, index) => {
              if (documentIndex === index) return false;
              else return item;
            });
            return next;
          });
          values.documents = values.documents.filter(
            (document: CustomFile) => document.name !== fileName
          );
          setDocumentsIds((prev) => {
            let next = [...prev];
            next[next.length] = res;
            return next;
          });
          setMissingDocuments((prev) => {
            let next = [...prev];
            next = next.map((document, index) => {
              if (documentIndex === index) {
                document.error = "";
                document.id = res;
                return document;
              } else {
                return document;
              }
            });
            return next;
          });
        })
        .catch((err) => {
          setUploading((prev) => {
            let next = [...prev];
            next = next.map((item, index) => {
              if (documentIndex === index) return false;
              else return false;
            });
            return next;
          });
        });
    }
  };

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        Action required
      </div>
      <div className="cx-text-text-secondary cx-text-center cx-mb-10">
        {`Your ${subtitle} has expired, please upload a valid ${subtitle} to be able to use our financial services.`}
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <div className="cx-mb-10">
          <Dropzone
            variant="simple"
            extensions={["PDF"]}
            accept={{ "application/pdf": [".pdf"] }}
            onDrop={handleFileDrop}
          />
        </div>

        <form onSubmit={handleSubmit}>
          {missingDocuments.map((missingDocument, index) => {
            return (
              <div className="cx-mb-2" key={index}>
                <CardList
                  variant={
                    missingDocument.error
                      ? CardVariantType.UPLOAD_FAILED
                      : CardVariantType.UPLOADED
                  }
                  title={missingDocument.name}
                  description={
                    missingDocument.error
                      ? missingDocument.error
                      : touched.documents &&
                        values.documents.length &&
                        !!errors.documents?.[index]
                      ? (errors.documents[index] as string)
                      : formatBytes(missingDocument.size, 2)
                  }
                  fullWidth
                  icon={
                    <PdfIcon
                      width="100%"
                      height="100%"
                      className="cx-text-brand-primary-regular"
                    />
                  }
                  inputProps={{ accept: ".pdf,application/pdf" }}
                  error={
                    missingDocument.error
                      ? true
                      : touched.documents &&
                        !!errors.documents?.[index] &&
                        !!values.documents.length
                  }
                  uploading={uploading[index]}
                  documentId={missingDocument.id}
                  handleFileClose={handleFileClose}
                  handleRetryUpload={handleRetryUpload}
                  truncateTitleLength={35}
                  fileNewDesign={true}
                />
              </div>
            );
          })}
          <div className="cx-w-full cx-flex cx-flex-col cx-gap-y-4 cx-mt-10">
            <StepNextButton
              label="Submit"
              disabled={
                !allUploaded && documentsIds.length
                  ? true
                  : values.documents.length === 0 &&
                    missingDocuments.length === 0
              }
              loading={!allUploaded ? false : loading}
            />
            <Button outlined fullWidth onClick={onClose} label={"Cancel"} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default MissingDocumentsUpload;
