import { useState } from "react";
import {
  InvoiceDiscountingInvoice,
  PayableFinancingInvoice,
  useLoanApplication,
  useLoanCalculator,
} from "../../../../contexts";
import { useMedia } from "../../../../hooks";
import AttachedDocumentCard from "./AttachedDocumentCard";
import { MediaPurpose, MediaType } from "../../../../hooks/api/types";
import { UploadInvoiceModal } from "../UploadInvoiceModal";

const InvoiceReviewSection = () => {
  const [invoiceUploading, setInvoiceUploading] = useState<boolean>();
  const [uploadInvoiceModal, setUploadInvoiceModal] = useState<boolean>(false);
  const [invoiceToEdit, setInvoiceToEdit] = useState<
    PayableFinancingInvoice | InvoiceDiscountingInvoice
  >();
  const [newInvoiceFile, setNewInvoiceFile] = useState<File>();
  const { state, actions } = useLoanApplication();
  const { state: calculatorState } = useLoanCalculator();
  const { upload } = useMedia();

  const updateInvoiceInfo = async (
    updatedInvoice: InvoiceDiscountingInvoice | PayableFinancingInvoice,
    file?: File
  ) => {
    if (!file) return;
    setInvoiceUploading(true);

    const newMediaId = await upload({
      file,
      type: MediaType.DOCUMENT,
      purpose: MediaPurpose.INVOICE,
      loanApplicationId: state.id,
    });

    const oldInvoice = { ...updatedInvoice };
    const newInvoice = {
      ...oldInvoice,
      invoiceId: newMediaId,
    };
    try {
      await actions.update({
        id: state.id,
        loanType: calculatorState.type,
        invoices: [newInvoice],
      });
      state.invoices = [newInvoice];
    } catch (err) {
      console.log(err);
    }

    setInvoiceUploading(false);
  };

  return (
    <>
      <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
        <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
          <div className="cx-text-2xl cx-font-bold">Invoices</div>
        </div>
        <div className="cx-mt-6">
          {!!state.invoices?.filter(Boolean).length && (
            <div className="cx-mt-6">
              <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
                {state.invoices.filter(Boolean).map((invoice) => (
                  <AttachedDocumentCard
                    document={{ id: invoice.invoiceId }}
                    key={invoice.invoiceId}
                    loading={invoiceUploading}
                    showTitle={false}
                    onChange={async (file) => {
                      if (!file) return;
                      setNewInvoiceFile(file);
                      setInvoiceToEdit(invoice);
                      setUploadInvoiceModal(true);
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {uploadInvoiceModal && (
        <UploadInvoiceModal
          isOpen={uploadInvoiceModal}
          onClose={() => {
            setUploadInvoiceModal(false);
          }}
          invoice={invoiceToEdit}
          updateInvoiceInfo={(updatedInvoice) => {
            updateInvoiceInfo(updatedInvoice, newInvoiceFile);
          }}
        />
      )}
    </>
  );
};

export default InvoiceReviewSection;
