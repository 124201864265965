import { useCallback, useMemo } from "react";
import { InfoIcon } from "../../assets/icons";

type ArrowPlacement =
  | "top-left"
  | "top"
  | "top-right"
  | "bottom-right"
  | "bottom"
  | "bottom-left";

type Props = {
  text: string;
  type?: "primary" | "secondary" | "tertiary";
  placement?: ArrowPlacement;
  icon?: boolean;
  rounded?: boolean;
  className?: string;
};

const Hint: React.FC<Props> = ({
  text,
  type = "primary",
  placement = "top-left",
  icon = false,
  rounded = false,
  className = "",
}) => {
  const getPositionClasses = useCallback(() => {
    switch (placement) {
      case "top-left":
        return "cx--top-1 cx-left-4 cx-bg-gradient-to-tr";
      case "top":
        return "cx--top-1 cx-left-1/2 cx-transform cx--translate-x-1/2 cx-bg-gradient-to-t";
      case "top-right":
        return "cx--top-1 cx-right-4 cx-bg-gradient-to-tl";
      case "bottom-right":
        return "cx--bottom-1 cx-right-4 cx-bg-gradient-to-bl";
      case "bottom":
        return "cx--bottom-1 cx-left-1/2 cx-transform cx--translate-x-1/2 cx-bg-gradient-to-b";
      case "bottom-left":
        return "cx--bottom-1 cx-left-4 cx-bg-gradient-to-br";
      default:
        return "cx--top-1 cx-left-4 cx-bg-gradient-to-tr";
    }
  }, [placement]);

  const [gradientContainer, gradientArrow] = useMemo(() => {
    switch (type) {
      case "primary":
        return [
          "cx-bg-gradient-to-tr cx-from-brand-primary-regular cx-to-brand-primary-dark",
          "cx-from-brand-primary-regular cx-to-brand-primary-dark",
        ];
      case "secondary":
        return [
          "cx-bg-gradient-to-br cx-from-[#EDA3FC] cx-via-[#7553DB] cx-via-30% cx-to-[#2529EB]",
          "cx-from-[#EDA3FC] cx-to-[#EDA3FC]",
        ];
      case "tertiary":
        return [
          "cx-bg-gradient-to-tr cx-from-brand-secondary-regular cx-to-brand-primary-regular",
          "cx-from-brand-secondary-regular cx-to-brand-primary-regular",
        ];
    }
  }, [type]);

  return (
    <div className={`cx-relative cx-z-10 ${className}`}>
      <div
        className={`cx-w-full cx-flex cx-items-center cx-px-3 cx-py-3 cx-text-text-inverse cx-text-xs ${gradientContainer} ${
          rounded ? "cx-rounded-base" : ""
        }`}
      >
        <div className="cx-flex">
          {icon && (
            <div className="cx-w-5 cx-h-5 cx-mr-2 cx-shrink-0">
              <InfoIcon width="100%" height="100%" />
            </div>
          )}
          {text}
        </div>
      </div>
      <div
        className={`cx-w-3 cx-h-3 cx-rounded-sm cx-absolute ${gradientArrow} cx-rotate-45 cx--z-10 ${getPositionClasses()}`}
      ></div>
    </div>
  );
};

export default Hint;
