import { FC } from "react";
import LoanReviewField from "./LoanReviewField";
import { Divider } from "../../../components";
import { CloseIcon } from "../../../assets/icons";
import EditButton from "./EditButton";

type DeleteButtonProps = {
  onClick?(): void;
};

export const DeleteButton: FC<DeleteButtonProps> = ({ onClick }) => {
  return (
    <div
      className="cx-group cx-w-8 cx-h-8 cx-flex cx-justify-center cx-items-center cx-rounded-full cx-border cx-border-stroke-error cx-cursor-pointer hover:cx-bg-background-error"
      onClick={onClick}
    >
      <div className="cx-w-4 cx-h-4">
        <CloseIcon className="cx-text-text-error group-hover:cx-text-text-inverse" />
      </div>
    </div>
  );
};

type Props = {
  title: string;
  name: string;
  email: string;
  phoneNumber: string;
  onEdit?: () => void;
  onDelete?: () => void;
};

const ShareholderInfo: FC<Props> = ({
  title,
  name,
  email,
  phoneNumber,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="cx-flex cx-flex-col">
      <div className="cx-text-lg cx-mt-6 cx-font-semibold">{title}</div>
      <LoanReviewField value={name} label={"Name"} />
      <Divider />
      <LoanReviewField value={phoneNumber} label={"Phone Number"} />
      <Divider />
      <LoanReviewField value={email} label={"Email"} />
      <div className="cx-text-xs cx-mb-6 cx-flex cx-flex-row cx-gap-2">
        {!!onEdit && <EditButton onClick={onEdit} />}
        {!!onDelete && <DeleteButton onClick={onDelete} />}
      </div>
    </div>
  );
};

export default ShareholderInfo;
