import { FC, Fragment } from "react";
import { Divider } from "../index";

type InformationBoxProps = {
  information: { title: string; subtitle: string; optional?: boolean }[];
};
export const InformationBox: FC<InformationBoxProps> = ({ information }) => {
  return (
    <div
      className={
        "cx-border-0.5 cx-border-muted-3 cx-rounded-xl cx-p-6 cx-flex cx-flex-col cx-gap-6"
      }
    >
      {information.map((info, index) => (
        <Fragment key={info.title}>
          <div className={"cx-gap-1 cx-flex cx-flex-col cx-flex-1"}>
            <p
              className={
                "cx-font-medium cx-text-sm cx-leading-6 cx-flex cx-gap-2 "
              }
            >
              {info.title}{" "}
              {info.optional && (
                <span
                  className={
                    "cx-flex cx-items-center cx-py-0.5 cx-px-2 cx-leading-normal cx-border-muted-3 cx-border-0.5 cx-text-[11px] cx-font-medium cx-text-neutral-darker cx-rounded-full"
                  }
                >
                  OPTIONAL
                </span>
              )}
            </p>
            <p
              className={
                "cx-text-neutral-darker cx-leading-tight cx-font-normal cx-text-sm"
              }
            >
              {info.subtitle.split("\n").map((line, index) => (
                <Fragment key={line}>
                  {line}
                  {index < info.subtitle.split("\n").length - 1 && <br />}
                </Fragment>
              ))}
            </p>
          </div>
          {index !== information.length - 1 && <Divider />}
        </Fragment>
      ))}
    </div>
  );
};
export default InformationBox;
