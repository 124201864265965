import React from "react";
import { Divider } from "../index";
import { formatCurrency, formatPercentage } from "../../utils";

export interface HistogramColumnProps {
  tag: string;
  onClick?: () => void;
  sectionData: HistogramColumnSectionProps[];
}

export interface HistogramColumnSectionProps {
  amount: number;
  isPlaceHolder?: boolean;
  backgroundClassName: string;
  labelProps: {
    showAmount: boolean;
    showLabel: boolean;
    labelAmount?: number;
    labelPrefix?: string | React.ReactNode;
    labelSuffix?: string | React.ReactNode;
    fontColorClassName: string;
  };
  index: number;
}

export interface HistogramProps {
  data: HistogramColumnProps[];
  numberVariant: NumberVariant;
  variant?: HistogramVariant;
  referenceValue?: number;
}

export enum NumberVariant {
  CURRENCY = "currency",
  PERCENTAGE = "percentage",
  NUMBER = "number",
}

export enum HistogramVariant {
  normal = "normal",
  slim = "slim",
}

export const MAX_COLUMNS_SUPPORTED = 12;
const calculateHeight = (
  value: number,
  max: number,
  isPlaceHolder?: boolean
): number => {
  const minHeight = 50; // Minimum height for visibility
  const maxHeight = 100; // Maximum height for the tallest bar
  const scaledHeight = ((isPlaceHolder ? max * 0.6 : value) / max) * maxHeight;
  return Math.max(scaledHeight, minHeight);
};

const getSectionLabel = (
  section: HistogramColumnSectionProps,
  variant: NumberVariant
): string => {
  switch (variant) {
    case NumberVariant.CURRENCY:
      return formatCurrency(section?.labelProps?.labelAmount || section.amount);
    case NumberVariant.PERCENTAGE:
      return formatPercentage(section.amount);
    case NumberVariant.NUMBER:
      return String(section.amount);
  }
};

const HistogramNormalColumn: React.FC<any> = ({
  index,
  item,
  maxWidthOverride,
  maxAmountDue,
  numberVariant,
}) => {
  const sectionInFocus: HistogramColumnSectionProps =
    item.sectionData[item.sectionData.length - 1];
  return (
    <div
      key={index}
      className={`text-center cx-flex cx-flex-col-reverse cx-items-center ${
        maxWidthOverride && "cx-flex-grow"
      }`}
      onClick={item.onClick}
    >
      <div className="cx-relative cx-w-full">
        {item.sectionData
          .sort(
            (a: { index: number }, b: { index: number }) => b.index - a.index
          )
          .map(
            (
              section: HistogramColumnSectionProps,
              sectionIndex: React.Key | null | undefined
            ) => (
              <div
                key={sectionIndex}
                className={`cx-flex cx-justify-center cx-flex-col cx-px-2 cx-py-1 cx-rounded ${
                  maxWidthOverride ? "cx-flex-grow" : "cx-w-16"
                } ${section.backgroundClassName}`}
                style={{
                  height: `${
                    !section.isPlaceHolder
                      ? calculateHeight(section.amount, maxAmountDue)
                      : calculateHeight(maxAmountDue * 0.6, maxAmountDue)
                  }px`,
                }}
              >
                {!section.isPlaceHolder && !maxWidthOverride && (
                  <div
                    className={`cx-text-xs cx-text-center cx-font-medium cx-flex cx-justify-center ${section?.labelProps?.fontColorClassName}`}
                  >
                    {sectionInFocus.labelProps?.showLabel && (
                      <>
                        {sectionInFocus.labelProps?.labelPrefix || ""}
                        {sectionInFocus.labelProps.showAmount
                          ? getSectionLabel(sectionInFocus, numberVariant)
                          : ""}
                        {sectionInFocus.labelProps.labelSuffix || ""}
                      </>
                    )}
                  </div>
                )}
              </div>
            )
          )}
      </div>
    </div>
  );
};
const HistogramSlimColumn: React.FC<any> = ({
  index,
  item,
  maxAmountDue,
  numberVariant,
}) => {
  const sortedSections = item.sectionData
    .sort((a: { index: number }, b: { index: number }) => a.index - b.index)
    .map((section: any, index: number, array: any[]) => {
      const cumulativeHeight = array
        .slice(0, index + 1)
        .reduce(
          (acc, curr) =>
            acc +
            calculateHeight(curr.amount, maxAmountDue, curr.isPlaceHolder),
          0
        );
      return {
        ...section,
        cumulativeHeight: cumulativeHeight,
      };
    });

  const columnHeight = sortedSections.reduce(
    (acc: number, section: HistogramColumnSectionProps) => {
      return (
        acc +
        calculateHeight(section.amount, maxAmountDue, section.isPlaceHolder)
      );
    },
    0
  );

  const sectionInFocus: HistogramColumnSectionProps =
    sortedSections[sortedSections.length - 1];

  return (
    <div
      key={index}
      className={`cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-1/12`}
      onClick={item.onClick}
    >
      <div
        className={`cx-text-xs cx-text-center cx-font-medium cx-z-50 ${sectionInFocus?.labelProps?.fontColorClassName}`}
      >
        {sectionInFocus?.labelProps?.showLabel && (
          <>
            {sectionInFocus?.labelProps?.labelPrefix || ""}
            {sectionInFocus?.labelProps?.showAmount
              ? getSectionLabel(sectionInFocus, numberVariant)
              : ""}
            {sectionInFocus?.labelProps?.labelSuffix || ""}
          </>
        )}
      </div>
      <div className="cx-relative" style={{ height: `${columnHeight + 10}px` }}>
        {sortedSections.map(
          (
            section: {
              backgroundClassName: any;
              index: number;
              cumulativeHeight: any;
            },
            sectionIndex: number
          ) => (
            <div
              key={sectionIndex}
              className={`cx-absolute cx-bottom-0 cx-transform -cx-translate-x-1/2 cx-w-4 ${
                section.backgroundClassName
              } cx-rounded-t-3xl cx-z-${50 - section.index * 10}`}
              style={{
                height: `${section.cumulativeHeight}px`,
              }}
            ></div>
          )
        )}
      </div>
    </div>
  );
};

const getMaxAmountDue = (data: HistogramColumnProps[]): number => {
  const sectionMax = data.map((item) =>
    Math.max(...item.sectionData.map((section) => section.amount))
  );
  return Math.max(...sectionMax);
};

const Histogram: React.FC<HistogramProps> = ({
  data,
  numberVariant,
  variant,
  referenceValue,
}) => {
  const maxAmountDue = getMaxAmountDue(data);
  const maxWidthOverride = data.length > MAX_COLUMNS_SUPPORTED;

  if (data.length > MAX_COLUMNS_SUPPORTED) {
    data = data.slice(data.length - MAX_COLUMNS_SUPPORTED, data.length);
  }

  return (
    <div className="cx-flex cx-flex-col cx-justify-center cx-mt-2 cx-w-full">
      <div className="cx-relative">
        <div
          className="cx-absolute cx-w-full cx-bottom-0 cx-z-0"
          style={{
            height: `${
              referenceValue
                ? calculateHeight(referenceValue, maxAmountDue, false)
                : 0
            }px`,
          }}
        >
          <Divider className="cx-border-1.5 cx-border-dashed cx-bg-neutral-100" />
        </div>
        <div className="cx-flex cx-space-x-2 cx-items-end cx-justify-between cx-w-full">
          {data.map((item, index) =>
            variant == HistogramVariant.normal ? (
              <HistogramNormalColumn
                index={index}
                item={item}
                maxWidthOverride={maxWidthOverride}
                maxAmountDue={maxAmountDue}
                numberVariant={numberVariant}
              />
            ) : (
              <HistogramSlimColumn
                index={index}
                item={item}
                maxAmountDue={maxAmountDue}
                numberVariant={numberVariant}
              />
            )
          )}
        </div>
      </div>
      <Divider className="cx-mb-4" />
      <div className="cx-flex cx-flex-row cx-space-x-2 cx-content-between cx-justify-between cx-w-full">
        {data.map((item, index) => (
          <div
            key={index}
            className={`cx-text-gray-500 cx-text-center cx-flex cx-flex-col-reverse cx-items-center ${
              maxWidthOverride ? "cx-w-1/12" : "cx-w-16"
            }`}
          >
            {item.tag}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Histogram;
