import { Outlet } from "react-router-dom";
import { CredibleLogoLight } from "../assets/images";
import { useTheme } from "../hooks";
import { QueryClient, QueryClientProvider } from "react-query";
import { Footer } from "../components";
import info from "../constants/info.json";
import { useEffect } from "react";

const queryClient = new QueryClient();

const Microflows = () => {
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-min-h-screen cx-sticky cx-font-[proxima-nova]">
        <div className="cx-flex cx-justify-center cx-items-center cx-w-full cx-border-b cx-border-stroke-primary">
          <div className="cx-flex cx-justify-between cx-items-center cx-w-full cx-max-w-[960px] cx-px-6 cx-py-4">
            <div className="cx-w-36 cx-h-6 cx-flex cx-justify-center cx-items-center">
              <img src={CredibleLogoLight} alt="CredibleX" />
            </div>
            <div className="cx-flex cx-justify-center cx-items-center"></div>
          </div>
        </div>
        <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-background-radial-gradient cx-w-full cx-p-8">
          <Outlet />
        </div>
        <div className="cx-p-2">
          <Footer text={info.footer} />
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default Microflows;
