import { useContext, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { OTPInput, StepNextButton } from "../../../components";
import {
  OTPType,
  StepperContext,
  useLoanApplication,
  useLoanCalculator,
  useSDKContext,
} from "../../../contexts";
import {
  OTP_FORM_INITIAL_VALUES,
  OTP_FORM_VALIDATION_SCHEMA,
  OTPFormFields,
} from "./service";
import {
  useIFrameEvents,
  useMedia,
  useSendEmailOTP,
  useSendOTP,
  useStopwatch,
} from "../../../hooks";

import { usePostHog } from "posthog-js/react";
import {
  LoanApplicationStage,
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../../hooks/api/types";
import { useDpConfig } from "../../../contexts/dpConfig";
import {
  handleInvoiceDiscountingLoanUpdate,
  handlePayableFinancingLoanUpdate,
} from "../../LoanCalculator/service";

const OTPForm = () => {
  const [creatingLoanApp, setCreatingLoanApp] = useState(false);
  const { previousStep, nextStep, setLoading } = useContext(StepperContext);
  const {
    state,
    actions: { create, refetch, update },
  } = useLoanApplication();
  const { state: calculatorState } = useLoanCalculator();
  const sdkContext = useSDKContext();
  const { postLoanApplicationStartedEvent } = useIFrameEvents();

  const { mutateAsync: sendOTP } = useSendOTP();
  const { mutateAsync: sendEmailOTP } = useSendEmailOTP();
  const { upload } = useMedia();
  const {
    state: { settings },
  } = useDpConfig();

  async function handleFixedTermLoanUpdate(id: string) {
    await update({
      id,
      loanType: LoanProductType.FIXED_TERM,
      fixedTermLoan: calculatorState.fixedTermLoan,
    });
  }

  async function handleRevenueBasedLoanUpdate(id: string) {
    const salesStatementPromises = state.salesStatementFiles.map((file) =>
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.SALES_STATEMENT,
        loanApplicationId: id,
      })
    );
    const salesStatementIds = await Promise.all(salesStatementPromises);
    const salesStatements = salesStatementIds.map((_statement, index) => ({
      documentId: salesStatementIds[index],
    }));
    await update({
      id,
      loanType: LoanProductType.REVENUE_BASED_FINANCING,
      revenueBasedLoan: {
        ...calculatorState.revenueBasedLoan,
        salesStatements: salesStatements,
      },
    });
  }

  async function handleBuyNowPayLaterLoanUpdate(id: string) {
    const salesInvoicePromises = state.salesInvoiceFiles.map((file) =>
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.INVOICE,
        loanApplicationId: id,
      })
    );
    const salesInvoiceIds = await Promise.all(salesInvoicePromises);
    const salesInvoices = salesInvoiceIds.map((_invoice, index) => ({
      invoiceId: salesInvoiceIds[index],
    }));
    await update({
      id,
      loanType: LoanProductType.BNPL,
      buyNowPayLaterLoan: {
        ...calculatorState.buyNowPayLaterLoan,
        salesInvoices: [...salesInvoices],
      },
    });
  }

  const posthog = usePostHog();

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik<OTPFormFields>({
    initialValues: {
      ...OTP_FORM_INITIAL_VALUES,
    },
    validationSchema: OTP_FORM_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      setCreatingLoanApp(true);
      try {
        const { id } = await create({
          user: {
            otpCode: values.otp,
            otpType: state.otpType,
            ...(state.otpType === OTPType.EMAIL
              ? { email: state.email }
              : {
                  localNumber: state.localNumber,
                  countryCode: state.countryCode,
                }),
          },
          reason: state.loanReason,
          ...(state.sourceId && { refId: state.sourceId }),
        });

        setLoading(true);

        //if we are in legacy flow mode, we update the loan application
        if (settings.disableLOCFlow) {
          switch (calculatorState.type) {
            case LoanProductType.FIXED_TERM:
              await handleFixedTermLoanUpdate(id);
              break;
            case LoanProductType.INVOICE_DISCOUNTING:
              await handleInvoiceDiscountingLoanUpdate(
                id,
                state.invoiceFiles,
                calculatorState,
                update,
                upload,
                undefined
              );
              break;
            case LoanProductType.PAYABLE_FINANCING:
              await handlePayableFinancingLoanUpdate(
                id,
                state.invoiceFiles,
                calculatorState,
                update,
                upload,
                undefined
              );
              break;
            case LoanProductType.REVENUE_BASED_FINANCING:
              await handleRevenueBasedLoanUpdate(id);
              break;
            case LoanProductType.BNPL:
              await handleBuyNowPayLaterLoanUpdate(id);
              break;
            default:
              throw new Error("Invalid loan type");
          }
        }

        let loanApplicationData: any = null;

        if (state.sourceId) {
          // if we are in LOC flow mode, we move to CALCULATOR stage
          if (!settings.disableLOCFlow) {
            await update({ id: id, stage: LoanApplicationStage.CALCULATOR });
          }
          loanApplicationData = await refetch();
        } else if (
          sdkContext.version === "1" &&
          sdkContext.onApplicationStarted
        ) {
          sdkContext.updateSDKContext({ prefilling: true });
          await Promise.resolve(sdkContext.onApplicationStarted(id));
          loanApplicationData = await refetch();
          sdkContext.updateSDKContext({ prefilling: false });
        } else {
          postLoanApplicationStartedEvent({ loanApplicationId: id });
          loanApplicationData = await refetch();
        }

        posthog.identify(loanApplicationData?.id, {
          distributionPartnerId: loanApplicationData.distributionPartnerId,
          distributionPartnerName:
            loanApplicationData.distributionPartner?.name,
          name: loanApplicationData?.user?.name,
          email: loanApplicationData?.user?.email,
          loanApplicationId: loanApplicationData?.id,
        });
      } catch (err: any) {
        console.error("Error occurred:", err);
        setFieldError("otp", err?.message);
      } finally {
        setCreatingLoanApp(false);
        setLoading(false);
      }
    },
  });

  const { seconds, start: startWatch } = useStopwatch(0, 0, 30);

  const handleResendSMSOTP = () => {
    sendOTP({
      countryCode: state.countryCode,
      localNumber: state.localNumber,
    })
      .then(() => {
        startWatch(0, 0, 30);
      })
      .catch((err) => {
        console.log(err);
        setFieldError("otp", err.message);
      });
  };

  const handleResendEmailOTP = () => {
    sendEmailOTP({
      email: state.email,
    })
      .then(() => {
        startWatch(0, 0, 30);
      })
      .catch((err) => {
        console.log(err);
        setFieldError("otp", err.message);
      });
  };

  const handleChangeVerify = () => {
    if (state.otpType === OTPType.SMS) {
      nextStep();
    }

    if (state.otpType === OTPType.EMAIL) {
      previousStep();
    }
  };

  const verificationMessage = useMemo(() => {
    const isSMS = state.otpType === OTPType.SMS;
    const recipient = isSMS
      ? state.countryCode + state.localNumber
      : state.email;
    return `We sent a verification code via ${
      isSMS ? "SMS" : "Email"
    } to ${recipient}`;
  }, [state.otpType]);

  useEffect(() => {
    startWatch();
  }, []);

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
        Please verify your{" "}
        {state.otpType === OTPType.SMS
          ? "mobile phone number"
          : "email address"}
      </div>
      <div className="cx-mt-2 cx-text-text-secondary cx-text-base cx-text-center">
        {verificationMessage}
      </div>
      <div
        className="cx-cursor-pointer cx-mt-1 cx-text-interaction-button-text-default cx-text-center cx-text-sm"
        onClick={state.otpType === OTPType.SMS ? previousStep : nextStep}
      >
        Change {state.otpType === OTPType.SMS ? "number" : "email address"}
      </div>
      <form
        className="cx-w-full cx-flex cx-flex-col cx-items-center cx-justify-center"
        onSubmit={handleSubmit}
      >
        <div className="cx-max-w-[400px] cx-mt-10 cx-w-full">
          <OTPInput
            length={6}
            value={values.otp}
            onChange={(val) => {
              setFieldValue("otp", val);
            }}
            error={!!touched.otp && !!errors.otp ? errors.otp : ""}
          />
          <div className="cx-mt-4 cx-text-sm cx-text-center">
            <span className="cx-text-text-tertiary cx-mr-1">
              Didn’t receive an{" "}
              {state.otpType === OTPType.SMS ? "SMS" : "Email"}?
            </span>
            {seconds > 0 ? (
              <span className="cx-text-interaction-button-text-disabled">
                Resend code in 00:{`0${seconds}`.slice(-2)}
              </span>
            ) : (
              <span
                onClick={() => {
                  if (state.otpType === OTPType.SMS) {
                    handleResendSMSOTP();
                  }

                  if (state.otpType === OTPType.EMAIL) {
                    handleResendEmailOTP();
                  }
                }}
                className="cx-text-interaction-button-primary-default cx-cursor-pointer"
              >
                Resend OTP
              </span>
            )}
            <span className="cx-text-text-tertiary cx-mr-1"> or </span>
            <span
              onClick={handleChangeVerify}
              className="cx-text-interaction-button-primary-default cx-cursor-pointer"
            >
              verify by {state.otpType === OTPType.SMS ? "Email" : "SMS"}
            </span>
          </div>
          <div className="cx-w-full cx-mt-10">
            <StepNextButton
              label="Next"
              loading={state.updating || creatingLoanApp}
              disabled={state.updating || creatingLoanApp}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default OTPForm;
