// import { Button } from "../../../components";
// import { DownloadIcon } from "../../../assets/icons";
import { LoanApplication, LoanPaymentStatus } from "../../../contexts";
import { FC, useMemo } from "react";
import { sortBy } from "lodash";
import LoanPaymentTableRow from "./LoanPaymentTableRow";
import { LoanProductType } from "../../../hooks/api/types";
import { useLoanApplicationPayments } from "../../../hooks";
import { useLoanApplicationPaymentsEnabled } from "../../../hooks/useLoanApplicationPaymentsEnabled";

type LoanPaymentScheduleTableProps = {
  loan: LoanApplication;
};

export const LoanPaymentScheduleTable: FC<LoanPaymentScheduleTableProps> = ({
  loan,
}) => {
  const loanPaymentsEnabled = useLoanApplicationPaymentsEnabled(loan);

  const { data: loanPayments = [] } = useLoanApplicationPayments(
    loan.id,
    loanPaymentsEnabled
  );

  const passedPayments = useMemo(() => {
    return sortBy(
      loanPayments.filter((e) => e.status === LoanPaymentStatus.PAID),
      [(v) => new Date(v.date)]
    );
  }, [loanPayments]);

  const upcomingPayments = useMemo(() => {
    return sortBy(
      loanPayments.filter((e) => e.status !== LoanPaymentStatus.PAID),
      [(v) => new Date(v.date)]
    );
  }, [loanPayments]);

  if (
    loan.loanType == LoanProductType.PAYABLE_FINANCING ||
    loan.loanType == LoanProductType.INVOICE_DISCOUNTING
  )
    return null;
  if (!loanPayments) return null;

  return (
    <div>
      <div className="cx-w-full cx-flex cx-flex-row cx-items-center cx-justify-between cx-py-4">
        <div className="cx-text-text-primary cx-font-semibold cx-text-lg">
          Payment schedule
        </div>
        {/*<Button*/}
        {/*  slim={true}*/}
        {/*  label={*/}
        {/*    <div className="cx-flex cx-flex-row cx-items-center cx-gap-x-2">*/}
        {/*      <DownloadIcon />*/}
        {/*      <div className="cx-interaction-text-inverse cx-font-semibold cx-text-sm">*/}
        {/*        Download*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*/>*/}
      </div>

      <div className="cx-w-full cx-flex cx-flex-col cx-gap-y-2 cx-rounded-xl cx-border-stroke-primary cx-border cx-p-2">
        <div className="cx-flex cx-flex-row cx-items-center cx-p-2 cx-min-h-[40px]">
          <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-base">
            Amount
          </div>
          <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-base">
            Date
          </div>
          <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-base">
            Remaining
          </div>
          <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-base">
            Status
          </div>
        </div>
        {passedPayments.map((payment, index) => (
          <LoanPaymentTableRow
            key={`payment-schedule-${index}`}
            payment={payment}
            index={index}
          />
        ))}
        <div className="cx-w-full cx-flex cx-flex-row cx-items-center cx-py-2 cx-min-h-[47px] cx-relative">
          <div className="cx-w-full cx-h-[2px] cx-rounded cx-bg-gradient-to-br cx-from-brand-secondary-regular cx-to-brand-primary-regular" />
          <div className="cx-flex cx-items-center cx-justify-center cx-absolute cx-top-0 cx-left-0 cx-bottom-0 cx-right-0">
            <div className="cx-bg-background-default">
              <div className="cx-px-4 cx-py-2 cx-text-transparent cx-text-xs cx-font-medium cx-uppercase cx-bg-clip-text cx-bg-gradient-to-br cx-from-brand-secondary-regular cx-to-brand-primary-regular">
                Upcoming payments
              </div>
            </div>
          </div>
        </div>
        {upcomingPayments.map((payment, index) => (
          <LoanPaymentTableRow
            key={`payment-schedule-${index}`}
            payment={payment}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default LoanPaymentScheduleTable;
