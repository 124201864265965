import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { CONFIG_INITIAL_STATE, DpConfigActions } from "./types";
import { DpConfigReducer } from "./reducer";
import { useLoanProducts, useTheme } from "../../hooks";
import { ButtonLoaderIcon, WhatsAppFloatIcon } from "../../assets/icons";
import { LOCALSTORAGE_KEYS } from "../../constants";
import { useSDKContext } from "../sdk";

const DpConfigContext = createContext(CONFIG_INITIAL_STATE);

type Props = {
  children: React.ReactNode;
};

const Loader = () => {
  return (
    <div className="cx-w-full cx-h-screen cx-flex cx-justify-center cx-items-center cx-text-brand-primary-regular">
      <ButtonLoaderIcon className="cx-animate-spin cx-w-6 cx-h-6" />
    </div>
  );
};

export const DpConfigProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(
    DpConfigReducer,
    CONFIG_INITIAL_STATE["state"]
  );

  const { data: loanProducts, isLoading } = useLoanProducts();
  const {
    options: { theme },
  } = useSDKContext();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme({
      ...(loanProducts?.themeConfig && { ...loanProducts.themeConfig }),
      ...(theme && { ...theme }),
    });

    // Storing the theme configuration in local storage to ensure that the timeout screen renders with the correct theme
    // based on the distribution partner's settings.
    localStorage.setItem(
      "theme",
      JSON.stringify({
        ...(loanProducts?.themeConfig && { ...loanProducts.themeConfig }),
        ...(theme && { ...theme }),
      })
    );
  }, [loanProducts?.themeConfig, theme]);

  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true });
    if (loanProducts?.dpConfig?.settings) {
      dispatch({
        type: "SET_SETTINGS",
        payload: loanProducts?.dpConfig?.settings,
      });
    }

    if (loanProducts?.dpConfig?.uiLocalizationData) {
      dispatch({
        type: "SET_UI_LOCALIZATION_DATA",
        payload: loanProducts?.dpConfig?.uiLocalizationData,
      });
    }

    if (loanProducts?.dpConfig?.dpTermsUrl) {
      dispatch({
        type: "SET_DP_TERMS_URL",
        payload: loanProducts?.dpConfig?.dpTermsUrl,
      });
    }

    if (loanProducts?.dpConfig?.name) {
      dispatch({
        type: "SET_DP_NAME",
        payload: loanProducts?.dpConfig?.name,
      });
    }

    if (loanProducts?.loanConfig) {
      dispatch({
        type: "SET_PRODUCTS",
        payload: loanProducts?.loanConfig,
      });
      dispatch({ type: "SET_LOADING", payload: false });
    }
    dispatch({ type: "SET_LOADING", payload: isLoading });
  }, [isLoading]);

  const actions = useMemo<DpConfigActions>(
    () => ({
      setSettings: (settings) => {
        dispatch({
          type: "SET_SETTINGS",
          payload: settings,
        });
      },
      setUiLocalizationData: (uiLocalizationData) => {
        dispatch({
          type: "SET_UI_LOCALIZATION_DATA",
          payload: uiLocalizationData,
        });
      },
      setDpTermsUrl: (dpTermsUrl) => {
        dispatch({
          type: "SET_DP_TERMS_URL",
          payload: dpTermsUrl,
        });
      },
      setDpName: (dpName) => {
        dispatch({
          type: "SET_DP_NAME",
          payload: dpName,
        });
      },
      setLoanProducts: (products) => {
        dispatch({
          type: "SET_PRODUCTS",
          payload: products,
        });
      },
      setLoading: (loading) => {
        dispatch({ type: "SET_LOADING", payload: loading });
      },
    }),
    [dispatch]
  );

  const contextValue = useMemo(() => ({ state, actions }), [state, actions]);

  if (state.loading || isLoading) {
    return <Loader />;
  }

  return (
    <DpConfigContext.Provider value={contextValue}>
      <div>{children}</div>
      {!loanProducts?.dpConfig?.disableWhatsappBubble && (
        <WhatsAppFloatIcon
          title={"Contact support"}
          className="cx-z-20 cx-h-14 cx-fixed cx-bottom-0 cx-right-0 cx-m-3"
          onClick={() => {
            let url = `https://wa.me/${
              import.meta.env.VITE_SUPPORT_WHATSAPP_NUMBER
            }`;
            const loanApplicationId = localStorage.getItem(
              LOCALSTORAGE_KEYS.LOAN_APPLICATION_EXTERNAL_ID
            );
            if (loanApplicationId) {
              url = `${url}?text=${encodeURIComponent(
                "Query for loan ID: "
              )}${loanApplicationId}`;
            }
            window.open(url, "_blank");
          }}
        />
      )}
    </DpConfigContext.Provider>
  );
};

export const useDpConfig = () => useContext(DpConfigContext);
