import { FC, useEffect, useState } from "react";
import {
  BOOLEAN_CARD_OPTIONS,
  INDIVIDUAL_SHAREHOLDER_INITIAL_VALUES,
  INDIVIDUAL_SHAREHOLDER_VALIDATION_SCHEMA,
  IndividualShareholderFields,
  isEmailUniqueInShareholders,
} from "../service";
import { useFormik } from "formik";
import {
  Button,
  CardRadioOptions,
  Divider,
  InputField,
  StepNextButton,
} from "../../../../components";
import {
  IndividualShareholder,
  useLoanApplication,
} from "../../../../contexts";
import { InfoIcon } from "../../../../assets/icons";
import { CompanyShareholderType } from "../../../../hooks/api/types";
import ShareholderKycFormModal from "../ShareHolderKycFormModal";

type Props = {
  shareholder?: IndividualShareholderFields;
  onSubmit: (values: IndividualShareholderFields) => void;
  onCancel: () => void;
};

const IndividualShareholderForm: FC<Props> = ({
  shareholder,
  onSubmit,
  onCancel,
}) => {
  const {
    state: { individualShareholders, companyShareholders, stage },
    actions: { update, refetch },
  } = useLoanApplication();

  const [addedShareholder, setAddedShareholder] =
    useState<IndividualShareholder>();
  const [kycModal, setKycModal] = useState(false);
  const [eidUploaded, setEidUploaded] = useState(false);

  const isShareholder =
    !shareholder || shareholder.type === CompanyShareholderType.SHAREHOLDER;

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldError,
    handleSubmit,
  } = useFormik<IndividualShareholderFields>({
    enableReinitialize: true,
    initialValues: shareholder ?? INDIVIDUAL_SHAREHOLDER_INITIAL_VALUES,
    validationSchema: INDIVIDUAL_SHAREHOLDER_VALIDATION_SCHEMA,
    onSubmit: () => {
      values.hasPOA || shareholder ? skipEidUploadHandler() : AddEidHandler();
    },
  });

  const AddShareholder = async (
    skipEid: boolean
  ): Promise<IndividualShareholderFields | undefined> => {
    const shareholders = [...individualShareholders, ...companyShareholders];
    const uniqueEmail = isEmailUniqueInShareholders(
      values.email,
      shareholders,
      shareholder
    );
    if (!uniqueEmail) {
      setFieldError("email", "Email already exists for another shareholder");
      return;
    }
    const newShareholder = { ...values };
    newShareholder.skipEidUpload = skipEid;

    await update({
      individualShareholders: [...individualShareholders, newShareholder],
    });

    await refetch();
    return newShareholder;
  };

  const skipEidUploadHandler = async () => {
    const newShareholder = await AddShareholder(true);
    if (!newShareholder) return;
    onSubmit(newShareholder);
  };

  const AddEidHandler = async () => {
    const newShareholder = await AddShareholder(false);
    if (!newShareholder) return;
    setAddedShareholder(newShareholder);
  };

  const getBtnLabel = () => {
    if (shareholder) {
      return "Save Changes";
    }

    if (!shareholder) {
      return values.hasPOA ? "Add Shareholder" : "Add Shareholder Emirates ID";
    }

    return values.hasPOA
      ? "Update Shareholder"
      : eidUploaded
      ? "Update Shareholder Emirates ID"
      : "Add Shareholder Emirates ID";
  };

  useEffect(() => {
    if (addedShareholder) {
      setKycModal(true);
    }
  }, [addedShareholder]);

  useEffect(() => {
    // If the EID for the current shareholder is already added then we set the eidUploaded to true. This is used for labelling of button accordingly
    if (individualShareholders && shareholder) {
      for (let sh of individualShareholders) {
        if (sh.id === shareholder.id) {
          if (sh.latestKycApplication) {
            setEidUploaded(true);
          }
          break;
        }
      }
    }
  }, [individualShareholders]);

  return (
    <div className="cx-flex cx-flex-col">
      <form onSubmit={handleSubmit}>
        <div className="cx-flex cx-justify-between cx-mb-4">
          <div className="cx-mr-1">
            <InputField
              label="First name"
              tick={touched.firstName && !errors.firstName}
              inputProps={{
                name: "firstName",
                value: values.firstName,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              error={
                touched.firstName && !!errors.firstName ? errors.firstName : ""
              }
            />
          </div>
          <div className="cx-ml-1">
            <InputField
              label="Last name"
              tick={touched.lastName && !errors.lastName}
              inputProps={{
                name: "lastName",
                value: values.lastName,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              error={
                touched.lastName && !!errors.lastName ? errors.lastName : ""
              }
            />
          </div>
        </div>
        <div className="cx-flex cx-mb-4">
          <div className="cx-max-w-[120px] cx-mr-1">
            <InputField
              label="Country code"
              inputProps={{
                name: "countryCode",
                value: "🇦🇪 " + values.countryCode,
                readOnly: true,
              }}
              error={touched.countryCode ? errors.countryCode : ""}
            />
          </div>
          <div className="cx-ml-1 cx-w-full">
            <InputField
              label="Phone number"
              inputProps={{
                name: "localNumber",
                value: values.localNumber,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              error={touched.localNumber ? errors.localNumber : ""}
            />
          </div>
        </div>
        <div className="cx-mb-4">
          <InputField
            label="Business email"
            tick={touched.email && !errors.email}
            error={touched.email && !!errors.email ? errors.email : ""}
            inputProps={{
              name: "email",
              value: values.email,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
          />
        </div>
        <div className="cx-font-semibold cx-text-text-primary cx-text-base cx-mb-4 cx-mt-10">
          Has authority to take out loan on behalf of the company?
        </div>
        <div className="cx-flex cx-justify-between cx-mb-4">
          <CardRadioOptions
            options={BOOLEAN_CARD_OPTIONS}
            inputProps={{
              name: "hasPOA",
              value: `${values.hasPOA}`,
              onChange: (e) => {
                setFieldValue("hasPOA", e.target.value === "true");
              },
            }}
            layout="horizontal"
            keyPropName="value"
            hideValues
          />
        </div>
        <div className="cx-my-10">
          <Divider />
        </div>
        <div className="cx-mt-4 cx-w-full cx-flex cx-items-center cx-gap-4">
          <div className="cx-w-1/3">
            <Button
              outlined
              fullWidth
              label="X"
              type="button"
              onClick={onCancel}
            />
          </div>
          <StepNextButton label={getBtnLabel()} type="submit" />
        </div>

        {!values.hasPOA && isShareholder && (
          <div>
            <div className="cx-my-6">
              <Divider />
            </div>
            <Button
              outlined
              fullWidth
              label={`${
                shareholder
                  ? eidUploaded
                    ? `Update Emirates ID`
                    : `Add Emirates ID`
                  : `Skip Emirates ID verification & Continue`
              }`}
              type="button"
              onClick={() => {
                shareholder ? AddEidHandler() : skipEidUploadHandler();
              }}
            />

            <div className="cx-my-3 cx-flex">
              <InfoIcon width={16} height={16} stroke="blue" />
              <p className="cx-pl-3 cx-text-sl cx-text-text-secondary ">
                if you skip Emirates ID verification then email would be sent to
                shareholder to perform KYC.
              </p>
            </div>
          </div>
        )}
      </form>

      {kycModal && (
        <ShareholderKycFormModal
          shareholder={addedShareholder!}
          isOpen={kycModal}
          onClose={() => {
            onSubmit(addedShareholder!);
            setKycModal(false);
          }}
        ></ShareholderKycFormModal>
      )}
    </div>
  );
};

export default IndividualShareholderForm;
