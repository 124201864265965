import { useContext } from "react";
import { StepperContext } from "../../../contexts";
import { Button } from "../../../components";

type ReviewFieldProps = {
  label: string;
  value: string;
  editStep?: [number, number];
  disabled?: boolean;
  description?: string;
};

const LoanReviewField = ({
  label,
  value,
  editStep,
  disabled,
  description,
}: ReviewFieldProps) => {
  const updatedValue = value?.split("\n");
  const { gotoStep } = useContext(StepperContext);
  return (
    <div className="cx-flex cx-flex-row cx-items-center cx-justify-between">
      <div className="cx-flex cx-flex-col cx-my-4">
        <div className="cx-text-sm cx-text-text-secondary">{label}</div>
        <div
          className={`cx-text-base ${
            disabled ? "cx-text-text-secondary" : "cx-text-text-primary"
          }`}
        >
          {updatedValue?.map((val, i) => (
            <p key={i}>{val}</p>
          ))}
        </div>
        {description && (
          <div className="cx-text-sm cx-text-text-secondary cx-mt-2">
            {description}
          </div>
        )}
      </div>
      {!!editStep && (
        <div className="cx-my-4 cx-ml-2 cx-text-xs">
          <Button
            label="Edit"
            slim
            onClick={() => {
              gotoStep(editStep, [5, 0]);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LoanReviewField;
