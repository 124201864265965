import { mergeWith } from "lodash";
import { DpConfigReducerAction, DpConfigState } from "./types";

// This customises the merge by completly overriding arrays if any
const arrayOverwriteCustomizer = (objValue: any, srcValue: any) => {
  if (Array.isArray(objValue)) {
    return srcValue;
  }
};

export const DpConfigReducer = (
  state: DpConfigState,
  action: DpConfigReducerAction
): DpConfigState => {
  switch (action.type) {
    case "SET_SETTINGS":
      const updatedSettings = Object.assign(state.settings, action.payload);

      const shouldHideFinancialInformation =
        updatedSettings.disableBankStatementsUpload &&
        updatedSettings.disableVATStatementsUpload &&
        updatedSettings.disableSalesStatementsUpload &&
        !updatedSettings.disbursementAccountProvided;
      return {
        ...state,
        settings: updatedSettings, //to not override the initial setting in case of messing attribute
        shouldHideFinancialInformation,
      };
    case "SET_UI_LOCALIZATION_DATA":
      return {
        ...state,
        uiLocalizationData: mergeWith(
          {},
          state.uiLocalizationData,
          action.payload,
          arrayOverwriteCustomizer
        ),
      };
    case "SET_DP_TERMS_URL":
      return {
        ...state,
        dpTermsUrl: action.payload,
      };
    case "SET_DP_NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
