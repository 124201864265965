import { FC, ReactNode, useState } from "react";
import { PlusLgIcon } from "../../assets/icons";
import clsx from "clsx";

type Props = {
  heading: string;
  children: ReactNode;
};

const Accordion: FC<Props> = ({ heading, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="w-full">
      <div
        className="cx-flex cx-w-full cx-justify-between cx-items-center cx-cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <div className="cx-text-text-primary cx-font-medium cx-pr-2">
          {heading}
        </div>
        <PlusLgIcon
          className={clsx(
            "cx-text-brand-primary-regular cx-w-6 cx-h-6 cx-transition-[transform] cx-duration-1000 cx-flex-shrink-0",
            {
              "cx-rotate-[135deg]": open,
            }
          )}
        />
      </div>
      <div
        className={clsx(
          "cx-overflow-hidden cx-max-h-0 cx-transition-[max-height] cx-duration-1000 cx-ease-in-out",
          {
            "cx-max-h-96 cx-overflow-scroll": open,
          }
        )}
      >
        <div className="cx-text-text-secondary cx-py-4">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
