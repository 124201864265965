import { FC, ReactNode } from "react";

export type PillProps = {
  label?: ReactNode;
  value?: string | number;
  isSelected?: boolean;
  onClick?(value?: string | number): void;
};

export const Pill: FC<PillProps> = ({ label, value, isSelected, onClick }) => {
  return (
    <div
      className={`cx-py-4 cx-px-6 cx-w-max cx-border hover:cx-border-stroke-brand hover:cx-border-1.5 cx-rounded-full cx-cursor-pointer cx-select-none cx-transition-all
        ${
          isSelected
            ? "cx-border-stroke-brand cx-border-1.5"
            : "cx-border-gray-200"
        }
      `}
      key={value}
      onClick={() => onClick?.(value)}
    >
      {label}
    </div>
  );
};

export default Pill;
