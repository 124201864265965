import { FC, ReactNode, useCallback } from "react";
import Pill from "../Pill/Pill";

type Value = string | number;

export type Option = {
  value: Value;
  label: ReactNode;
};

export type MultiSelectPillsProps = {
  title?: ReactNode;
  options?: Option[];
  selected?: Value[];
  onChange?(selected: Value[]): void;
  multiple?: boolean;
};

export const SelectPills: FC<MultiSelectPillsProps> = ({
  title,
  options,
  selected = [],
  onChange,
  multiple = true,
}) => {
  const handleChange = useCallback(
    (value: Value) => {
      const isSelected = selected?.includes(value);

      if (!multiple) {
        onChange?.(isSelected ? [] : [value]);
        return;
      }

      if (isSelected) {
        const newSelected = selected?.filter((v) => v !== value) ?? [];
        onChange?.(newSelected);
      } else {
        onChange?.([...selected, value]);
      }
    },
    [selected, onChange, multiple]
  );

  return (
    <>
      {title && (
        <div className="cx-text-2xl cx-font-bold cx-text-center cx-mb-8">
          {title}
        </div>
      )}
      <div className="cx-flex cx-flex-row cx-flex-wrap cx-justify-center cx-space-x-2 cx-gap-4">
        {options?.map(({ value, label }) => (
          <Pill
            key={value}
            value={value}
            label={label}
            isSelected={selected?.includes(value)}
            onClick={() => handleChange(value)}
          />
        ))}
      </div>
    </>
  );
};

export default SelectPills;
