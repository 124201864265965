import { useFormik } from "formik";
import { useContext } from "react";
import { Button, CardRadioOptions } from "../../components";
import { StepperContext, useLoanOffer } from "../../contexts";
import {
  LoanOfferDeclineReasonFields,
  LOAN_OFFER_DECLINE_REASON_INITIAL_VALUES,
  LOAN_OFFER_DECLINE_REASON_VALIDATION_SCHEMA,
} from "./service";
import { DocumentIcon } from "../../assets/icons";
import { useDeclineLoanOffer } from "../../hooks";

const options = [
  {
    icon: <DocumentIcon className="cx-text-text-brand" />,
    label: "I don't need a loan anymore",
    inputProps: {
      value: "I don't need a loan anymore",
    },
  },
  {
    icon: <DocumentIcon className="cx-text-text-brand" />,
    label: "I received a loan from different company",
    inputProps: {
      value: "I received a loan from different company",
    },
  },
  {
    icon: <DocumentIcon className="cx-text-text-brand" />,
    label: "Interest is too high",
    inputProps: {
      value: "Interest is too high",
    },
  },
  {
    icon: <DocumentIcon className="cx-text-text-brand" />,
    label: "Other",
    inputProps: {
      value: "Other",
    },
  },
];

const LoanOfferDeclineReason = () => {
  const { nextStep, gotoStep } = useContext(StepperContext);
  const {
    state,
    actions: { update },
  } = useLoanOffer();

  const { mutateAsync: declineLoanOffer, isLoading } = useDeclineLoanOffer();

  const { values, touched, errors, handleChange, handleSubmit, setFieldError } =
    useFormik<LoanOfferDeclineReasonFields>({
      initialValues: {
        ...LOAN_OFFER_DECLINE_REASON_INITIAL_VALUES,
      },
      validationSchema: LOAN_OFFER_DECLINE_REASON_VALIDATION_SCHEMA,
      onSubmit: (values) => {
        update({
          declineReason: values.reason,
        })
          .then(() => {
            declineLoanOffer({ id: state.id, reason: values.reason })
              .then(() => {
                nextStep();
              })
              .catch((err) => {
                console.log(err);
                setFieldError(
                  "reason",
                  err?.message || "Something went wrong, refresh and try again"
                );
              });
          })
          .catch((err) => {
            console.log(err);
            setFieldError(
              "reason",
              err?.message || "Something went wrong, refresh and try again"
            );
          });
      },
    });

  console.log({ state });

  return (
    <div className="cx-max-w-[960px] cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        Why do you want to decline?
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <form onSubmit={handleSubmit}>
          <div className="cx-w-full cx-py-10">
            <CardRadioOptions
              options={options}
              inputProps={{
                name: "reason",
                value: values.reason,
                onChange: handleChange,
              }}
            />
            {touched.reason && !!errors.reason && (
              <div className="cx-text-xs cx-py-1 cx-text-text-error">
                {errors.reason}
              </div>
            )}
          </div>
          <div className="cx-flex cx-flex-col cx-gap-4">
            <Button
              label="Decline loan offer"
              danger
              fullWidth
              type="submit"
              {...(isLoading && { loader: "left" })}
            />
            <Button
              label="Cancel"
              outlined
              fullWidth
              onClick={() => {
                gotoStep([0, 0]);
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoanOfferDeclineReason;
