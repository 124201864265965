import { InputField } from "../";

type Props = {
  label: string;
  value: number;
  error?: string;
  unit?: string;
  isValid?: boolean;
  setValue: (value: number) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
};

const NumericInputField: React.FC<Props> = ({
  label,
  value,
  error,
  unit = "AED",
  isValid = false,
  setValue,
  onBlur,
}) => {
  return (
    <InputField
      tick={isValid}
      label={label}
      error={error}
      inputProps={{
        value: `${unit} ${(value ?? "").toLocaleString("en-AE")}`,
        onChange: (e) => {
          const str = e.target.value;
          const num = Number(str.replace(unit + " ", "").replaceAll(",", ""));
          if (isNaN(num)) {
            return;
          }
          setValue(num);
        },
        onBlur,
      }}
    />
  );
};

export default NumericInputField;
