import LoanOfferFlow from "./LoanOfferFlow";
import LoanOfferStart from "./LoanOfferStart";
import LoanOfferAddAccount from "./LoanOfferAddAccount";
import LoanOfferSelectAccount from "./LoanOfferSelectAccount";
import LoanOfferSelectSignType from "./LoanOfferSelectSignType";
import LoanOfferSignInput from "./LoanOfferSignInput";
import LoanOfferReview from "./LoanOfferReview";
import LoanOfferDeclineReason from "./LoanOfferDeclineReason";
import LoanOfferDeclined from "./LoanOfferDeclined";
import { LoanOffer, StepConfig } from "../../contexts";
import { LoanOfferStage } from "../../hooks/api/types";
import { DpConfigProvider } from "../../contexts/dpConfig";
import SetupDirectDebitIntro from "./SetupDirectDebitIntro";

export enum StepName {
  LOAN_OFFER_START = "Loan Offer Start",
  SELECT_DEBIT_ACCOUNT = "Select Debit Account",
  LOAN_OFFER_SIGN = "Loan Offer Sign",
  LOAN_OFFER_REVIEW = "Loan Offer Review",
  DECLINE_LOAN_OFFER = "Decline Loan Offer",
}

export const STEP_STAGE_NAME_MAP: {
  [key in LoanOfferStage]: [number, number];
} = {
  REVIEW: [0, 0],
  SETUP_DIRECT_DEBIT: [1, 0],
  SELECT_BANK_ACCOUNT: [1, 1],
  ADD_BANK_ACCOUNT: [1, 2],
  SELECT_SIGN_MODE: [2, 0],
  COMPLETED: [3, 0],
  DECLINED: [4, 0],
};

export const stepConfig: StepConfig = [
  {
    name: StepName.LOAN_OFFER_START,
    subSteps: [
      {
        name: "Loan Offer Start",
        component: <LoanOfferStart />,
      },
    ],
  },
  {
    name: StepName.SELECT_DEBIT_ACCOUNT,
    subSteps: [
      {
        name: "Set up direct debit",
        component: <SetupDirectDebitIntro />,
      },
      {
        name: "Select Debit Account",
        component: <LoanOfferSelectAccount />,
      },
      {
        name: "Add Debit Account",
        component: <LoanOfferAddAccount />,
      },
    ],
  },
  {
    name: StepName.LOAN_OFFER_SIGN,
    subSteps: [
      {
        name: "Select Sign Type",
        component: <LoanOfferSelectSignType />,
      },
      {
        name: "Sign Loan Offer",
        component: <LoanOfferSignInput />,
      },
    ],
  },
  {
    name: StepName.LOAN_OFFER_REVIEW,
    subSteps: [
      {
        name: "Loan Offer Review",
        component: <LoanOfferReview />,
      },
    ],
  },
  {
    name: StepName.DECLINE_LOAN_OFFER,
    subSteps: [
      {
        name: "Decline Loan Offer Reason",
        component: <LoanOfferDeclineReason />,
      },
      {
        name: "Loan Offer Declined",
        component: <LoanOfferDeclined />,
      },
    ],
  },
];

const LoanOfferMicroflow = () => (
  <LoanOffer>
    <LoanOfferFlow stepConfig={stepConfig} />
  </LoanOffer>
);

export {
  LoanOfferFlow,
  LoanOfferStart,
  LoanOfferAddAccount,
  LoanOfferSelectAccount,
  LoanOfferSelectSignType,
  LoanOfferSignInput,
  LoanOfferReview,
  LoanOfferDeclineReason,
  LoanOfferDeclined,
  LoanOfferMicroflow,
};
