import { useState } from "react";
import { useLoanApplication } from "../../../../contexts";
import { useGetOpenBankingStatus } from "../../../../hooks";
import { OpenBankingStatus } from "../../../../hooks/api/types";
import { PlusIcon } from "../../../../assets/icons";
import { Button } from "../../../../components";
import { ConnectBankAccountModal } from "../../FinancialInformation";

const ConnectedBanksReviewSection = () => {
  const [integratedBanks, setIntegratedBanks] = useState([]);
  const {
    state: { id, leanApplicationId },
  } = useLoanApplication();
  const [connectBankAccountModalOpen, setConnectBankAccountModalOpen] =
    useState(false);

  useGetOpenBankingStatus(
    id,
    (data) => {
      console.log(data);
      if (data && data.status !== OpenBankingStatus.IN_PROGRESS) {
        if (data.banks?.length) {
          setIntegratedBanks(data.banks);
        }
      }
    },
    !!leanApplicationId
  );

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
        <div className="cx-flex cx-flex-col cx-justify-center">
          <div className="cx-text-2xl cx-font-bold cx-items-center">
            Connected banks
          </div>
        </div>
        <Button
          label={
            <div className="cx-flex cx-items-center">
              <PlusIcon className="cx-mr-2" />
              <div>Add</div>
            </div>
          }
          slim={true}
          onClick={() => {
            setConnectBankAccountModalOpen(true);
          }}
        />
      </div>

      {!!integratedBanks.length && (
        <div className="cx-mt-6 cx-flex cx-items-center cx-gap-10">
          {integratedBanks.map((bank: any, index) => (
            <div key={index} className="cx-flex cx-flex-col">
              <div className="cx-w-8 cx-h-8 cx-mb-2 cx-bg-black cx-rounded">
                <img src={bank.logo} />
              </div>
              <div className="cx-text-text-primary cx-text-lg">{bank.name}</div>
            </div>
          ))}
        </div>
      )}
      <ConnectBankAccountModal
        isOpen={connectBankAccountModalOpen}
        onClose={() => setConnectBankAccountModalOpen(false)}
      />
    </div>
  );
};

export default ConnectedBanksReviewSection;
