import { ReactNode, useContext, useState } from "react";
import { BoxTabs, Hint, StepNextButton } from "../../../components";
import { StepperContext, useLoanApplication } from "../../../contexts";
import { ConnectIcon, UploadIcon } from "../../../assets/icons";

const GradientBorderContainer = ({
  className,
  disabled,
  children,
}: {
  className?: string;
  disabled?: boolean;
  children: ReactNode;
}) => (
  <div
    className={`cx-bg-gradient-to-br ${className} ${
      disabled
        ? "cx-bg-gradient-to-br cx-from-stroke-primary cx-to-stroke-primary cx-p-0.5"
        : "cx-from-[#EDA3FC] cx-from-10% cx-via-[#2529EB] cx-via-80% cx-to-[#01B2FF] cx-to-90% cx-p-0.5"
    }`}
  >
    {children}
  </div>
);

const GradientContainer = ({
  className,
  children,
}: {
  className?: string;
  disabled?: boolean;
  children: ReactNode;
}) => (
  <div
    className={`cx-bg-gradient-to-br cx-from-[#EDA3FC] cx-via-[#2529EB] cx-via-80% cx-to-[#2529EB] ${className}`}
  >
    {children}
  </div>
);

const ConnectBankAccount = ({ selected }: { selected?: boolean }) => {
  return (
    // <GradientBorderContainer
    //   disabled={!selected}
    //   className="cx-rounded-lg cx-overflow-hidden"
    // >
    //   <div className="cx-bg-background-default cx-rounded-lg cx-p-0.5">
    //     <GradientContainer className="cx-w-full cx-rounded cx-overflow-hidden cx-py-1 cx-px-2">
    //       <div className="cx-text-center cx-text-xs cx-uppercase cx-text-text-inverse">
    //         Recommended
    //       </div>
    //     </GradientContainer>
    //     <div className="cx-flex cx-flex-col cx-items-center cx-p-4">
    //       <div className="cx-w-6 cx-h-6">
    //         <ConnectIcon className="cx-text-stroke-brand" />
    //       </div>
    //       <div className="cx-text-base">Connect bank</div>
    //     </div>
    //   </div>
    // </GradientBorderContainer>
    <div
      className={`cx-flex cx-flex-col cx-w-full cx-h-full cx-items-center cx-justify-center cx-rounded-lg cx-overflow-hidden cx-border ${
        selected ? "cx-border-stroke-brand" : "cx-border-stroke-primary"
      }`}
    >
      <div className="cx-bg-background-default cx-rounded-lg cx-p-0.5 cx-w-full">
        <div className="cx-w-full cx-rounded cx-overflow-hidden cx-py-1 cx-px-2 cx-bg-background-brand">
          <div className="cx-text-center cx-text-xs cx-uppercase cx-text-text-inverse">
            Recommended
          </div>
        </div>
        <div className="cx-flex cx-flex-col cx-items-center cx-p-4">
          <div className="cx-w-6 cx-h-6">
            <ConnectIcon className="cx-text-stroke-brand" />
          </div>
          <div className="cx-text-base">Connect bank</div>
        </div>
      </div>
    </div>
  );
};

const UploadStatementTab = ({ selected }: { selected?: boolean }) => {
  return (
    <div
      className={`cx-flex cx-flex-col cx-w-full cx-h-full cx-items-center cx-justify-center cx-rounded-lg cx-overflow-hidden cx-border ${
        selected ? "cx-border-stroke-brand" : "cx-border-stroke-primary"
      }`}
    >
      <div className="cx-w-6 cx-h-6">
        <UploadIcon className="cx-text-interaction-button-primary-default" />
      </div>
      <div className="cx-text-base">Upload statement</div>
    </div>
  );
};

const FinanceInformationSelect = () => {
  const {
    state: { financeInformationOption },
    actions: { update },
  } = useLoanApplication();
  const { nextStep } = useContext(StepperContext);

  const [selectedTab, setSelectedTab] = useState(financeInformationOption);

  const handleConnectBank = () => {
    setSelectedTab("connect");
  };

  const handleUploadStatement = () => {
    setSelectedTab("upload");
  };

  const handleNextStep = () => {
    update({ financeInformationOption: selectedTab }, { local: true })
      .then(() => {
        nextStep();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="cx-max-w-[960px] cx-w-full cx-flex cx-flex-col cx-items-center cx-gap-y-10">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
        How would you like to provide your financial information?
      </div>
      <div className="cx-max-w-[400px] cx-w-full">
        <BoxTabs
          tabs={[
            {
              label: "Connect bank",
              component: (
                <ConnectBankAccount selected={selectedTab === "connect"} />
              ),
              onTabClicked: handleConnectBank,
            },
            {
              label: "Upload statement",
              component: (
                <UploadStatementTab selected={selectedTab === "upload"} />
              ),
              onTabClicked: handleUploadStatement,
            },
          ]}
        />
        <div className="cx-mt-4">
          <Hint
            icon
            rounded
            text="Connect your bank to get lower application fees, fast decisioning, and higher credit limit."
          />
        </div>
      </div>
      <div className="cx-max-w-[400px] cx-w-full">
        <StepNextButton
          label="Next"
          disabled={!selectedTab}
          onClick={handleNextStep}
        />
      </div>
    </div>
  );
};

export default FinanceInformationSelect;
