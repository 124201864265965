import { Button, Modal } from "../../components";
import info from "../../constants/info.json";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TermsAndConditionsModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal title="Terms and conditions" isOpen={isOpen} onClose={onClose}>
      <div className="cx-flex cx-flex-col cx-items-center">
        <div className="cx-w-full cx-border-2 cx-border-lightgray-400 cx-rounded-lg cx-p-6 cx-overflow-y-auto cx-max-h-96">
          <div className="cx-text-sm">
            Please read these Loan Application Terms and Conditions ("Terms")
            carefully before submitting your loan application to CredibleX
            Limited. By applying for a loan with us, you agree to be bound by
            these Terms. If you do not agree with any part of these Terms,
            please do not proceed with the loan application.
          </div>
          <div className="cx-text-lg cx-mt-4">1. Eligibility</div>
          <div className="cx-text-sm cx-mt-4">
            1.1 To be eligible to apply for a loan, you must be an established
            entity in the United Arab Emirates (UAE). Shareholders with 25% or
            more ownership or a controlling shareholding in the company must be
            residents of the UAE and hold a valid residence visa and Emirates ID
            (EID).
          </div>
          <div className="cx-text-lg cx-mt-4">2. Consents</div>
          <div className="cx-text-sm cx-mt-4">
            2.1 You consent and authorise us to obtain credit report or reports
            from Al Etihad Credit Bureau of the Applicant as a company and its
            shareholders that own 25% or more of the company, or that have
            controlling shareholding.
          </div>
          <div className="cx-text-sm cx-mt-2">
            2.2 You consent and authorise us to share your data with third
            parties to conduct the loan application process, carry out
            compliance checks, conduct our credit assessment and to conduct our
            underwriting process including any further requirements we may have.
          </div>
          <div className="cx-text-sm cx-mt-2">
            2.3 It is a precondition for us to conduct compliance checks
            including EID and facial recognition on all owners with at least 25%
            shareholding or a controlling shareholding, in the entity applying
            for a loan. You consent and authorise us as part of this
            precondition to conduct compliance checks via EID and facial
            recognition.
          </div>
          <div className="cx-text-sm cx-mt-2">
            2.4 You consent and authorise us to conduct additional compliance
            checks if required by us on all owners with at least 25%
            shareholding or a controlling shareholding.
          </div>
          <div className="cx-text-lg cx-mt-4">3. Information required</div>
          <div className="cx-text-sm cx-mt-4">
            3.1 You must provide accurate and complete information and documents
            as required during the application process.
          </div>
          <div className="cx-text-sm cx-mt-2">
            3.2 You are responsible for ensuring that all information provided
            in the loan application is accurate, truthful, and up-to-date.
          </div>
          <div className="cx-text-sm cx-mt-2">
            3.3 You may be required to submit additional documents to support
            your loan application. Failure to provide the requested documents
            may result in the rejection of your application.
          </div>
          <div className="cx-text-lg cx-mt-4">4. Application Processing</div>
          <div className="cx-text-sm cx-mt-4">
            4.1 Following submission of your application we will conduct our
            assessment and revert back to you on the status of your application.
            In the event your application is not successful we are under no
            obligation to share any reason.
          </div>
          <div className="cx-text-sm cx-mt-2">
            4.2 The loan application processing time may vary based on factors
            such as loan type, loan amount, and completeness of documentation.
          </div>
          <div className="cx-text-lg cx-mt-4">
            5. Loan Approval and Disbursement
          </div>
          <div className="cx-text-sm cx-mt-4">
            5.1 If your application is successful, the approved loan amount and
            repayment terms will be communicated to you.
          </div>
          <div className="cx-text-sm cx-mt-2">
            5.2 Disbursement: Once your loan is approved and following agreement
            with the terms and conditions of the loan, the loan amount will be
            disbursed.
          </div>
          <div className="cx-text-lg cx-mt-4">6. Loan Fees and Charges</div>
          <div className="cx-text-sm cx-mt-4">
            6.1 We may charge a processing fee for your loan application. The
            exact amount will be communicated in the loan agreement. If you sign
            the loan agreement, you consent that any fees will be deducted from
            the approved loan at the time of disbursement.
          </div>
          <div className="cx-text-sm cx-mt-2">
            6.2 Interest Rates: The interest rates applicable to your loan will
            be specified in the loan agreement.
          </div>
          <div className="cx-text-lg cx-mt-4">
            7. Privacy and Data Protection
          </div>
          <div className="cx-text-sm cx-mt-4">
            7.1 Data Collection: By submitting a loan application, you consent
            to the collection, processing, and storage of your personal
            information in accordance with our Privacy Policy.
          </div>
          <div className="cx-text-lg cx-mt-4">8. Amendments to Terms</div>
          <div className="cx-text-sm cx-mt-4">
            8.1 CredibleX Limited reserves the right to amend or modify these
            Terms at any time. Any changes to these Terms will be communicated
            to you through our website or other appropriate means.
          </div>
          <div className="cx-text-lg cx-mt-4">9. Contact</div>
          <div className="cx-text-sm cx-mt-4">
            If you have any questions or concerns regarding these Terms or your
            loan application, please contact:
          </div>
          <div className="cx-text-md cx-mt-4">{info.name}</div>
          <div className="cx-text-md">{info.address.street}</div>
          <div className="cx-text-md">{info.address.city}</div>
          <div className="cx-text-md cx-text-blue-600 cx-underline">
            {info.email}
          </div>
          <div className="cx-text-sm cx-mt-4">
            By submitting a loan application to CredibleX Limited, you
            acknowledge that you have read, understood, and agreed to these Loan
            Application Terms and Conditions.
          </div>
        </div>
        <div className="cx-mt-6 cx-w-full">
          <Button label="Close" onClick={onClose} fullWidth />
        </div>
      </div>
    </Modal>
  );
};

export default TermsAndConditionsModal;
