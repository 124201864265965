import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Combobox,
  InputField,
  NumericInputField,
  StepNextButton,
  Divider,
} from "../../../components";
import {
  LicensingAuthorityType,
  StepperContext,
  useLoanApplication,
} from "../../../contexts";
import {
  ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER,
  InformationFormFields,
  INFORMATION_FORM_INITIAL_VALUES,
  TAX_PERCENTAGE_MULTIPLIER,
  LicensingAuthorityFormField,
  getEffectiveInformationFormValidationSchema,
} from "./service";
import { LoanApplicationStage } from "../../../hooks/api/types";
import { useGetLicensingAuthorities } from "../../../hooks";
import { useDpConfig } from "../../../contexts/dpConfig";

const BusinessInformationForm = () => {
  const {
    state,
    actions: { update },
  } = useLoanApplication();
  const { nextStep } = useContext(StepperContext);
  const { mutateAsync: getLicensingAuthorities } = useGetLicensingAuthorities();
  const {
    state: {
      settings: { disableRevenueHistory },
    },
  } = useDpConfig();

  const [licensingAuthorities, setLicensingAuthorities] = useState<
    Array<LicensingAuthorityFormField>
  >([]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = useFormik<InformationFormFields>({
    initialValues: {
      ...INFORMATION_FORM_INITIAL_VALUES,
      tradeLicenseNumber: state.tradeLicenseNumber,
      annualRevenue: state.annualRevenue,
      taxRegistrationNumber: state.taxRegistrationNumber,
      lastYearPaidTax: state.lastYearPaidTax,
      licensingAuthorityId: state.licensingAuthorityId,
      ...(disableRevenueHistory
        ? { annualRevenue: 0, taxRegistrationNumber: "", lastYearPaidTax: 0 }
        : {}),
    },
    validationSchema: getEffectiveInformationFormValidationSchema(
      disableRevenueHistory
    ),
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await update({
          tradeLicenseNumber: values.tradeLicenseNumber,
          annualRevenue: values.annualRevenue,
          taxRegistrationNumber: values.taxRegistrationNumber,
          lastYearPaidTax: values.lastYearPaidTax,
          licensingAuthorityId: values.licensingAuthorityId,
          stage: LoanApplicationStage.COMPANY_INFORMATION,
        });
        nextStep();
      } catch (e) {
        console.log(e);
      }
    },
  });

  const shouldApplyTax = useMemo(() => {
    return values.annualRevenue >= ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER;
  }, [values.annualRevenue]);

  useEffect(() => {
    setFieldValue(
      "lastYearPaidTax",
      shouldApplyTax ? values.annualRevenue * TAX_PERCENTAGE_MULTIPLIER : 0
    );
  }, [shouldApplyTax, values.annualRevenue]);

  useEffect(() => {
    const initialize = async () => {
      const res = await getLicensingAuthorities();
      const _authorities = res.map((e: LicensingAuthorityType) => ({
        value: e.id,
        label: e.name,
        ...e,
      }));
      setLicensingAuthorities(_authorities);
    };
    initialize().catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-w-full">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-10">
        Tell us about your business
      </div>
      <div className="cx-max-w-[400px] cx-w-full">
        <form onSubmit={handleSubmit}>
          <div className="cx-mb-4">
            <InputField
              label="Trade license number"
              tick={touched.tradeLicenseNumber && !errors.tradeLicenseNumber}
              error={
                touched.tradeLicenseNumber && !!errors.tradeLicenseNumber
                  ? errors.tradeLicenseNumber
                  : ""
              }
              inputProps={{
                name: "tradeLicenseNumber",
                value: values.tradeLicenseNumber,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              hint="Please add your trade license number exactly as it appears on your license, including any special characters."
            />
          </div>
          <div className="">
            <Combobox
              label="Licensing Authority"
              placeholder="Select licensing authority"
              options={licensingAuthorities}
              inputProps={{
                name: "licensingAuthorityId",
                value: licensingAuthorities.find(
                  (e) => e.value === values.licensingAuthorityId
                ),
                onBlur: () => setFieldTouched("licensingAuthorityId"),
              }}
              onChange={handleChange("licensingAuthorityId")}
              error={
                touched.licensingAuthorityId && !!errors.licensingAuthorityId
                  ? errors.licensingAuthorityId
                  : ""
              }
            />
          </div>
          <div className="cx-my-4">
            {!disableRevenueHistory && (
              <NumericInputField
                label="Previous year annual revenue"
                isValid={touched.annualRevenue && !errors.annualRevenue}
                value={values.annualRevenue}
                error={
                  touched.annualRevenue && !!errors.annualRevenue
                    ? errors.annualRevenue
                    : ""
                }
                setValue={(value) => {
                  setFieldValue("annualRevenue", value);
                }}
              />
            )}
          </div>
          {shouldApplyTax && (
            <>
              <div className="cx-my-4">
                <InputField
                  label="Tax registration number"
                  tick={
                    touched.taxRegistrationNumber &&
                    !errors.taxRegistrationNumber
                  }
                  error={
                    touched.taxRegistrationNumber &&
                    !!errors.taxRegistrationNumber
                      ? errors.taxRegistrationNumber
                      : ""
                  }
                  inputProps={{
                    name: "taxRegistrationNumber",
                    value: values.taxRegistrationNumber,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                />
              </div>
              <div className="cx-mb-4">
                <NumericInputField
                  label="VAT Paid in the Previous Year"
                  isValid={touched.lastYearPaidTax && !errors.lastYearPaidTax}
                  value={values.lastYearPaidTax}
                  error={
                    touched.lastYearPaidTax && !!errors.lastYearPaidTax
                      ? errors.lastYearPaidTax
                      : ""
                  }
                  setValue={(value) => {
                    setFieldValue("lastYearPaidTax", value);
                  }}
                  onBlur={() => setFieldTouched("lastYearPaidTax")}
                />
              </div>
            </>
          )}
          <div className="cx-my-10">
            <Divider />
          </div>
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <StepNextButton loading={state.updating} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessInformationForm;
