import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  Combobox,
  Dropdown,
  InputField,
  StepPrevButton,
  StepNextButton,
  Divider,
} from "../../../components";
import {
  IndustryType,
  StepperContext,
  useLoanApplication,
} from "../../../contexts";
import {
  DETAIL_FORM_EMIRATE_DROPDOWN_OPTIONS,
  DETAIL_FORM_YEAR_DROPDOWN_OPTIONS,
  DETAIL_FORM_INITIAL_VALUES,
  DETAIL_FORM_VALIDATION_SCHEMA,
  DetailFormFields,
} from "./service";
import { LoanApplicationStage } from "../../../hooks/api/types";
import { useGetBusinessTypes, useGetIndustryTypes } from "../../../hooks";
import { ArrowLeftIcon } from "../../../assets/icons";

const BusinessDetailForm = () => {
  const {
    state,
    actions: { update, refetch },
  } = useLoanApplication();
  const { previousStep } = useContext(StepperContext);
  const { mutateAsync: getIndustryTypes } = useGetIndustryTypes();
  const { mutateAsync: getBusinessTypes } = useGetBusinessTypes();
  const [industryTypes, setIndustryTypes] = useState<
    { value: string; label: string }[]
  >([]);
  const [businessTypes, setBusinessTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
  } = useFormik<DetailFormFields>({
    initialValues: {
      ...DETAIL_FORM_INITIAL_VALUES,
      companyName: state.businessName,
      companyYearEstabilished: state.businessYearEstabilished,
      businessType: state.businessType,
      industryTypeId: state.industryTypeId,
      emirate: state.businessEmirate,
      area: state.businessArea,
      street: state.businessStreet,
      building: state.businessBuilding,
      floor: state.businessFloor,
      number: state.businessUnit,
    },
    validationSchema: DETAIL_FORM_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      await update({
        businessName: values.companyName,
        businessYearEstabilished: values.companyYearEstabilished,
        businessType: values.businessType,
        businessEmirate: values.emirate,
        businessArea: values.area,
        businessStreet: values.street,
        businessBuilding: values.building,
        businessFloor: values.floor,
        businessUnit: values.number,
        industryTypeId: values.industryTypeId,
        stage: LoanApplicationStage.DOCUMENTS_UPLOAD,
      });
      await refetch();
    },
  });

  useEffect(() => {
    const initIndustryTypes = async () => {
      const _types = await getIndustryTypes();
      const _industryTypes = _types.map((e: IndustryType) => ({
        value: e.id,
        label: e.name,
        ...e,
      }));
      setIndustryTypes(_industryTypes);
    };
    const initBusinessTypes = async () => {
      const _types = await getBusinessTypes();
      const businessTypes = _types.map((e: IndustryType) => ({
        value: e.id,
        label: e.name,
        ...e,
      }));
      setBusinessTypes(businessTypes);
    };
    initIndustryTypes().catch((err) => console.log(err));
    initBusinessTypes().catch((err) => console.log(err));
  }, []);

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-10">
        Complete your business details
      </div>
      <div className="cx-max-w-[400px]">
        <div className="cx-font-semibold cx-text-text-primary cx-text-base cx-mb-4">
          Business details
        </div>
        <form onSubmit={handleSubmit}>
          <div className="cx-mb-4">
            <InputField
              label="Company name"
              tick={touched.companyName && !errors.companyName}
              inputProps={{
                name: "companyName",
                value: values.companyName,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              error={touched.companyName ? errors.companyName : ""}
            />
          </div>
          <div className="cx-mb-4">
            <Combobox
              label="Year company estabilished"
              options={DETAIL_FORM_YEAR_DROPDOWN_OPTIONS}
              inputProps={{
                name: "companyYearEstabilished",
                value: DETAIL_FORM_YEAR_DROPDOWN_OPTIONS.find(
                  (e) => e.value === values.companyYearEstabilished
                ),
                onBlur: () => setFieldTouched("companyYearEstabilished"),
              }}
              onChange={handleChange("companyYearEstabilished")}
              error={
                touched.companyYearEstabilished
                  ? errors.companyYearEstabilished
                  : ""
              }
            />
          </div>
          <div className="cx-mb-4">
            <Combobox
              label="Business type"
              options={businessTypes}
              inputProps={{
                name: "businessType",
                value: businessTypes.find(
                  (e) => e.value === values.businessType
                ),
                onBlur: () => setFieldTouched("businessType"),
              }}
              onChange={handleChange("businessType")}
              error={touched.businessType ? errors.businessType : ""}
            />
          </div>
          <div className="cx-mb-8">
            <Combobox
              label="Industry type"
              options={industryTypes}
              inputProps={{
                name: "industryTypeId",
                value: industryTypes.find(
                  (e) => e.value === values.industryTypeId
                ),
                onBlur: () => setFieldTouched("industryTypeId"),
              }}
              onChange={handleChange("industryTypeId")}
              error={
                touched.industryTypeId && !!errors.industryTypeId
                  ? errors.industryTypeId
                  : ""
              }
            />
          </div>
          <div className="cx-font-semibold cx-text-text-primary cx-text-base cx-mb-4">
            Office address
          </div>
          <div className="cx-flex cx-justify-between cx-mb-4">
            <div className="cx-mr-1 cx-w-full">
              <Dropdown
                label="Emirate"
                options={DETAIL_FORM_EMIRATE_DROPDOWN_OPTIONS}
                inputProps={{
                  name: "emirate",
                  value: values.emirate,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={
                  touched.emirate && !!errors.emirate ? errors.emirate : ""
                }
              />
            </div>
            <div className="cx-ml-1 cx-w-full">
              <InputField
                label="Area"
                tick={touched.area && !errors.area}
                inputProps={{
                  name: "area",
                  value: values.area,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={touched.area && !!errors.area ? errors.area : ""}
              />
            </div>
          </div>
          <div className="cx-flex cx-justify-between cx-mb-4">
            <div className="cx-mr-1">
              <InputField
                label="Street name"
                tick={touched.street && !errors.street}
                inputProps={{
                  name: "street",
                  value: values.street,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={touched.street ? errors.street : ""}
              />
            </div>
            <div className="cx-ml-1">
              <InputField
                label="Building"
                tick={touched.building && !errors.building}
                inputProps={{
                  name: "building",
                  value: values.building,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={touched.building ? errors.building : ""}
              />
            </div>
          </div>
          <div className="cx-flex cx-justify-between cx-mb-10">
            <div className="cx-mr-1">
              <InputField
                label="Floor"
                tick={touched.floor && !errors.floor}
                inputProps={{
                  name: "floor",
                  value: values.floor,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={touched.floor ? errors.floor : ""}
              />
            </div>
            <div className="cx-ml-1">
              <InputField
                label="Office number"
                tick={touched.number && !errors.number}
                inputProps={{
                  name: "number",
                  value: values.number,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={touched.number ? errors.number : ""}
              />
            </div>
          </div>
          <div className="cx-my-10">
            <Divider />
          </div>
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <div className="cx-w-1/4">
              <StepPrevButton
                label={<ArrowLeftIcon />}
                type="button"
                onClick={previousStep}
              />
            </div>
            <div className="cx-w-3/4">
              <StepNextButton loading={state.updating} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessDetailForm;
