import { useContext } from "react";
import { StepperContext, useLoanApplication } from "../../../contexts";
import { LoanApplicationStage } from "../../../hooks/api/types";
import LinkBankLite from "./LinkBankLite";

//To be used in place of legacy LinkBankAccount.tsx
const LinkBankAccountSection = () => {
  const { nextStep, previousStep } = useContext(StepperContext);
  const {
    state: { annualRevenue },
    actions: { update },
  } = useLoanApplication();
  const VAT_LIMIT = Number(import.meta.env.VITE_SKIP_VAT_LIMIT ?? 375000);

  const handleSubmit = async () => {
    update({
      stage:
        annualRevenue < VAT_LIMIT
          ? LoanApplicationStage.REVIEW
          : LoanApplicationStage.VAT_STATEMENTS,
    })
      .then(() => {
        nextStep();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return <LinkBankLite onNext={handleSubmit} onPrevious={previousStep} />;
};

export default LinkBankAccountSection;
