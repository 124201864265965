import { ReactNode } from "react";
import { DropzoneOptions, Accept, useDropzone } from "react-dropzone";
import { Line } from "rc-progress";
import { DocumentIcon, SuccessIcon, UploadIcon } from "../../assets/icons";

type Variant = "simple" | "uploading" | "uploaded";

type Props = {
  accept?: Accept;
  variant: Variant;
  extensions?: string[];
  options?: DropzoneOptions;
  onDrop?: (files: File[]) => void;
  error?: string;
};

const ICON_MAP: { [key in Variant]: ReactNode } = {
  simple: (
    <UploadIcon
      width="100%"
      height="100%"
      className="cx-text-interaction-button-primary-default"
    />
  ),
  uploading: <DocumentIcon width="100%" height="100%" color="#666666" />,
  uploaded: <SuccessIcon width="100%" height="100%" />,
};

const TITLE_MAP: { [key in Variant]: string } = {
  simple: "Upload or drop files here",
  uploading: "Uploading file...",
  uploaded: "Upload complete",
};

const Dropzone: React.FC<Props> = ({
  accept,
  extensions,
  variant = "simple",
  onDrop,
  options = {},
  error,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    ...options,
  });

  const renderVariation = () => {
    switch (variant) {
      case "simple":
        return (
          <>
            <div className="cx-w-6 cx-h-6">{ICON_MAP[variant]}</div>
            <div className="cx-text-interaction-button-primary-default cx-text-sm cx-mt-2">
              {TITLE_MAP[variant]}
            </div>
            <div className="cx-text-xs cx-text-text-tertiary">
              Allowed extensions:{" "}
              {extensions?.length ? extensions.join(", ") : "any"}
            </div>
          </>
        );
      case "uploading":
        return (
          <>
            <div className="cx-w-6 cx-h-6 cx-text-interaction-button-text-disabled">
              {ICON_MAP[variant]}
            </div>
            <div className="cx-text-interaction-button-text-disabled cx-text-sm cx-mt-2">
              {TITLE_MAP[variant]}
            </div>
            <div className="cx-text-xs cx-text-text-tertiary cx-mb-2">
              Please wait
            </div>
            <div className="cx-w-full">
              <Line
                percent={25}
                strokeWidth={1}
                strokeColor="#4589FF"
                className="cx-h-1 cx-w-full"
              />
            </div>
          </>
        );
      case "uploaded":
        return (
          <>
            <div className="cx-w-6 cx-h-6">{ICON_MAP[variant]}</div>
            <div className="cx-text-black cx-text-sm cx-mt-2">
              {TITLE_MAP[variant]}
            </div>
            <div className="cx-text-xs cx-text-primary">Remove file</div>
          </>
        );
    }
  };

  return (
    <>
      <div
        className={`cx-h-32 cx-py-7 cx-cursor-pointer cx-px-4 cx-flex cx-flex-col cx-items-center cx-justify-center cx-rounded cx-bg-background-default cx-border-2 ${
          error ? "cx-border-stroke-error" : "cx-border-stroke-primary"
        }`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {renderVariation()}
      </div>
      {error && (
        <div className="cx-mt-2 cx-text-text-error cx-text-xs">{error}</div>
      )}
    </>
  );
};

export default Dropzone;
