import { FC } from "react";
import { Button, Modal } from "../../../components";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const DeleteConfirmationModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Confirmation">
      <div className="cx-text-text-secondary cx-text-base cx-text-center">
        Are you sure you want to delete this shareholder? this operation is
        irreversible.
      </div>
      <div className="cx-flex cx-justify-center cx-mt-10 cx-gap-4">
        <div className="cx-w-1/3">
          <Button label="X" outlined fullWidth onClick={onClose} />
        </div>
        <div className="cx-w-2/3">
          <Button label="Yes" danger fullWidth onClick={onSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
