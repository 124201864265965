import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { CardList, StepNextButton, StepPrevButton } from "../../../components";
import { StepperContext, useLoanApplication } from "../../../contexts";
import { useMedia } from "../../../hooks";
import { MediaPurpose, MediaType } from "../../../hooks/api/types";
import {
  DocumentsUploadFormFields,
  DOCUMENTS_UPLOAD_FORM_INITIAL_VALUES,
  DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA,
} from "./service";
import { generateFile } from "../../../utils";
import { CardVariantType } from "../../../components/CardList/CardList";
import {
  ArrowLeftIcon,
  DocumentIcon,
  SuccessIcon,
} from "../../../assets/icons";
import { useBusinessType } from "../../../hooks";

interface DocumentsUploadProps {
  loanApplicationId?: string;
  onSubmit?: (vatStatementIds: string[]) => void;
}
const DocumentsUpload: React.FC<DocumentsUploadProps> = ({ onSubmit }) => {
  const [tradeLicenseUploading, setTradeLicenseUploading] = useState(false);
  const [moaUploading, setMoaUploading] = useState(false);
  const [poaUploading, setPoaUploading] = useState(false);
  const [tradeLicenseId, setTradeLicenseId] = useState("");
  const [moaId, setMoaId] = useState("");
  const [poaId, setPoaId] = useState("");

  const {
    state,
    actions: { update },
  } = useLoanApplication();
  const { previousStep, nextStep } = useContext(StepperContext);

  const { skipMoaAndPoa } = useBusinessType();

  const { errors, touched, setFieldValue, values, handleSubmit } =
    useFormik<DocumentsUploadFormFields>({
      initialValues: {
        ...DOCUMENTS_UPLOAD_FORM_INITIAL_VALUES,
        isSole: skipMoaAndPoa,
        tradeLicense: generateFile(state.docTradeLicense),
        memorandumOfAssociation: generateFile(state.docMemorandumOfAssociation),
        powerOfAttorney: generateFile(state.docPowerOfAttorney),
      },
      validationSchema: DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA,
      onSubmit: async () => {
        if (!tradeLicenseId) return;
        if (!moaId && !skipMoaAndPoa) return;
        if (!poaId && !skipMoaAndPoa) return;

        if (onSubmit) onSubmit([moaId]);
        try {
          await update({
            docTradeLicense: tradeLicenseId,
            docMemorandumOfAssociation: moaId,
            docPowerOfAttorney: poaId,
          });
          nextStep();
        } catch (err) {
          console.log(err);
        }
      },
    });

  useEffect(() => {
    setTradeLicenseId(state.docTradeLicense ?? "");
  }, [state.docTradeLicense]);

  useEffect(() => {
    setMoaId(state.docMemorandumOfAssociation ?? "");
  }, [state.docMemorandumOfAssociation]);

  useEffect(() => {
    setPoaId(state.docPowerOfAttorney ?? "");
  }, [state.docPowerOfAttorney]);

  const { upload } = useMedia();

  const handleFileUpload = async (
    file: File | undefined,
    fieldName: string,
    mediaPurpose: MediaPurpose,
    uploadingSetter: React.Dispatch<React.SetStateAction<boolean>>,
    idSetter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (!file) return;

    setFieldValue(fieldName, file);
    uploadingSetter(true);

    try {
      const id = await upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: mediaPurpose,
        loanApplicationId: state.id,
      });
      idSetter(id);
    } catch (err) {
      console.log(err);
    } finally {
      uploadingSetter(false);
    }
  };

  const disabled =
    !tradeLicenseId ||
    (!moaId && state.businessType !== "SOLE_PROPRIETORSHIP") ||
    (!poaId && state.businessType !== "SOLE_PROPRIETORSHIP");

  let tradeVariant = CardVariantType.UPLOAD;
  let moaVariant = CardVariantType.UPLOAD;
  let poaVariant = CardVariantType.UPLOAD;
  if (tradeLicenseUploading) {
    tradeVariant = CardVariantType.UPLOADING;
  } else if (values.tradeLicense) {
    tradeVariant = CardVariantType.UPLOADED;
  }
  if (moaUploading) {
    moaVariant = CardVariantType.UPLOADING;
  } else if (values.memorandumOfAssociation) {
    moaVariant = CardVariantType.UPLOADED;
  }
  if (poaUploading) {
    poaVariant = CardVariantType.UPLOADING;
  } else if (values.powerOfAttorney) {
    poaVariant = CardVariantType.UPLOADED;
  }

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
        Upload the following documents
      </div>
      <div className="cx-mt-2 cx-text-text-secondary cx-text-base cx-text-center cx-mb-10">
        Please upload a PDF. Maximum file size is 5 MB
      </div>
      <div className="cx-max-w-[400px]">
        <form onSubmit={handleSubmit}>
          <div className="cx-mb-4 cx-text-brand-primary-regular">
            <CardList
              variant={tradeVariant}
              title="Trade License"
              description={
                values.tradeLicense
                  ? values.tradeLicense.name
                  : `Trade license number ${state.tradeLicenseNumber}`
              }
              inputProps={{ name: "tradeLicense" }}
              icon={
                values.tradeLicense && !tradeLicenseUploading ? (
                  <SuccessIcon
                    width="100%"
                    height="100%"
                    className="cx-text-background-success"
                  />
                ) : (
                  <DocumentIcon width="100%" height="100%" />
                )
              }
              onChange={(file) =>
                handleFileUpload(
                  file,
                  "tradeLicense",
                  MediaPurpose.TRADE_LICENSE,
                  setTradeLicenseUploading,
                  setTradeLicenseId
                )
              }
              error={touched.tradeLicense && !!errors.tradeLicense}
              truncateDescriptionLength={35}
            />
          </div>
          <div className="cx-mb-4 cx-text-brand-primary-regular">
            <CardList
              variant={moaVariant}
              title="Memorandum of Association"
              description={
                values.memorandumOfAssociation
                  ? values.memorandumOfAssociation.name
                  : "A legal document that sets out the fundamental details and objectives of a company during its formation"
              }
              inputProps={{ name: "memorandumOfAssociation" }}
              icon={
                values.memorandumOfAssociation && !moaUploading ? (
                  <SuccessIcon
                    width="100%"
                    height="100%"
                    className="cx-text-background-success"
                  />
                ) : (
                  <DocumentIcon width="100%" height="100%" />
                )
              }
              onChange={(file) =>
                handleFileUpload(
                  file,
                  "memorandumOfAssociation",
                  MediaPurpose.MEMORANDUM_OF_ASSOCIATION,
                  setMoaUploading,
                  setMoaId
                )
              }
              error={
                touched.memorandumOfAssociation &&
                !!errors.memorandumOfAssociation
              }
              truncateDescriptionLength={35}
            />
          </div>
          <div className="cx-mb-4 cx-text-brand-primary-regular">
            <CardList
              variant={poaVariant}
              title="Power of Attorney"
              description={
                values.powerOfAttorney
                  ? values.powerOfAttorney.name
                  : "A legal document specifying the name of the person who is authorized to take out a loan on behalf of the company."
              }
              inputProps={{ name: "powerOfAttorney" }}
              icon={
                values.powerOfAttorney && !poaUploading ? (
                  <SuccessIcon
                    width="100%"
                    height="100%"
                    className="text-background-success"
                  />
                ) : (
                  <DocumentIcon width="100%" height="100%" />
                )
              }
              onChange={(file) =>
                handleFileUpload(
                  file,
                  "powerOfAttorney",
                  MediaPurpose.POWER_OF_ATTORNEY,
                  setPoaUploading,
                  setPoaId
                )
              }
              error={touched.powerOfAttorney && !!errors.powerOfAttorney}
              truncateDescriptionLength={35}
            />
          </div>
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4 cx-mt-10">
            <div className="cx-w-1/4">
              <StepPrevButton
                label={<ArrowLeftIcon />}
                type="button"
                onClick={previousStep}
              />
            </div>
            <div className="cx-w-3/4">
              <StepNextButton loading={state.updating} disabled={disabled} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DocumentsUpload;
