import { Button, Modal } from "../../../components";

type Props = {
  isOpen: boolean;
  date: string;
  onClose: () => void;
  onContinue: () => void;
  onNewApplication: () => void;
};

const ExistingLoanApplicationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onContinue,
  onNewApplication,
  date = "<date>",
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="You have an existing loan application"
      subtitle={`You have started a loan application on ${date}. Would you like to continue where you left off or Starting a new application?`}
    >
      <div className="cx-w-full">
        <Button label="Continue" fullWidth onClick={onContinue} />
      </div>
      <div className="cx-w-full cx-mt-4">
        <Button
          label="Start a new loan application"
          outlined
          fullWidth
          onClick={onNewApplication}
        />
      </div>
    </Modal>
  );
};

export default ExistingLoanApplicationModal;
