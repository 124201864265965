import { FC, useEffect, useState } from "react";
import { Button, Card, Divider, InputField } from "../../components";
import { LoanDashboardFormCard } from "./LoanDashboardForm";
import { useLoanDashboard } from "../../contexts";
import { DownloadIcon, GreenCircleIcon } from "../../assets/icons";
import { InputFieldVariant } from "../../components/InputField/InputField";
import { usePaymentDetails, usePaymentDetailsExport } from "../../hooks";
import {
  LoanContractScheduleTransactionListDTO,
  PaymentTransactionsSortColumns,
  SortOrder,
} from "../../hooks/api/types";
import { formatISOString } from "../../utils";
import { debounce } from "lodash";

const MAX_LABEL_LENGTH = 10; // Maximum length of the label

const LoanPaymentDetailsDashboard: FC = () => {
  const {
    state: { currentLoanApplicationId, currentPaymentId },
    actions: { update },
  } = useLoanDashboard();
  const [searchInput, setSearchInput] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };
  const [searchKey, setSearchKey] = useState<string>("");
  const { data: paymentDetails, isLoading } = usePaymentDetails({
    loanApplicationId: currentLoanApplicationId,
    paymentId: currentPaymentId,
    search: searchKey,
    sortColumn: PaymentTransactionsSortColumns.DATE,
    sortOrder: SortOrder.DESC,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const { refetch: downloadCsv, isFetching } = usePaymentDetailsExport(
    currentLoanApplicationId,
    currentPaymentId,
    searchKey
  );
  const [transactionsData, setTransactionsData] =
    useState<LoanContractScheduleTransactionListDTO>();

  const filterBySearchInput = debounce((searchKey: string) => {
    setSearchKey(searchKey);
  }, 1000);

  useEffect(() => {
    filterBySearchInput(searchInput);
  }, [searchInput]);

  useEffect(() => {
    if (paymentDetails && !isLoading) {
      setTransactionsData(paymentDetails);
    }
  }, [paymentDetails, isLoading]);

  const handleExport = async () => {
    await downloadCsv();
  };

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-background-radial-gradient cx-w-full cx-p-8">
      <div className="cx-flex cx-flex-row cx-items-center cx-justify-between cx-w-full">
        <div className="cx-flex cx-flex-row cx-content-center cx cx-items-center cx-w-full cx-max-w-[960px] cx-py-3 cx-relative">
          <Button
            label="Back"
            arrow="left"
            invertArrowDirection={true}
            arrowClassName="cx-stroke-2 cx-h-4 cx-w-4"
            slim={true}
            onClick={() =>
              update({ showPaymentHistory: true, showPaymentDetails: false })
            }
            className="cx-text-s cx-font-semibold cx-py-2 cx-px-3 cx-h-8 cx-mr-2"
          />
          <div className="cx-text-3xl">{} Payments</div>
        </div>
        {transactionsData?.lastUpdatedAt && (
          <div className="cx-flex cx-flex-row cx-items-center cx-w-full cx-justify-end">
            <GreenCircleIcon className="cx-mx-1" />
            <div className="cx-text-neutral-dark cx-text-[16px]">
              Last updated on {transactionsData?.lastUpdatedAt}
            </div>
          </div>
        )}
      </div>
      <div className="cx-w-full cx-max-w-[960px] cx-py-3 cx-flex cx-flex-row cx-justify-between">
        <InputField
          label="Search by platform or transaction id"
          search={true}
          className="cx-max-w-xs cx-border-1.5"
          variant={InputFieldVariant.slim}
          inputProps={{
            name: "searchInput",
            value: searchInput,
            onChange: handleChange,
          }}
        ></InputField>
        <Button
          label={
            <div
              className={
                "cx-flex cx-flex-row cx-justify-center cx-items-center"
              }
            >
              {!isFetching && <DownloadIcon className="cx-mx-1" />}
              <div>Export</div>
            </div>
          }
          {...(isFetching ? { loader: "left" } : {})}
          disabled={isFetching}
          slim={true}
          onClick={handleExport}
        />
      </div>
      <Card>
        <div className="sm:cx-flex cx-flex-wrap cx-items-center sm:cx-divide-x sm:cx-divide-neutral-light">
          <div className={"cx-w-full cx-px-10 cx-py-4"}>
            <LoanDashboardFormCard>
              <div className={"cx-w-full cx-flex cx-flex-col cx-gap-6"}>
                <div className="cx-grow cx-flex cx-flex-row cx-items-center cx-justify-center">
                  <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-[16px]">
                    Date
                  </div>
                  <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-[16px]">
                    Platform
                  </div>
                  <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-[16px]">
                    Debit
                  </div>
                  <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-[16px] cx-text-right">
                    Transaction ID
                  </div>
                </div>
                <Divider />
                <div className="cx-w-full cx-flex cx-flex-col cx-gap-6">
                  {transactionsData?.transactions?.map((transaction, index) => {
                    return (
                      <div
                        key={index}
                        className="cx-w-full cx-grow sm:cx-flex cx-flex-wrap cx-items-center sm:cx-divide-x sm:cx-divide-neutral-light"
                      >
                        <div className="cx-grow cx-flex cx-flex-row cx-items-center cx-justify-center cx-pb-6">
                          <div className="cx-w-1/4 cx-text-text-secondary cx-font-normal cx-text-[16px]">
                            {formatISOString(transaction.date)}
                          </div>
                          <div className="cx-w-1/4 cx-text-text-primary cx-font-normal cx-text-[16px]">
                            {transaction.remitter.length < MAX_LABEL_LENGTH
                              ? transaction.remitter
                              : transaction.remitter.substring(
                                  0,
                                  MAX_LABEL_LENGTH
                                ) + "..."}
                          </div>
                          <div className="cx-w-1/4 cx-text-text-primary cx-font-normal cx-text-[16px]">
                            {transaction.amount.toFixed(2)}
                          </div>
                          <div className="cx-w-1/4 cx-text-text-secondary cx-font-normal cx-text-[16px] cx-text-right">
                            {transaction.transactionId.length < MAX_LABEL_LENGTH
                              ? transaction.transactionId
                              : transaction.transactionId.substring(
                                  0,
                                  MAX_LABEL_LENGTH
                                ) + "..."}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </LoanDashboardFormCard>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LoanPaymentDetailsDashboard;
