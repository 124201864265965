import { useId } from "react";
import { DropdownIcon } from "../../assets/icons";

type Props = {
  options: string[];
  label: string;
  error?: string;
  inputProps?: React.InputHTMLAttributes<HTMLSelectElement>;
};

const Dropdown = ({ error, options, label, inputProps = {} }: Props) => {
  const id = useId();
  return (
    <>
      <div
        className={`cx-relative cx-w-full cx-rounded-base cx-border-2 cx-bg-background-default
        ${
          error
            ? "cx-border-stroke-error"
            : "cx-border-stroke-primary cx-focus-within:border-stroke-brand"
        }`}
      >
        <label
          htmlFor={id}
          className={`cx-absolute cx-text-xs cx-text-text-tertiary cx-px-4 cx-pt-2 cx-top-0`}
        >
          {label}
        </label>
        <select
          id={id}
          // NOTE: There's a hacky right padding fix here to account for very large option text
          className={`cx-relative cx-bg-transparent cx-appearance-none cx-outline-none cx-pb-2 cx-pt-6 cx-pl-4 cx-pr-10 cx-w-full cx-rounded-base cx-z-10`}
          {...inputProps}
        >
          <option value="">Select your option</option>
          {options.map((opt) => {
            return (
              <option key={opt} value={opt} className="cx-p-0">
                {opt}
              </option>
            );
          })}
        </select>
        <div className="cx-absolute cx-right-0 cx-top-0 cx-bottom-0 cx-py-4 cx-pr-4">
          <DropdownIcon height="100%" />
        </div>
      </div>
      {!!error && (
        <div className="cx-text-xs cx-py-1 cx-text-text-error">{error}</div>
      )}
    </>
  );
};

export default Dropdown;
