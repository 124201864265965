import { Modal } from "../../../components";
import SumSubKyc from "./SumSubKyc";
import React, { FC } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const SumSubKycModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal
      title=""
      isOpen={isOpen}
      onClose={onClose}
      className="cx-py-4 cx-w-full"
    >
      <SumSubKyc onSubmit={onSubmit} />
    </Modal>
  );
};

export default SumSubKycModal;
