import { useContext, useEffect, useState } from "react";
import { Button, CardSelect, Divider, GradientCard } from "../../../components";
import {
  StepperContext,
  useLoanApplication,
  useLoanCalculator,
} from "../../../contexts";
import { Funds, Onboarding, PreApproval } from "../../../assets/images";
import TermsAndConditionsModal from "../../LoanCalculator/TermsAndConditionsModal";
import PrivacyPolicyModal from "../../LoanCalculator/PrivacyPolicyModal";
import { LoanReasonMap } from "./service";
import { LoanProductType } from "../../../hooks/api/types";
import { LoanProductConfig } from "../../../contexts/dpConfig/types";
import { useDpConfig } from "../../../contexts/dpConfig";
import { StepName } from "../index";

const productDescription: { [key in LoanProductType]: string } = {
  [LoanProductType.FIXED_TERM]: "Borrow a fixed amount",
  [LoanProductType.INVOICE_DISCOUNTING]:
    "Get cashflow from invoices owed to you",
  [LoanProductType.PAYABLE_FINANCING]: "Pay an expense or supplier",
  [LoanProductType.ACCOUNT_RECEIVABLES]: "",
  [LoanProductType.REVENUE_BASED_FINANCING]: "",
  [LoanProductType.BNPL]: "Meet your needs now, pay us later",
};

const getLoanDescription = (
  type: LoanProductType,
  config: LoanProductConfig
) => (
  <>
    <div className="cx-text-center cx-text-text-secondary">
      {productDescription[type]}
    </div>

    {type === LoanProductType.BNPL ? (
      <div className="cx-text-center cx-text-text-secondary">
        Customisable / Flexible tenures
      </div>
    ) : (
      <div className="cx-text-center cx-text-text-secondary">
        Repayment period from {config.duration.min}{" "}
        {config.duration.unit.toLowerCase()} to {config.duration.max}{" "}
        {config.duration.unit.toLowerCase()}
      </div>
    )}
    <div className="cx-text-center cx-text-text-secondary">
      {type === LoanProductType.FIXED_TERM ||
      type === LoanProductType.REVENUE_BASED_FINANCING ||
      LoanProductType.BNPL
        ? "Fixed monthly repayments"
        : "One-time repayment"}
    </div>
    {type !== LoanProductType.BNPL && (
      <div className="cx-text-center cx-text-text-secondary">
        Early settlement option
      </div>
    )}
  </>
);

const FEATURES = [
  { name: "Application time of 10 minutes", image: Onboarding },
  { name: "Pre-approval in 24 hours", image: PreApproval },
  { name: "Funds disbursed in 48 hours", image: Funds },
];

const DEFAULT_REASON = {
  label: "I received some unexpected bills",
  type: LoanProductType.INVOICE_DISCOUNTING,
};
const LoanTypeSelection = () => {
  const { nextStep, gotoStep, currentStep, steps } = useContext(StepperContext);
  const {
    state: { loanReason },
    actions: { update },
  } = useLoanApplication();
  const {
    state: {
      type: loanType,
      fixedTermLoan,
      invoiceDiscountingLoan,
      payableFinancingLoan,
      revenueBasedLoan,
      buyNowPayLaterLoan,
    },
    actions: {
      setFixedTermLoan,
      setInvoiceDiscountingLoan,
      setPayableFinancingLoan,
      setRevenueBasedLoan,
      setBuyNowPayLaterLoan,
    },
  } = useLoanCalculator();

  const {
    state: { products },
  } = useDpConfig();
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [selectedLoanType, setSelectedLoanType] = useState<LoanProductType>(
    loanType || LoanProductType.FIXED_TERM
  );

  const loanOption = LoanReasonMap.get(loanReason) ?? DEFAULT_REASON;

  const LOAN_TYPES = products.map((product) => ({
    value: product.type,
    label: product.displayName,
    description: getLoanDescription(product.type, product.config),
  }));

  const handleClick = ([value]: string[]) => {
    setSelectedLoanType(value as LoanProductType);
    update({ loanType: value as LoanProductType }, { local: true });

    switch (value) {
      case LoanProductType.FIXED_TERM:
        setFixedTermLoan(fixedTermLoan);
        break;
      case LoanProductType.INVOICE_DISCOUNTING:
        setInvoiceDiscountingLoan(invoiceDiscountingLoan);
        break;
      case LoanProductType.PAYABLE_FINANCING:
        setPayableFinancingLoan(payableFinancingLoan);
        break;
      case LoanProductType.REVENUE_BASED_FINANCING:
        setRevenueBasedLoan(revenueBasedLoan);
        break;
      case LoanProductType.BNPL:
        setBuyNowPayLaterLoan(buyNowPayLaterLoan);
        break;
    }
  };

  useEffect(() => {
    if (products && products.length === 1) {
      const [product] = products;
      switch (product.type) {
        case LoanProductType.FIXED_TERM:
          setFixedTermLoan(fixedTermLoan);
          break;
        case LoanProductType.INVOICE_DISCOUNTING:
          setInvoiceDiscountingLoan(invoiceDiscountingLoan);
          break;
        case LoanProductType.PAYABLE_FINANCING:
          setPayableFinancingLoan(payableFinancingLoan);
          break;
        case LoanProductType.REVENUE_BASED_FINANCING:
          setRevenueBasedLoan(revenueBasedLoan);
        case LoanProductType.BNPL:
          setBuyNowPayLaterLoan(buyNowPayLaterLoan);
          break;
      }
      setSelectedLoanType(product.type as LoanProductType);
      update(
        { loanType: product.type as LoanProductType },
        { local: true }
      ).then(() => {
        //Checks if the next step is the loan calculator then just skip this part and go to the next step
        if (
          steps.at(currentStep[0])?.subSteps[currentStep[1] + 1].name ==
          StepName.LOAN_CALCULATOR
        ) {
          gotoStep([currentStep[0], currentStep[1] + 1]);
        }
      });
    }
  }, []);

  return (
    <div className="cx-w-full cx-flex cx-flex-col cx-items-center cx-gap-y-2">
      <div className={"cx-mt-8"}>
        <div className={"cx-text-center cx-my-2"}>
          {/* <GradientBox angle={"cx-bg-gradient-to-bl"} {...TEXT_GRADIENT_STYLE}> */}
          <div className={"cx-text-3xl cx-font-bold"}>“{loanOption.label}”</div>
          {/* </GradientBox> */}
        </div>
        {products.length > 1 && (
          <CardSelect
            options={LOAN_TYPES}
            onChange={handleClick}
            value={[selectedLoanType]}
            multiple={false}
            title={"We’ve got you covered!"}
            description={
              "Choose which lending solution works best for your business."
            }
          />
        )}
        <div className={"cx-flex cx-justify-center cx-py-8"}>
          <Button
            onClick={nextStep}
            disabled={!loanType}
            arrow={"right"}
            label={"Apply Now"}
            type={"button"}
          ></Button>
        </div>
        <div className={"cx-flex cx-justify-center"}>
          <div
            className={
              "cx-text-neutral-darker cx-my-8 cx-text-sm cx-text-center cx-max-w-[960px]"
            }
          >
            By clicking the "Apply now" button, I confirm my acknowledgment and
            agreement to accept by CredibleX's{" "}
            <span
              className={"cx-text-primary hover:cx-cursor-pointer"}
              onClick={() => setTermsModalOpen(true)}
            >
              Terms & Conditions
            </span>{" "}
            and{" "}
            <span
              className={"cx-text-primary hover:cx-cursor-pointer"}
              onClick={() => setPrivacyModalOpen(true)}
            >
              Privacy Policy
            </span>{" "}
            , and I, in my capacity as an authorized person on behalf of the
            Borrower confirm that the Borrower is considered as a Professional
            Client as per the relevant, applicable regulations
          </div>
        </div>
        <Divider />
        <div className={"cx-flex cx-justify-center"}>
          <div
            className={
              "cx-py-3 cx-flex cx-gap-8  max-sm:cx-max-w-xs max-sm:cx-overflow-scroll"
            }
          >
            {FEATURES.map(({ name, image }) => (
              <GradientCard key={name} text={name} image={image} />
            ))}
          </div>
        </div>
      </div>

      <TermsAndConditionsModal
        isOpen={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
      />
      <PrivacyPolicyModal
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
      />
    </div>
  );
};

export default LoanTypeSelection;
