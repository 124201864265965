import { StepperAction, StepperContextValue } from "./types";

/**
 * @description This function is responsible to figure out the next step and substep index based on whether it should show/skip the illustrations based on the DP configuration
 * @param shouldSkipIllustrationSteps should the stepper show/skip the illustration step
 * @param steps Steps data from StepperContext
 * @param stepIndex Current step number
 * @param subStepIndex Current substep number
 * @param isForward Should the stepper move forward (next) or backwards (prev)
 * @param shouldMoveStep Should the stepper pre-move to the next/previous step before entering the illustration checker loop
 * @returns The next step and substep index
 */
const calculateNextStep = (
  shouldSkipIllustrationSteps: boolean,
  steps: StepperContextValue["steps"],
  stepIndex: number,
  subStepIndex: number,
  isForward: boolean,
  shouldMoveStep = true
) => {
  let currentStep = stepIndex;
  let currentSubStep = subStepIndex;

  // Pre-move before entering the illustration checker loop
  if (shouldMoveStep) {
    if (isForward) {
      currentSubStep += 1;
      if (currentSubStep >= steps[currentStep]?.subSteps.length) {
        currentStep += 1;
        currentSubStep = 0;
      }
    } else {
      currentSubStep -= 1;
      if (currentSubStep < 0) {
        currentStep -= 1;
        if (currentStep < 0) {
          currentStep = 0;
          currentSubStep = 0;
        } else {
          currentSubStep = steps[currentStep]?.subSteps.length - 1;
        }
      }
    }
  }

  while (shouldSkipIllustrationSteps) {
    const currentStepObj = steps[currentStep];

    if (!currentStepObj) {
      break; // If there are no more steps, break the loop
    }

    const isCurrentSubStepIllustration =
      currentStepObj.subSteps[currentSubStep]?.metadata?.hasIllustrations;

    if (isCurrentSubStepIllustration) {
      // If the current substep is an illustration, move to the next substep
      currentSubStep = isForward ? currentSubStep + 1 : currentSubStep - 1;

      if (
        currentSubStep >= currentStepObj.subSteps.length ||
        currentSubStep < 0
      ) {
        // If it was the last substep or the first step, move to the next/previous step
        currentStep = isForward ? currentStep + 1 : currentStep - 1;
        currentSubStep = isForward
          ? 0
          : steps[currentStep]?.subSteps.length - 1;
      }
    } else {
      // If the current substep is not an illustration, break the loop
      break;
    }
  }

  return [currentStep, currentSubStep];
};

export const StepperContextReducer = (
  state: StepperContextValue,
  action: StepperAction
): StepperContextValue => {
  const [currentStep, currentSubStep] = state.currentStep;

  switch (action.type) {
    case "NEXT_STEP": {
      if (state.jumpToAfterGoTo) {
        return {
          ...state,
          currentStep: state.jumpToAfterGoTo,
          jumpToAfterGoTo: null,
        };
      }

      const [nextStep, nextSubStep] = calculateNextStep(
        state.skipIllustrationSteps ?? false,
        state.steps,
        currentStep,
        currentSubStep,
        true
      );

      return { ...state, currentStep: [nextStep, nextSubStep] };
    }

    case "PREVIOUS_STEP": {
      const [prevStep, prevSubStep] = calculateNextStep(
        state.skipIllustrationSteps ?? false,
        state.steps,
        currentStep,
        currentSubStep,
        false
      );

      return { ...state, currentStep: [prevStep, prevSubStep] };
    }

    case "GOTO_STEP": {
      const [targetStep, targetSubStep] = action.payload.stepIndices;

      const validTargetStep =
        targetStep >= 0 && targetStep < state.steps.length;
      const validTargetSubStep = validTargetStep
        ? targetSubStep >= 0 &&
          targetSubStep < state.steps[targetStep].subSteps.length
        : false;

      if (validTargetStep && validTargetSubStep) {
        const [gotoStep, gotoSubStep] = calculateNextStep(
          state.skipIllustrationSteps ?? false,
          state.steps,
          targetStep,
          targetSubStep,
          true,
          false
        );

        return {
          ...state,
          currentStep: [gotoStep, gotoSubStep],
          jumpToAfterGoTo: action.payload.jumpToAfterGoTo ?? null,
        };
      } else {
        return state;
      }
    }

    case "RESET": {
      const [resetStep, resetSubStep] = calculateNextStep(
        state.skipIllustrationSteps ?? false,
        state.steps,
        0,
        0,
        true,
        false
      );

      return {
        ...state,
        currentStep: [resetStep, resetSubStep],
        jumpToAfterGoTo: null,
      };
    }

    case "SET_SKIP_ILLUSTRATION_STEPS": {
      return { ...state, skipIllustrationSteps: action.payload };
    }

    case "SET_LOADING": {
      return { ...state, loading: action.payload };
    }

    default:
      return state;
  }
};

export default StepperContextReducer;
