import { useContext } from "react";
import StepStart from "../../../components/StepStart/StepStart";
import { StepperContext } from "../../../contexts";
import { CompanyInformation } from "../../../assets/images";
import { useLoanCalculator } from "../../../contexts";
import { LOAN_PRODUCT_DISPLAY_NAME } from "../../../hooks/api/types";

const CompanyInformationStartScreen = () => {
  const { nextStep } = useContext(StepperContext);
  const {
    state: { type },
  } = useLoanCalculator();
  return (
    <StepStart
      title="Congratulations!"
      subtitle={`You are eligible for a ${
        type && LOAN_PRODUCT_DISPLAY_NAME[type]
      }`}
      image={CompanyInformation}
      onNext={() => {
        nextStep();
      }}
    />
  );
};

export default CompanyInformationStartScreen;
