import { useContext, useMemo } from "react";
import { CredibleLogoLight } from "../../assets/images";
import { useStepper } from "../LoanApplicationSteps/Stepper";
import { ProgressBar } from "../../components";
import { SDKContext, useLoanCalculator } from "../../contexts";
import { LoanProductType } from "../../hooks/api/types";
import clsx from "clsx";
import { useDpConfig } from "../../contexts/dpConfig";

const CALCULATOR_LOAN_TYPE_MAP = {
  [LoanProductType.FIXED_TERM]: "fixedTermLoan",
  [LoanProductType.INVOICE_DISCOUNTING]: "invoiceDiscountingLoan",
  [LoanProductType.PAYABLE_FINANCING]: "payableFinancingLoan",
  [LoanProductType.REVENUE_BASED_FINANCING]: "revenueBasedLoan",
};

const LoanApplicationFlowHeader = () => {
  const { currentStep, steps } = useStepper();
  const { state: calculatorState } = useLoanCalculator();
  const { options } = useContext(SDKContext);
  const {
    state: { settings },
  } = useDpConfig();

  const loanAmount = useMemo(() => {
    switch (calculatorState.type) {
      case LoanProductType.FIXED_TERM:
        return calculatorState.fixedTermLoan?.amount;
      case LoanProductType.INVOICE_DISCOUNTING:
        return calculatorState.invoiceDiscountingLoan?.invoices?.[0]?.amount;
      case LoanProductType.PAYABLE_FINANCING:
        return calculatorState.payableFinancingLoan?.invoices?.[0]?.amount;
      case LoanProductType.REVENUE_BASED_FINANCING:
        return calculatorState.revenueBasedLoan?.amount;
      case LoanProductType.BNPL:
        return calculatorState.buyNowPayLaterLoan?.amount;
      default:
        return 0;
    }
  }, [
    calculatorState.type,
    calculatorState.fixedTermLoan?.amount,
    calculatorState.invoiceDiscountingLoan?.invoices,
    calculatorState.payableFinancingLoan?.invoices,
    calculatorState.revenueBasedLoan?.amount,
    calculatorState.buyNowPayLaterLoan?.amount,
  ]);

  const currencyUnit = useMemo(() => {
    switch (calculatorState.type) {
      case LoanProductType.FIXED_TERM:
        return calculatorState.constraints.fixedTermLoan?.amount?.unit;
      case LoanProductType.INVOICE_DISCOUNTING:
        return calculatorState.constraints.invoiceDiscountingLoan?.amount?.unit;
      case LoanProductType.PAYABLE_FINANCING:
        return calculatorState.constraints.payableFinancingLoan?.amount?.unit;
      case LoanProductType.REVENUE_BASED_FINANCING:
        return calculatorState.constraints.revenueBasedLoan?.amount?.unit;
      case LoanProductType.BNPL:
        return calculatorState.constraints.buyNowPayLaterLoan?.amount?.unit;
      default:
        return "AED";
    }
  }, [
    calculatorState.type,
    calculatorState.constraints.fixedTermLoan?.amount?.unit,
    calculatorState.constraints.invoiceDiscountingLoan?.amount?.unit,
    calculatorState.constraints.payableFinancingLoan?.amount?.unit,
    calculatorState.constraints.revenueBasedLoan?.amount?.unit,
    calculatorState.constraints.buyNowPayLaterLoan?.amount?.unit,
  ]);

  return (
    <>
      <div className="cx-flex cx-justify-center cx-items-center cx-w-full cx-border-b cx-border-stroke-primary">
        {!settings.hideHeader && (
          <div className="cx-flex cx-justify-between cx-items-center cx-w-full cx-max-w-[960px] cx-px-2 cx-py-4">
            <div className="cx-max-h-14 cx-min-h-[42px] cx-flex cx-justify-center cx-items-center">
              {!settings.hideLogo && (
                <img
                  src={options?.logoUrl || CredibleLogoLight}
                  className={clsx({
                    "cx-h-6": !options?.logoUrl,
                    "cx-h-14": options?.logoUrl,
                  })}
                  alt={"logo"}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {!steps[currentStep[0]].hideProgress ? (
        <div className="cx-w-full cx-px-2">
          <ProgressBar steps={steps} currentStep={currentStep} />
        </div>
      ) : null}
    </>
  );
};

export default LoanApplicationFlowHeader;
