import { roundTo } from "../../../../utils";
import LoanReviewField from "../LoanReviewField";
import { Divider } from "../../../../components";
import { useLoanMetrics } from "../../../../hooks";
import EditButton from "../EditButton";
import { useState } from "react";
import BusinessLoanModal from "../BusinessLoanModal";
import { useLoanApplication } from "../../../../contexts";

const FixedTermLoanReviewSection = () => {
  const [loanCalculatorModalOpen, setLoanCalculatorModalOpen] = useState(false);

  const {
    monthlyBusinessLoanPaymentForSME,
    totalPayment,
    borrowedAmount,
    duration,
    interestRate,
    currencyUnit,
  } = useLoanMetrics();
  const {
    state: { additionalData },
  } = useLoanApplication();
  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
        <div className="cx-text-2xl cx-font-bold">Advance Cashflow</div>
        <EditButton onClick={() => setLoanCalculatorModalOpen(true)} />
      </div>
      <div className="cx-flex cx-flex-col sm:cx-flex-row cx-gap-x-10">
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(
              monthlyBusinessLoanPaymentForSME,
              2
            ).toLocaleString()}`}
            label="Your estimated monthly payment"
          />
          <Divider />
          <LoanReviewField
            value={`${duration.value} ${duration.unit}`}
            label="Loan Duration"
          />
          <Divider />
          {import.meta.env.VITE_HARDCODED_INTEREST_RATE ? (
            <LoanReviewField
              label="Monthly interest rate"
              value={`${import.meta.env.VITE_HARDCODED_INTEREST_RATE}%`}
            />
          ) : (
            <LoanReviewField
              label="Monthly interest rate"
              value={`Pay as low as ${(interestRate / 12) * 100}%*`}
              description="*Depends on your loan application and eligibility."
            />
          )}
        </div>
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(
              totalPayment,
              2
            ).toLocaleString()}`}
            label="Total repayment amount"
          />
          <Divider />
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(
              borrowedAmount,
              2
            ).toLocaleString()}*`}
            label="Amount to borrow"
          />
          {additionalData?.disbursementAccount?.iban && (
            <LoanReviewField
              label={"Disbursement account"}
              value={additionalData.disbursementAccount.iban}
            />
          )}
        </div>
      </div>
      <BusinessLoanModal
        isOpen={loanCalculatorModalOpen}
        onClose={() => {
          setLoanCalculatorModalOpen(false);
        }}
      />
    </div>
  );
};

export default FixedTermLoanReviewSection;
