import { useContext } from "react";
import StepStart from "../../../components/StepStart/StepStart";
import { StepperContext, useLoanApplication } from "../../../contexts";
import { OwnerInformation } from "../../../assets/images";

const OwnersInformationStartScreen = () => {
  const { nextStep } = useContext(StepperContext);
  const { state } = useLoanApplication();
  return (
    <StepStart
      title="Let's get to know you a bit better."
      subtitle={`Please have your Emirates ID${
        state.businessType === "Sole Proprietorship"
          ? " and shareholder information"
          : ""
      } ready`}
      image={OwnerInformation}
      onNext={() => {
        nextStep();
      }}
    />
  );
};

export default OwnersInformationStartScreen;
