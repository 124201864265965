import { ReactNode, useId } from "react";
import Button from "../Button/Button";
import { CloseIcon, ResetIcon } from "../../assets/icons";
import { Line } from "rc-progress";
import { truncate } from "../../utils";

export enum CardVariantType {
  SIMPLE = "simple",
  UPLOAD = "upload",
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  EDIT = "edit",
  UPLOAD_FAILED = "upload_failed",
}

type Props = {
  title: string;
  icon: ReactNode;
  description?: string | ReactNode;
  variant?: CardVariantType;
  onChange?: (file: File | undefined, index?: number) => void;
  error?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  fullWidth?: boolean;
  onClick?: () => void;
  handleFileClose?: (fileName: string, id?: string) => void;
  handleRetryUpload?: (fileName: string) => void;
  uploading?: boolean;
  documentId?: string;
  fileNewDesign?: boolean;
  truncateLength?: number;
  truncateTitleLength?: number;
  truncateDescriptionLength?: number;
};

const CardList: React.FC<Props> = ({
  title,
  description,
  icon,
  variant = CardVariantType.SIMPLE,
  onChange: onChange,
  error,
  inputProps = {},
  fullWidth,
  onClick,
  handleFileClose,
  handleRetryUpload,
  uploading,
  documentId,
  fileNewDesign = false,
  truncateTitleLength,
  truncateDescriptionLength,
}) => {
  const id = useId();
  return (
    <div
      className={`${
        fileNewDesign
          ? "cx-bg-background-secondary"
          : "cx-bg-background-default cx-shadow-base"
      } cx-rounded-base`}
    >
      <div
        className={`cx-py-4 cx-flex cx-min-w-[300px] cx-justify-between cx-items-center cx-px-5 cx-rounded-base ${
          fileNewDesign ? "" : error ? "cx-border cx-border-stroke-error" : ""
        } ${fullWidth ? "cx-w-full" : ""}`}
      >
        <input
          id={id}
          type="file"
          hidden
          multiple={false}
          onChange={(e) => {
            console.log("OnChange Input", e);
            if (onChange) {
              onChange(e.target.files?.[0]);
            }
          }}
          accept=".pdf,application.pdf"
          {...inputProps}
        />
        <div className="cx-flex cx-items-center cx-mr-2">
          <div className="cx-w-6 cx-h-6 cx-shrink-0">{icon}</div>
          <div className="cx-flex cx-flex-col cx-pl-4">
            <div className="cx-text-base cx-font-medium cx-text-black">
              {truncateTitleLength
                ? truncate(title, truncateTitleLength)
                : title}
            </div>
            {description && (
              <div
                className={`cx-text-xs ${
                  error ? "cx-text-error-regular" : "cx-text-neutral-darker"
                }`}
              >
                {truncateDescriptionLength &&
                variant === CardVariantType.UPLOADED
                  ? truncate(description as string, truncateDescriptionLength)
                  : variant !== CardVariantType.UPLOADING && description}
              </div>
            )}
          </div>
        </div>
        {variant === CardVariantType.UPLOAD && (
          <Button
            label="Upload"
            onClick={() => {
              document.getElementById(id)?.click();
            }}
            slim
          />
        )}
        {variant === CardVariantType.UPLOADED && handleFileClose && (
          <div
            className="cx-text-stroke-brand cx-cursor-pointer cx-hover:cursor-pointer"
            onClick={() => {
              handleFileClose && handleFileClose(title, documentId);
            }}
          >
            <CloseIcon className="stroke-current cx-text-stroke-brand" />
          </div>
        )}
        {variant === CardVariantType.UPLOAD_FAILED && (
          <div
            className="cx-text-stroke-brand cx-cursor-pointer cx-hover:cursor-pointer"
            onClick={() => {
              handleRetryUpload && handleRetryUpload(title);
            }}
          >
            <ResetIcon className="stroke-current cx-text-stroke-brand" />
          </div>
        )}
        {variant === CardVariantType.UPLOADED && !handleFileClose && (
          <Button
            label="Change"
            outlined
            slim
            onClick={() => {
              document.getElementById(id)?.click();
            }}
          />
        )}
        {variant === CardVariantType.UPLOADING && <Button loader="left" slim />}
        {variant === CardVariantType.EDIT && (
          <Button
            label="Edit"
            slim
            onClick={() => {
              if (onClick) {
                onClick();
              } else {
                document.getElementById(id)?.click();
              }
            }}
          />
        )}
      </div>
      {uploading && (
        <div className="cx-w-full">
          <Line
            percent={25}
            strokeWidth={1}
            strokeColor="#4589FF"
            className="cx-w-full cx-pb-4 cx-px-5"
          />
        </div>
      )}
    </div>
  );
};

export default CardList;
