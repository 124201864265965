import { Button } from "../../components";
import { useLoanCalculator } from "../../contexts";
import { LoanProductType } from "../../hooks/api/types";
import BuyNowPayLaterCalculator from "./BuyNowPayLaterCalculator";
import FixedTermLoanCalculator from "./FixedTermLoanCalculator";
import InvoiceDiscountingLoanCalculator from "./InvoiceDiscountingLoanCalculator";
import PayableFinancingLoanCalculator from "./PayableFinancingLoanCalculator";
import RevenueBasedLoanCalculator from "./RevenueBasedLoanCalculator";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  className?: string;
  actionButton?: string;
  onAction?: () => void;
};

const LoanCalculatorModal: React.FC<Props> = ({
  isOpen,
  onClose,
  className,
  actionButton,
  onAction,
}) => {
  const {
    state: { type },
  } = useLoanCalculator();

  const renderCalculator = (): React.ReactNode => {
    if (type === LoanProductType.FIXED_TERM) {
      return (
        <FixedTermLoanCalculator
          editMode
          handleSubmit={onAction}
          handleCancel={onClose}
        />
      );
    } else if (type === LoanProductType.INVOICE_DISCOUNTING) {
      return (
        <InvoiceDiscountingLoanCalculator
          editMode
          handleSubmit={onAction}
          handleCancel={onClose}
        />
      );
    } else if (type === LoanProductType.PAYABLE_FINANCING) {
      return (
        <PayableFinancingLoanCalculator
          editMode
          handleSubmit={onAction}
          handleCancel={onClose}
        />
      );
    } else if (type === LoanProductType.REVENUE_BASED_FINANCING) {
      return (
        <RevenueBasedLoanCalculator
          editMode
          handleSubmit={onAction}
          handleCancel={onClose}
        />
      );
    } else {
      return (
        <BuyNowPayLaterCalculator
          editMode
          handleSubmit={onAction}
          handleCancel={onClose}
        />
      );
    }
  };

  return isOpen ? (
    <>
      <div className="cx-fixed cx-inset-0 cx-bg-background-overlay-primary cx-z-40"></div>
      <div className="cx-fixed cx-z-50 cx-inset-0 cx-overflow-y-auto">
        <div className="cx-flex cx-items-center cx-justify-center cx-min-h-screen">
          <div
            className="cx-fixed cx-inset-0 cx-bg-gray-500 cx-opacity-90"
            onClick={onClose}
          ></div>
          <div
            className={`cx-relative cx-bg-background-default cx-rounded-modal cx-max-w-[960px] ${className}`}
          >
            {renderCalculator()}
            {!!actionButton && (
              <div className="cx-flex cx-items-center cx-justify-center cx-w-full">
                <div className="cx-max-w-[400px] cx-w-full">
                  <Button label={actionButton} onClick={onAction} fullWidth />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default LoanCalculatorModal;
