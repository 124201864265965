import { FC, useEffect, useRef, useState } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useGetKycAccessToken } from "../../../hooks";
import { useLoanApplication } from "../../../contexts";
import { StepNextButton } from "../../../components";
import { ButtonLoaderIcon } from "../../../assets/icons";
import { ShareholderKYCApplicationStatus } from "../../../hooks/api/types";

type Props = {
  onSubmit: () => void;
};

enum SumSubMessageType {
  APPLICANT_LOADED = "idCheck.onApplicantLoaded",
  APPLICANT_STATUS_CHANGED = "idCheck.onApplicantStatusChanged",
  APPLICANT_RESUBMITTED = "idCheck.onApplicantResubmitted",
  APPLICANT_SUBMITTED = "idCheck.onApplicantSubmitted",
}

enum SumSubReviewStatus {
  INIT = "init",
  PENDING = "pending",
  PRECHECKED = "prechecked",
  QUEUED = "queued",
  COMPLETED = "completed",
  ON_HOLD = "onHold",
}

const SumSubKyc: FC<Props> = ({ onSubmit }) => {
  const [accessToken, setAccessToken] = useState<string>();
  const [kycApplicantId, setKycApplicantId] = useState<string>();
  const [isNext, setIsNext] = useState<boolean>();

  const kycRef = useRef<string>();
  kycRef.current = kycApplicantId;

  const {
    state: { applicant },
    actions: { update, refetch },
  } = useLoanApplication();

  const { refetch: getAccessToken } = useGetKycAccessToken(false);

  const handleGetAccessToken = async () => {
    const {
      data: { kycAccessToken },
    } = await getAccessToken(applicant?.kycApplication?.id);
    setAccessToken(kycAccessToken.token);
  };

  useEffect(() => {
    if (
      [
        ShareholderKYCApplicationStatus.COMPLETED,
        ShareholderKYCApplicationStatus.IN_PROGRESS,
      ].includes(applicant?.kycApplication?.status)
    ) {
      setIsNext(true);
    }
  }, [applicant?.kycApplication?.status]);

  const handleResult = async (type: any, payload: any) => {
    switch (type) {
      case SumSubMessageType.APPLICANT_LOADED: {
        setKycApplicantId(payload.applicantId);
        break;
      }
      case SumSubMessageType.APPLICANT_RESUBMITTED:
      case SumSubMessageType.APPLICANT_SUBMITTED: {
        await update({
          applicant: {
            ...applicant,
            kycApplication: {
              status: ShareholderKYCApplicationStatus.IN_PROGRESS,
            },
          },
        });
        await refetch();
        setIsNext(true);
        break;
      }
      case SumSubMessageType.APPLICANT_STATUS_CHANGED: {
        if (payload.reviewStatus === SumSubReviewStatus.COMPLETED) {
          await refetch();
          setIsNext(true);
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (applicant?.id && applicant?.id !== "") {
      handleGetAccessToken().catch((err) => console.log(err));
    }
  }, [applicant?.id]);

  if (accessToken) {
    return (
      <div className="cx-max-w-[960px] cx-min-h-[400px] cx-w-full cx-grid">
        <SumsubWebSdk
          accessToken={accessToken}
          config={{
            lang: "en",
            country: "ARE",
          }}
          options={{ addViewportTag: false, adaptIframeHeight: true }}
          expirationHandler={() =>
            handleGetAccessToken().catch((err) => console.log(err))
          }
          onMessage={handleResult}
          onError={(err: any) => console.log("onError", err)}
        />
        {isNext ? (
          <div className="cx-w-1/2 cx-justify-self-center">
            <StepNextButton label="Next" onClick={onSubmit} />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return (
    <div className="cx-max-w-[960px] cx-min-h-[400px] cx-w-full cx-flex cx-justify-center cx-items-center">
      <div className="cx-animate-spin cx-text-brand-primary-regular cx-w-10 cx-h-10">
        <ButtonLoaderIcon width="100%" height="100%" />
      </div>
    </div>
  );
};

export default SumSubKyc;
