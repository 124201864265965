import { FC, ReactNode, useCallback } from "react";
import { OptionCard } from "./OptionCard";

type Value = string | number;

export type CardOption = {
  value: Value;
  label?: ReactNode;
  description?: ReactNode;
};

export type CardSelectProps = {
  title?: ReactNode;
  description?: ReactNode;
  options?: CardOption[];
  value?: Value[];
  onChange?(value: Value[]): void;
  multiple?: boolean;
};

export const CardSelect: FC<CardSelectProps> = ({
  title,
  description,
  options = [],
  value: selectedValue = [],
  onChange,
  multiple = true,
}) => {
  const handleChange = useCallback(
    (value: Value) => {
      const isSelected = selectedValue?.includes(value);

      if (!multiple) {
        onChange?.(isSelected ? [] : [value]);
        return;
      }

      if (isSelected) {
        const newSelected = selectedValue?.filter((v) => v !== value) ?? [];
        onChange?.(newSelected);
      } else {
        onChange?.([...selectedValue, value]);
      }
    },
    [selectedValue, onChange, multiple]
  );

  return (
    <div className="cx-w-full">
      {title && (
        <div className="cx-text-2xl cx-font-bold cx-text-center cx-mb-2">
          {title}
        </div>
      )}
      {description && (
        <div className="cx-text-center cx-font-light cx-text-s cx-text-text-secondary">
          {description}
        </div>
      )}
      {/* <div className="cx-mt-8 cx-grid cx-grid-cols-3 max-lg:cx-grid-cols-2 max-sm:cx-grid-cols-1 cx-gap-10"> */}
      <div className="cx-mt-8 cx-flex cx-flex-row cx-flex-wrap cx-justify-center cx-gap-10 cx-items-stretch">
        {options.map(({ value, label, description }) => (
          <div className="cx-min-w-[420px] cx-max-w-[420px]" key={value}>
            <OptionCard
              value={value}
              label={label}
              description={description}
              isSelected={selectedValue?.includes(value)}
              onClick={() => handleChange(value)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSelect;
