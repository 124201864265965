import { FC, useEffect, useState } from "react";
import { Button, StepNextButton, StepPrevButton } from "../../../components";
import {
  useGetOpenBankingStatus,
  useInitiateOpenBanking,
  useUpdateOpenBankingStage,
} from "../../../hooks";
import { useLoanApplication } from "../../../contexts";
import {
  OpenBankingStatus,
  APIError,
  OpenbankingApplicationStage,
} from "../../../hooks/api/types";
import { ArrowLeftIcon, ConnectIcon } from "../../../assets/icons";

type IntegratedBank = {
  name: string;
  logo: string;
};

enum LeantechStatus {
  SUCCESS = "SUCCESS",
  CANCELLED = "CANCELLED",
}

const loadLeanTechScript = (callback?: Function) => {
  const existingScript = document.getElementById("lean-tech-script");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://cdn.leantech.me/link/sdk/web/latest/Lean.min.js";
    script.id = "lean-tech-script";
    document.body.appendChild(script);
    const div = document.createElement("div");
    div.id = "lean-link";
    document.body.appendChild(div);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

type Props = {
  onNext?: () => void;
  onPrevious?: () => void;
};

const LinkBankLite: FC<Props> = ({ onNext, onPrevious }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [openBankingInitiating, setOpenBankingInitiating] = useState(false);
  const [integratedBanks, setIntegratedBanks] = useState<Array<IntegratedBank>>(
    []
  );
  const [allowNext, setAllowNext] = useState(false);

  const {
    state: { id, leanApplicationId },
    actions: { update },
  } = useLoanApplication();

  const [loading, setLoading] = useState(false);

  const { mutateAsync: initiateOpenBanking, error } = useInitiateOpenBanking();
  const { mutateAsync: updateOpenBankingStage } = useUpdateOpenBankingStage();
  const [isResultsPending, setIsResultsPending] = useState(false);

  useGetOpenBankingStatus(
    id,
    (data) => {
      if (
        data?.status === OpenBankingStatus.IN_PROGRESS &&
        data?.stage === OpenbankingApplicationStage.WAITING_FOR_WEBHOOK
      ) {
        setLoading(true);
      } else if (data && data.status !== OpenBankingStatus.IN_PROGRESS) {
        if (data.banks?.length) {
          setIntegratedBanks(data.banks);
        }
        setLoading(false);
        setAllowNext(true);
      } else if (data.status === OpenBankingStatus.RECONNECT_REQUIRED) {
        (window as any).Lean.reconnect({
          app_token: data.leanAppToken,
          reconnect_id: data.leanReconnectId,
          sandbox: import.meta.env.VITE_LEANTECH_SANDBOX_MODE === "true",
        });
        setLoading(false);
      }
      setIsResultsPending(data?.status === OpenBankingStatus.RESULTS_PENDING);
    },
    !!leanApplicationId
  );

  useEffect(() => {
    loadLeanTechScript(() => {
      setScriptLoaded(true);
    });
  }, []);

  const handleConnectBank = async () => {
    try {
      setOpenBankingInitiating(true);
      const data = await initiateOpenBanking({ loanApplicationId: id });
      await update(
        {
          leanApplicationId: data.leanApplicationId,
          leanCustomerId: data.leanCustomerId,
        },
        { local: true }
      );
      setOpenBankingInitiating(false);

      (window as any).Lean.connect({
        app_token: data.leanAppToken,
        customer_id: data.leanCustomerId,
        permissions: ["identity", "accounts", "transactions", "balance"],
        sandbox: import.meta.env.VITE_LEANTECH_SANDBOX_MODE === "true",
        ...(!!data.leanAccessToken && { access_token: data.leanAccessToken }),
        callback: (data: any) => {
          if (data.status === LeantechStatus.SUCCESS) {
            setLoading(true);
            updateOpenBankingStage({
              loanApplicationId: id,
              stage: OpenbankingApplicationStage.WAITING_FOR_WEBHOOK,
            });
          } else if (data.status === LeantechStatus.CANCELLED) {
            setLoading(false);
            updateOpenBankingStage({
              loanApplicationId: id,
              stage: OpenbankingApplicationStage.CANCELLED,
            });
          }
        },
      });
    } catch (err) {
    } finally {
      setOpenBankingInitiating(false);
    }
  };

  return (
    <div className="cx-w-full cx-flex cx-flex-col cx-items-center cx-gap-y-6">
      <div className="cx-w-full cx-flex cx-flex-col cx-gap-y-2">
        <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
          Connect your bank account
        </div>
        <div className="cx-text-text-secondary cx-text-base cx-text-center">
          You can securely connect up-to six bank accounts.
        </div>
      </div>
      {integratedBanks.length === 3 && (
        <div className="cx-mb-4 cx-py-2 cx-px-3 cx-bg-highlight-lighter cx-text-highlight-regular cx-text-center cx-rounded-alert">
          You have reached the limit of connected banks
        </div>
      )}
      <div className="cx-w-full cx-bg-background-default cx-rounded-xl cx-gap-y-4 ">
        {integratedBanks.map((bank) => (
          <div
            key={bank.name}
            className="cx-p-4 cx-flex cx-min-w-[300px] cx-justify-between cx-items-center cx-rounded-base cx-bg-background-default cx-border-[1.5px] cx-border-background-tertiary cx-mb-4"
          >
            <div className="cx-flex cx-gap-4">
              <div className="cx-w-6 cx-h-6 cx-bg-black cx-rounded">
                <img src={bank.logo} />
              </div>
              <div className="cx-text-text-primary">{bank.name}</div>
            </div>
          </div>
        ))}
        <div className="cx-p-4 cx-flex cx-min-w-[300px] cx-justify-between cx-items-center cx-rounded-base cx-bg-background-default cx-border-[1.5px] cx-border-background-tertiary">
          <div className="cx-flex cx-gap-4">
            <div className="cx-w-6 cx-h-6">
              <ConnectIcon className="cx-text-stroke-brand" />
            </div>
            <div className="cx-text-text-primary">
              Connect {integratedBanks.length > 0 ? "another " : ""} bank
            </div>
          </div>
          <div>
            <Button
              label={
                scriptLoaded && !openBankingInitiating && !loading
                  ? "Connect"
                  : ""
              }
              slim
              {...((!scriptLoaded || openBankingInitiating || loading) && {
                loader: "left",
              })}
              onClick={() => {
                if (integratedBanks.length === 3) return;
                handleConnectBank();
              }}
              disabled={
                !scriptLoaded ||
                openBankingInitiating ||
                loading ||
                isResultsPending
              }
            />
          </div>
        </div>
        {!!error && (
          <div className="cx-text-xs cx-py-1 cx-text-text-error cx-px-1 cx-mt-1">
            {(error as APIError)?.message}
          </div>
        )}
        {isResultsPending && (
          <div className="cx-text-xs cx-py-1 cx-text-text-highlight cx-px-1 cx-mt-1">
            It looks like it's taking longer than usual, feel free to move ahead
            while we complete this process in the background
          </div>
        )}
      </div>
      <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4 cx-max-w-[400px]">
        <div className="cx-w-1/4">
          {onPrevious && (
            <StepPrevButton
              label={<ArrowLeftIcon />}
              type="button"
              onClick={onPrevious}
            />
          )}
        </div>
        <div className="cx-w-3/4">
          {onNext && (
            <StepNextButton
              label="Next"
              onClick={onNext}
              disabled={!allowNext}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkBankLite;
