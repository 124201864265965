import { FC, useEffect, useRef, useState } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useGetKycShareholderAccessToken } from "../../../hooks";
import { useLoanApplication } from "../../../contexts";
import { StepNextButton } from "../../../components";
import { ButtonLoaderIcon } from "../../../assets/icons";
import { ShareholderKYCApplicationStatus } from "../../../hooks/api/types";
import IndividualShareholderForm from "./forms/IndividualShareholderForm";

type Props = {
  onSubmit: () => void;
};

enum SumSubMessageType {
  APPLICANT_LOADED = "idCheck.onApplicantLoaded",
  APPLICANT_STATUS_CHANGED = "idCheck.onApplicantStatusChanged",
  APPLICANT_RESUBMITTED = "idCheck.onApplicantResubmitted",
  APPLICANT_SUBMITTED = "idCheck.onApplicantSubmitted",
}

const SumsubWebSdkConfig = {
  lang: "en",
  country: "ARE",
};

const ShareHolderSumSubKyc: FC<Props> = ({ onSubmit }) => {
  const [accessToken, setAccessToken] = useState<string>();
  const [kycApplicantId, setKycApplicantId] = useState<string>();
  const [isNext, setIsNext] = useState<boolean>();

  const kycRef = useRef<string>();
  kycRef.current = kycApplicantId;

  const {
    state: { individualShareholders, shareHolderPendingKYC },
    actions: { update, refetch },
  } = useLoanApplication();
  const { refetch: refetchAccessToken } = useGetKycShareholderAccessToken(
    shareHolderPendingKYC?.id ?? ""
  );

  useEffect(() => {
    if (
      shareHolderPendingKYC &&
      shareHolderPendingKYC.id !== "" &&
      !kycApplicantId
    ) {
      handleGetAccessToken();
    }
  }, [shareHolderPendingKYC]);

  const handleGetAccessToken = async () => {
    const {
      data: { kycAccessToken },
    } = await refetchAccessToken();
    setAccessToken(kycAccessToken.token);
  };

  const handleResult = async (type: any, payload: any) => {
    switch (type) {
      case SumSubMessageType.APPLICANT_LOADED:
        setKycApplicantId(payload.applicantId);
        break;
      case SumSubMessageType.APPLICANT_RESUBMITTED:
      case SumSubMessageType.APPLICANT_SUBMITTED:
        individualShareholders.map((sh) => {
          if (shareHolderPendingKYC!.id === sh.id) {
            sh.latestKycApplication!.status =
              ShareholderKYCApplicationStatus.IN_PROGRESS;
          }
        });

        await update({
          individualShareholders: [...individualShareholders],
        });
        await refetch();
        setIsNext(true);
        break;
      default:
        break;
    }
  };

  if (accessToken) {
    return (
      <div className="cx-max-w-[960px] cx-min-h-[400px] cx-w-full cx-grid">
        <SumsubWebSdk
          accessToken={accessToken}
          config={SumsubWebSdkConfig}
          options={{ addViewportTag: false, adaptIframeHeight: true }}
          expirationHandler={() => handleGetAccessToken().catch()}
          onMessage={handleResult}
        />
        {isNext && (
          <div className="cx-w-1/2 cx-justify-self-center">
            <StepNextButton label="Continue" onClick={onSubmit} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="cx-max-w-[960px] cx-min-h-[400px] cx-w-full cx-flex cx-justify-center cx-items-center">
      <div className="cx-animate-spin cx-text-brand-primary-regular cx-w-10 cx-h-10">
        <ButtonLoaderIcon width="100%" height="100%" />
      </div>
    </div>
  );
};

export default ShareHolderSumSubKyc;
