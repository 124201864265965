import { LoanApplicationStatus } from "../../../hooks/api/types";

export const AWAITABLE_STATUSES: Array<LoanApplicationStatus> = [
  LoanApplicationStatus.WAITING_FOR_DP_REVIEW,
  LoanApplicationStatus.SUBMITTED,
  LoanApplicationStatus.INPRINCIPAL_APPROVAL,
  LoanApplicationStatus.MISSING_INFORMATION,
  LoanApplicationStatus.REFER,
  LoanApplicationStatus.MLRO_REVIEW,
  LoanApplicationStatus.INPRINCIPAL_APPROVAL,
  LoanApplicationStatus.INREVIEW_MLRO,
  LoanApplicationStatus.INREVIEW_OPS,
  LoanApplicationStatus.INREVIEW_UW,
  LoanApplicationStatus.INREVIEW_SUW,
  LoanApplicationStatus.INREVIEW_CRO,
  LoanApplicationStatus.APPROVED_SUW,
  LoanApplicationStatus.APPROVED_CRO,
  LoanApplicationStatus.ADDITIONAL_INFO_REQUESTED,
];
