import { ReactNode } from "react";
import { StepConfig, StepperContextProvider } from "../../contexts";
import LoanOfferForm from "./Form";

type StepperProps = {
  stepConfig: StepConfig;
  children?: ReactNode;
};

const LoanOfferFlow: React.FC<StepperProps> = ({ stepConfig }) => {
  return (
    <StepperContextProvider stepConfig={stepConfig}>
      <LoanOfferForm />
    </StepperContextProvider>
  );
};

export default LoanOfferFlow;
