import { ReactNode } from "react";

type TabProps = {
  label: string;
  component: ReactNode;
  onTabClicked?: () => void;
};

type Props = {
  tabs: TabProps[];
  disabled?: boolean;
};

const BoxTabs = ({ tabs, disabled }: Props) => (
  <div className="cx-flex cx-flex-row cx-gap-x-4">
    {tabs.map((tab) => (
      <div
        key={tab.label}
        className="cx-cursor-pointer cx-flex-1"
        onClick={() => {
          if (disabled) return;
          if (tab.onTabClicked) {
            tab.onTabClicked();
          }
        }}
      >
        {tab.component}
      </div>
    ))}
  </div>
);

export default BoxTabs;
