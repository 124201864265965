import { ClockIcon } from "../../assets/icons";
import { Button } from "../../components";

type Props = {
  title: string;
  subtitle: string;
  time?: string;
  onNext?: () => void;
  image?: string;
};

const StepStart: React.FC<Props> = ({
  title,
  subtitle,
  time,
  onNext,
  image,
}) => {
  return (
    <div className="cx-flex cx-flex-col cx-justify-center cx-items-center">
      {!!image && (
        <div className="cx-w-96 cx-h-96">
          <img src={image} alt="start" />
        </div>
      )}
      <div className="cx-text-center cx-text-5xl cx-text-text-primary cx-mb-2 cx-font-bold">
        {title}
      </div>
      <div className="cx-text-text-secondary cx-text-center cx-mb-10">
        {subtitle}
      </div>
      {!!time && (
        <div className="cx-flex cx-justify-center cx-items-center cx-mb-10">
          <div className="cx-w-4 cx-h-4 cx-text-text-primary cx-mr-1.5">
            <ClockIcon width="100%" height="100%" />
          </div>
          <div className="cx-text-text-primary cx-text-sm">2 mins</div>
        </div>
      )}
      <div className="cx-min-w-[170px]">
        <Button label="Start" arrow="right" onClick={onNext} fullWidth />
      </div>
    </div>
  );
};

export default StepStart;
