import { useFormik } from "formik";
import { FC } from "react";
import {
  Button,
  CardRadioOptions,
  Divider,
  InputField,
  StepNextButton,
} from "../../../components";
import {
  BOOLEAN_CARD_OPTIONS,
  OWNER_INFORMATION_INITIAL_VALUES,
  OWNER_INFORMATION_VALIDATION_SCHEMA,
  OwnerInformationFields,
} from "./service";
import {
  CompanyShareholderType,
  ShareholderEntityType,
} from "../../../hooks/api/types";
import {
  Applicant,
  IndividualShareholder,
  OTPType,
  useLoanApplication,
} from "../../../contexts";
import { ArrowLeftIcon } from "../../../assets/icons";

type Props = {
  onSubmit: (values: Applicant) => Promise<void>;
  shareholderPreSelected?: IndividualShareholder;
  backClicked?: () => void;
};

const ApplicantInformationForm: FC<Props> = ({
  onSubmit,
  shareholderPreSelected,
  backClicked,
}) => {
  const {
    state,
    actions: { update },
  } = useLoanApplication();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    submitForm,
  } = useFormik<OwnerInformationFields>({
    initialValues: {
      ...OWNER_INFORMATION_INITIAL_VALUES,
      email:
        shareholderPreSelected?.email ??
        (state.otpType === OTPType.EMAIL
          ? state.email
          : state.applicant?.email),
      firstName:
        shareholderPreSelected?.firstName ?? state.applicant?.firstName,
      lastName: shareholderPreSelected?.lastName ?? state.applicant?.lastName,
      isStakeholder:
        shareholderPreSelected?.isStakeholder ?? state.applicant?.isStakeholder,
      hasPOA: shareholderPreSelected?.hasPOA ?? state.applicant?.hasPOA,
    },
    enableReinitialize: true,
    validationSchema: OWNER_INFORMATION_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      try {
        const applicant = {
          ...state.applicant,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          isStakeholder: values.isStakeholder,
          hasPOA: values.hasPOA,
          id: shareholderPreSelected?.id,
        };
        await update({
          applicant,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        });
        await locallyAddApplicantToIndividualShareholders(values);
        await onSubmit(applicant);
      } catch (err) {
        console.log(err);
      }
    },
  });

  // This function adds the applicant to the individual shareholders list for the next step to render it
  const locallyAddApplicantToIndividualShareholders = async (
    values: OwnerInformationFields
  ) => {
    let individualShareHolders: IndividualShareholder[] = [];
    if (state.applicant) {
      individualShareHolders = state.individualShareholders?.filter(
        (shareholder) => {
          return shareholder.id !== state.applicant?.id;
        }
      );
    }

    await update(
      {
        individualShareholders: [
          ...individualShareHolders,
          {
            id: state.applicant?.id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            isStakeholder: values.isStakeholder,
            hasPOA: values.hasPOA,
            countryCode: state.countryCode,
            localNumber: state.localNumber,
            type: CompanyShareholderType.APPLICANT,
            __entityType: ShareholderEntityType.INDIVIDUAL,
          },
        ],
      },
      { local: true }
    );
  };

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
        Your information
      </div>
      <div className="cx-max-w-[400px]">
        <form onSubmit={handleSubmit}>
          <div className="cx-font-semibold cx-text-text-primary cx-text-base cx-mb-4 cx-mt-10">
            Please enter your personal information
          </div>
          <div className="cx-flex cx-justify-between cx-mb-4">
            <div className="cx-mr-1">
              <InputField
                label="First name"
                tick={touched.firstName && !errors.firstName}
                inputProps={{
                  name: "firstName",
                  value: values.firstName,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={
                  touched.firstName && !!errors.firstName
                    ? errors.firstName
                    : ""
                }
              />
            </div>
            <div className="cx-ml-1">
              <InputField
                label="Last name"
                tick={touched.lastName && !errors.lastName}
                inputProps={{
                  name: "lastName",
                  value: values.lastName,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={
                  touched.lastName && !!errors.lastName ? errors.lastName : ""
                }
              />
            </div>
          </div>
          <div className="cx-mb-4">
            <InputField
              label="Business email"
              tick={touched.email && !errors.email}
              error={touched.email && !!errors.email ? errors.email : ""}
              inputProps={{
                name: "email",
                value: values.email,
                onChange: handleChange,
                onBlur: handleBlur,
                disabled: state.otpType === OTPType.EMAIL,
              }}
            />
          </div>
          <div className="cx-font-semibold cx-text-text-primary cx-text-base cx-mb-4 cx-mt-10">
            Are you a shareholder of {state.businessName}?
          </div>
          <div className="cx-flex cx-justify-between cx-mb-4">
            <CardRadioOptions
              options={BOOLEAN_CARD_OPTIONS}
              inputProps={{
                name: "isStakeholder",
                value: `${values.isStakeholder}`,
                onChange: (e) => {
                  setFieldValue("isStakeholder", e.target.value === "true");
                },
              }}
              layout="horizontal"
              keyPropName="value"
              hideValues
            />
          </div>
          <div className="cx-font-semibold cx-text-text-primary cx-text-base cx-mb-4 cx-mt-10">
            Do you have authority to take out a loan on behalf of the company?
          </div>
          <div className="cx-flex cx-justify-between cx-mb-4">
            <CardRadioOptions
              options={BOOLEAN_CARD_OPTIONS}
              inputProps={{
                name: "hasPOA",
                value: `${values.hasPOA}`,
                onChange: (e) => {
                  setFieldValue("hasPOA", e.target.value === "true");
                },
              }}
              layout="horizontal"
              keyPropName="value"
              hideValues
            />
          </div>
          <div className="cx-my-10">
            <Divider />
          </div>

          {
            <div className="cx-max-w-[400px] cx-w-full cx-flex cx-py-6 cx-p-4">
              {shareholderPreSelected && (
                <div className="cx-mr-4">
                  <Button
                    outlined
                    label={
                      <ArrowLeftIcon className="cx-mx-4 hover:cx-text-text-inverse" />
                    }
                    onClick={backClicked}
                  />
                </div>
              )}

              <div className="cx-w-full">
                <StepNextButton
                  loading={state.updating}
                  disabled={state.updating}
                />
              </div>
            </div>
          }
        </form>
      </div>
    </div>
  );
};

export default ApplicantInformationForm;
