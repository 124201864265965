import { useContext } from "react";
import StepStart from "../../../components/StepStart/StepStart";
import { StepperContext } from "../../../contexts/stepper";
import { BusinessInformation } from "../../../assets/images";

const BusinessInformationStartScreen = () => {
  const { nextStep } = useContext(StepperContext);
  return (
    <StepStart
      title="Check if your business is eligible"
      subtitle="Please have your Trade License, Memorandum of Association, and Power of Attorney (if required) ready."
      image={BusinessInformation}
      onNext={() => {
        nextStep();
      }}
    />
  );
};

export default BusinessInformationStartScreen;
