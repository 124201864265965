import { useMemo } from "react";
import { LoanApplicationStatus, LoanProductType } from "./api/types";
import { LoanApplication } from "../contexts";

/**
 * Dashboard should show payments only when loan is active,
 * and it is not Invoice Discounting
 * */
export const useLoanApplicationPaymentsEnabled = (
  loan: LoanApplication | undefined
) => {
  return useMemo(() => {
    return (
      loan &&
      !(
        loan.loanType == LoanProductType.INVOICE_DISCOUNTING ||
        loan.loanType == LoanProductType.PAYABLE_FINANCING
      ) &&
      loan?.status === LoanApplicationStatus.ACTIVE
    );
  }, [loan]);
};
