import { FC, useEffect, useState } from "react";
import { Button, Divider, StepNextButton } from "../../../components";
import {
  CompanyShareholder,
  IndividualShareholder,
  useLoanApplication,
} from "../../../contexts";
import ShareholderCard from "./ShareholderCard";
import AddShareholder from "./AddShareholder";
import { useStepper } from "../Stepper";
import { ArrowLeftIcon } from "../../../assets/icons";

type Props = {
  nextClicked: (shareholder: IndividualShareholder | null) => void;
};

export const OwnerListSelection: FC<Props> = ({ nextClicked }) => {
  const { nextStep, gotoStep, previousStep } = useStepper();

  const { state } = useLoanApplication();
  const [selectedShareholder, setSelectedShareholder] =
    useState<IndividualShareholder | null>(null);

  const handleCardClick = (shareholder: IndividualShareholder) => {
    if (selectedShareholder?.email === shareholder.email) {
      setSelectedShareholder(null);
    } else {
      setSelectedShareholder(shareholder);
    }
  };

  return (
    <div>
      <div className="cx-flex cx-flex-col cx-items-center">
        <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
          Applicant Information
        </div>
        <div className="cx-font-semibold cx-text-text-secondary cx-text-base cx-mb-10 cx-mt-10">
          Help us identify you. Please select your profile or create a new one
        </div>
        <div className="cx-flex cx-gap-6 cx-items-stretch">
          {state.individualShareholders.map((shareholder, idx) => (
            <ShareholderCard
              key={shareholder.email}
              mode="selection"
              name={`${shareholder.firstName} ${shareholder.lastName}`}
              email={shareholder.email}
              className={`hover:cx-border-2 hover:cx-border-brand-primary-regular hover:cx-rounded-lg hover:cx-shadow-2xl  hover:cx-cursor-pointer 
                ${
                  selectedShareholder?.email === shareholder.email
                    ? "cx-border-2 cx-border-brand-primary-regular cx-rounded-lg"
                    : ""
                }`}
              onClick={() => handleCardClick(shareholder)}
            />
          ))}
          <ShareholderCard
            mode="add"
            name="Add your details"
            onClick={() => nextClicked(null)}
          />
        </div>

        <div className="cx-my-10">
          <Divider />
        </div>

        <div className="cx-max-w-[400px] cx-w-full cx-flex cx-py-6 cx-p-4">
          <div className="cx-mr-4">
            <Button
              outlined
              label={
                <ArrowLeftIcon className="cx-mx-4 hover:cx-text-text-inverse" />
              }
              onClick={previousStep}
            />
          </div>

          <div className="cx-w-full">
            <StepNextButton
              label="Next"
              disabled={!selectedShareholder}
              onClick={async () => {
                nextClicked(selectedShareholder!);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
