import { useEffect } from "react";
import { InactivityClockIcon, StarIcon } from "../../assets/icons";
import { useTheme } from "../../hooks";

const TimeoutScreen: React.FC = () => {
  const { setTheme } = useTheme();

  useEffect(() => {
    // Getting the theme configuration from local storage to render timeout screen as per the distribution partner's settings.
    // Since we cannot request backend for dp's data with an expired token.
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(JSON.parse(storedTheme));
    } else {
      setTheme();
    }
  }, []);

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-min-h-screen cx-sticky  cx-background-radial-gradient">
      <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-background-radial-gradient cx-w-full cx-p-8">
        <div className="cx-flex cx-relative">
          <div className="cx-absolute cx-top-2 cx-left-3">
            <StarIcon className="cx-stroke-brand-primary-regular cx-w-3.5 cx-h-3.5" />
          </div>
          <div className="cx-w-40 cx-h-40 cx-flex cx-items-center cx-justify-center cx-rounded-full cx-bg-brand-primary-lighter">
            <InactivityClockIcon className="cx-text-brand-primary-regular" />
          </div>
          <div className="cx-absolute cx-bottom-1 cx-right-8">
            <StarIcon className="cx-stroke-brand-primary-regular cx-w-[18px] cx-h-[18px]" />
          </div>
        </div>
        <div className="cx-text-text-primary cx-text-[36px] cx-font-bold cx-pt-5">
          Oh snap!
        </div>
        <div className="cx-text-text-secondary cx-text-base cx-font-normal cx-text-center cx-w-2/6">
          Your session has timed out due to inactivity. Please refresh the page
          or try again to continue.
        </div>
      </div>
    </div>
  );
};

export default TimeoutScreen;
