import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";
import {
  DirectDebitBankAccountSourceType,
  LoanOfferStage,
} from "../../hooks/api/types";

export type BankType = {
  bankName: string;
  bankLogo?: string;
  iban?: string;
  accountName?: string;
  accountId?: string;
  sourceType: DirectDebitBankAccountSourceType;
};

export enum SignModeType {
  MOUSE_TRACKPAD = "MOUSE_TRACKPAD",
  CAMERA = "CAMERA",
  PRINT_SCAN = "PRINT_SCAN",
  UPLOAD_SIGNATURE = "UPLOAD_SIGNATURE",
}

type LoanOfferContextValues = {
  state: {
    id: string;
    tradeLicenseNumber: string;
    businessName: string;
    loanType: string;
    interestRate: number;
    duration: string;
    totalAmount: string;
    monthlyPayment: string;
    totalInterestPaid: string;
    totalRepayment: string;
    bankName: string;
    bankIban: string;
    signMode: SignModeType;
    signImgUrl: string;
    banklist: BankType[];
    loading: boolean;
    declineReason: string;
    loanApplicationId: string;
    stage: LoanOfferStage;
    isMainApplicant: boolean;
    totalShareholders: number;
    shareholderId: string;
    isActive: boolean;
    dpConfig: Record<string, any>;
    dpSettings: Record<string, any>;
    lastOfferExpiredAt: string;
    isAlternateOffer: boolean;
    preSelectedSignMode: SignModeType | null;
    existingShareholderSignatureSignedUrl: string | null;
    uiLocalizationData: Record<string, any>;
  };
  actions: {
    update: (payload: Partial<LoanOfferContextValues["state"]>) => Promise<any>;
  };
};

type LoanOfferContextAction = {
  type: "UPDATE";
  payload: Partial<LoanOfferContextValues["state"]>;
};

const INITIAL_STATE: LoanOfferContextValues["state"] = {
  id: "",
  tradeLicenseNumber: "",
  businessName: "",
  loanType: "",
  interestRate: 0,
  duration: "",
  totalAmount: "",
  monthlyPayment: "",
  totalInterestPaid: "",
  totalRepayment: "",
  bankName: "",
  bankIban: "",
  signMode: SignModeType.MOUSE_TRACKPAD,
  signImgUrl: "",
  banklist: [],
  loading: false,
  declineReason: "",
  loanApplicationId: "",
  stage: LoanOfferStage.REVIEW,
  isMainApplicant: false,
  totalShareholders: 0,
  shareholderId: "",
  isActive: false,
  dpConfig: {},
  dpSettings: {},
  uiLocalizationData: {},
  lastOfferExpiredAt: "",
  isAlternateOffer: false,
  preSelectedSignMode: null,
  existingShareholderSignatureSignedUrl: null,
};

const LoanOfferContext = createContext<LoanOfferContextValues>({
  state: INITIAL_STATE,
  actions: {
    update: async () => {},
  },
});

const reducer = (
  state: LoanOfferContextValues,
  action: LoanOfferContextAction
): LoanOfferContextValues => {
  console.log("action", action, "state", state);
  if (action.type === "UPDATE") {
    return {
      ...state,
      state: {
        ...state.state,
        ...action.payload,
      },
    };
  }
  return state;
};

export const LoanOffer: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    state: INITIAL_STATE,
    actions: {
      update: async () => {},
    },
  });

  const actions = useMemo(() => {
    return {
      update: async (payload: Partial<LoanOfferContextValues["state"]>) => {
        dispatch({ type: "UPDATE", payload });
      },
    };
  }, [dispatch, state.state]);

  const contextValue = useMemo(() => {
    return { state: state.state, actions };
  }, [state.state, actions]);

  return (
    <LoanOfferContext.Provider value={contextValue}>
      {children}
    </LoanOfferContext.Provider>
  );
};

export const useLoanOffer = () => useContext(LoanOfferContext);
