import { CredibleLogoLight } from "../../assets/images";

type Props = {
  text: string;
};

const Footer: React.FC<Props> = ({ text }) => {
  return (
    <div className="cx-flex cx-flex-col cx-content-center cx-items-center cx-gap-3 cx-p-4">
      <div className="cx-w-[74px]">
        <img src={CredibleLogoLight} alt={"CredibleX"} />
      </div>
      <div className="cx-max-w-[626px] cx-text-center cx-text-text-tertiary cx-text-xs cx-m-auto">
        {text}
      </div>
    </div>
  );
};

export default Footer;
