import { useLoanApplication, useLoanCalculator } from "../../../contexts";
import {
  LoanApplicationStage,
  LoanProductType,
} from "../../../hooks/api/types";
import LoanCalculatorModal from "../../LoanCalculator/LoanCalculatorModal";
import React from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const BusinessLoanModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { state: calculatorState } = useLoanCalculator();
  const {
    state: loanApplicationState,
    actions: { update },
  } = useLoanApplication();

  return (
    <LoanCalculatorModal
      isOpen={isOpen}
      onClose={onClose}
      onAction={async () => {
        try {
          if (
            loanApplicationState.stage !== LoanApplicationStage.BRANDING &&
            loanApplicationState.stage !== LoanApplicationStage.CALCULATOR
          ) {
            // If the loan application is after branding/calculator, then we call the API
            // to update the loan application stage
            await update({
              loanType: calculatorState.type,
              ...(calculatorState.type === LoanProductType.FIXED_TERM && {
                fixedTermLoan: calculatorState.fixedTermLoan,
              }),
              ...(calculatorState.type ===
                LoanProductType.INVOICE_DISCOUNTING && {
                invoices: calculatorState.invoiceDiscountingLoan.invoices,
              }),
              ...(calculatorState.type ===
                LoanProductType.PAYABLE_FINANCING && {
                invoices: calculatorState.payableFinancingLoan.invoices,
              }),
              ...(calculatorState.type ===
                LoanProductType.REVENUE_BASED_FINANCING && {
                revenueBasedLoan: calculatorState.revenueBasedLoan,
              }),
              ...(calculatorState.type === LoanProductType.BNPL && {
                buyNowPayLaterLoan: calculatorState.buyNowPayLaterLoan,
              }),
              additionalDPMetadata: {
                userConstraintsConfig: JSON.stringify(
                  calculatorState.constraints.userConstraintsConfig
                ),
              },
            });
          }
          onClose();
        } catch (err) {
          console.log(err);
        }
      }}
    />
  );
};

export default BusinessLoanModal;
