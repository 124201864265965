import { FC, useState } from "react";
import { CardRadioOptions } from "../../../components";
import { ShareholderEntityType } from "../../../hooks/api/types";
import {
  CompanyShareholderFields,
  IndividualShareholderFields,
  ShareholderFields,
} from "./service";
import IndividualShareholderForm from "./forms/IndividualShareholderForm";
import CompanyShareholderForm from "./forms/CompanyShareholderForm";
import clsx from "clsx";

type Props = {
  shareholder?: ShareholderFields;
  onSubmit: (values: ShareholderFields) => void;
  onCancel: () => void;
  modal?: boolean;
  title?: string;
};

const AddShareholder: FC<Props> = ({
  shareholder,
  onSubmit,
  onCancel,
  modal = false,
  title,
}) => {
  const [entityType, setEntityType] = useState<ShareholderEntityType>(
    shareholder?.__entityType || ShareholderEntityType.INDIVIDUAL
  );

  const handleIndividualShareholderSubmit = (
    values: IndividualShareholderFields
  ) => {
    // Do something with the values
    onSubmit(values);
  };

  const handleCompanyShareholderSubmit = (values: CompanyShareholderFields) => {
    // Do something with the values
    onSubmit(values);
  };

  const displayMode = shareholder ? "Update" : "Add";

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
        {title ||
          (modal
            ? `${displayMode} shareholder`
            : `${displayMode} shareholder information`)}
      </div>
      <div
        className={clsx({
          "cx-text-text-secondary cx-mt-4 cx-mb-4": modal,
          "cx-text-text-primary cx-font-semibold cx-mb-10 cx-mt-10": !modal,
        })}
      >
        Shareholders will be notified via email to conduct an identity
        verification check using a valid Emirates ID
      </div>
      <div className="cx-max-w-[400px] cx-w-full">
        <div
          className={clsx(
            "cx-font-semibold cx-text-text-primary cx-text-base cx-mb-4",
            { "cx-mt-10": !modal }
          )}
        >
          Shareholder type
        </div>
        <div className="cx-w-full cx-mb-10">
          <CardRadioOptions
            options={[
              { label: "Individual", value: ShareholderEntityType.INDIVIDUAL },
              {
                label: "Company",
                value: ShareholderEntityType.COMPANY,
              },
            ]}
            inputProps={{
              name: "entityType",
              onChange: (e) =>
                setEntityType(e.target.value as ShareholderEntityType),
              value: entityType,
              disabled: !!shareholder,
            }}
            keyPropName="value"
            layout="horizontal"
            hideValues
          />
        </div>
        {entityType === ShareholderEntityType.INDIVIDUAL ? (
          <IndividualShareholderForm
            shareholder={shareholder as IndividualShareholderFields}
            onSubmit={handleIndividualShareholderSubmit}
            onCancel={onCancel}
          />
        ) : (
          <CompanyShareholderForm
            shareholder={shareholder as CompanyShareholderFields}
            onSubmit={handleCompanyShareholderSubmit}
            onCancel={onCancel}
          />
        )}
      </div>
    </div>
  );
};

export default AddShareholder;
