import { FC, useEffect, useState } from "react";
import {
  Histogram,
  HistogramColumnProps,
  HistogramColumnSectionProps,
  NumberVariant,
} from "../../components";
import { ButtonLoaderIcon } from "../../assets/icons";
import { useLoanDashboard } from "../../contexts";
import { usePaymentHistory } from "../../hooks";
import {
  MonthlyAmounts,
  PaymentHistoryDTO,
  PaymentHistoryResponseDTO,
} from "../../hooks/api/types";
import { HistogramVariant } from "../../components/Histogram/Histogram";
import {
  getHumanReadableMonthShort,
  isFutureMonth,
  isFutureYear,
} from "../../utils";

const mapPaymentHistoryDataToHistogramProps = (
  data: PaymentHistoryResponseDTO,
  variant: HistogramVariant,
  onClick?: (paymentId: string) => void
): HistogramColumnProps[] => {
  const history = data.history;
  if (!history) return [];
  const sortedMonths = Object.keys(history).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  return variant == HistogramVariant.normal
    ? getNormalSections(history, sortedMonths)
    : getSlimSections(history, sortedMonths, onClick);
};

//we are under the assumption that monthly value is supposed to be same for all installments
const getMonthlyInstallmentValue = (history: PaymentHistoryDTO) => {
  return history[Object.keys(history)[0]].installmentAmount;
};

const getNormalSections = (
  history: PaymentHistoryDTO,
  sortedMonths: string[]
) => {
  return sortedMonths.map((item) => ({
    tag: new Date(item).toLocaleString("default", { month: "short" }),
    onClick: () => console.log("Clicked on", item),
    sectionData: [
      {
        amount: history[item].paidAmount,
        backgroundClassName: history[item].outstandingAmount
          ? "cx-bg-red-500 cx-rounded-lg"
          : "cx-bg-blue-500",
        labelProps: {
          showLabel: true,
          showAmount: true,
          fontColorClassName: "cx-text-white",
        },
        index: 0,
      },
      {
        amount: history[item].exceededAmount,
        backgroundClassName: "cx-bg-blue-100 cx-mb-1",
        labelProps: {
          showLabel: true,
          showAmount: true,
          labelPrefix: "+",
          fontColorClassName: "cx-text-blue-500",
        },
        index: 1,
      },
      {
        amount: history[item].outstandingAmount,
        backgroundClassName: history[item].paidAmount
          ? "cx-bg-red-100 -cx-z-10"
          : "cx-bg-red-100",
        labelProps: {
          showLabel: true,
          showAmount: true,
          labelPrefix: "-",
          fontColorClassName: "cx-text-red-500",
        },
        index: 2,
      },
      ...(history[item].outstandingAmount +
        history[item].paidAmount +
        history[item].exceededAmount ===
      0
        ? [
            {
              amount: 0,
              backgroundClassName: "cx-bg-gray-100",
              labelProps: {
                showLabel: true,
                showAmount: true,
                fontColorClassName: "cx-text-gray-500",
              },
              isPlaceHolder: true,
              index: 3,
            },
          ]
        : []),
    ].filter((section) => section.amount > 0 || section.isPlaceHolder),
  }));
};

const getFormatedTag = (sortedMonths: string[], item: string) => {
  return isFutureYear(new Date(sortedMonths[0]), new Date(item))
    ? `${getHumanReadableMonthShort(item)} ${new Date(item).getFullYear()}`
    : getHumanReadableMonthShort(item);
};

const getSlimSections = (
  history: PaymentHistoryDTO,
  sortedMonths: string[],
  onClick?: (paymentId: string) => void
): HistogramColumnProps[] => {
  return sortedMonths.map((item) => ({
    tag: getFormatedTag(sortedMonths, item),
    onClick: () =>
      //This is under the assumption that we can have multiple installments per month but for now we only have monthly installments
      onClick && onClick(history[item].loanContractScheduleIds[0]),
    sectionData: getStyledSections(history[item]),
  }));
};

const getStyledSections = (
  sectionData: MonthlyAmounts
): HistogramColumnSectionProps[] => {
  const styledSections = [];

  if (sectionData.paidAmount) {
    if (sectionData.paidAmount < sectionData.installmentAmount) {
      styledSections.push({
        amount: sectionData.paidAmount,
        labelProps: {
          labelAmount: sectionData.outstandingAmount,
          fontColorClassName: "cx-text-text-error",
          labelPrefix: (
            <>
              Due <br />-
            </>
          ),
          showAmount: true,
          showLabel: true,
        },
        backgroundClassName: "cx-bg-background-error",
        index: 0,
      });
    } else {
      styledSections.push({
        amount: sectionData.paidAmount,
        backgroundClassName: "cx-bg-background-brand",
        labelPrefix: "Paid",
        labelProps: {
          fontColorClassName: "cx-text-text-secondary",
          labelPrefix: "Paid",
          showAmount: false,
          showLabel: true,
        },
        index: 0,
      });

      sectionData.exceededAmount &&
        styledSections.push({
          amount: sectionData.exceededAmount,
          backgroundClassName: "cx-bg-background-success",
          labelProps: {
            fontColorClassName: "cx-text-text-success",
            labelPrefix: (
              <>
                Refund <br />+
              </>
            ),
            showAmount: true,
            showLabel: true,
          },
          index: 1,
        });
    }
  }

  if (
    isFutureMonth(new Date(sectionData.installmentDates[0])) &&
    !sectionData.paidAmount
  ) {
    const date = new Date(sectionData.installmentDates[0]);
    styledSections.push({
      amount: sectionData.installmentAmount,
      backgroundClassName: "cx-bg-gray-100",
      fontColorClassName: "cx-text-gray-500",
      labelProps: {
        showLabel: true,
        showAmount: false,
        labelPrefix: `Due on ${date.getDate()} ${getHumanReadableMonthShort(
          date.toString()
        )}`,
        fontColorClassName: "cx-text-gray-500",
      },
      index: 3,
    });
  }
  return styledSections;
};

export const PaymentHistoryHistogram: FC = () => {
  const {
    state: { currentLoanApplicationId },
    actions: { update },
  } = useLoanDashboard();
  const { data, isLoading } = usePaymentHistory(currentLoanApplicationId);
  const [columnData, setColumnData] = useState<HistogramColumnProps[]>([]);
  const [monthlyInstallment, setMonthlyInstallment] = useState<number>(0);

  const handleOpenPaymentDetails = async (paymentId: string) => {
    await update({
      currentPaymentId: paymentId,
      showPaymentDetails: true,
      showPaymentHistory: false,
    });
  };

  useEffect(() => {
    if (data) {
      setColumnData(
        mapPaymentHistoryDataToHistogramProps(
          data,
          HistogramVariant.slim,
          handleOpenPaymentDetails
        )
      );
      if (data.history) {
        setMonthlyInstallment(getMonthlyInstallmentValue(data.history));
      }
    }
  }, [data, isLoading]);

  if (isLoading || !data)
    return (
      <div className="cx-animate-spin cx-text-brand-primary-regular cx-w-7 cx-h-7 cx-mt-4">
        <ButtonLoaderIcon width="100%" height="100%" />
      </div>
    );
  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-w-full cx-p-8">
      <Histogram
        data={columnData}
        numberVariant={NumberVariant.CURRENCY}
        variant={HistogramVariant.slim}
        referenceValue={monthlyInstallment}
      />
    </div>
  );
};
