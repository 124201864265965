import { differenceDays, formatAmount } from "../../../utils";
import { DateTime } from "luxon";
import {
  LoanPayment,
  LoanPaymentStatus,
  statusReadableMap,
} from "../../../contexts";
import { FC, useMemo } from "react";

type LoanPaymentTableRowProps = {
  payment: LoanPayment;
  index: number;
};

const getReadablePaymentStatus = (status: LoanPaymentStatus): string => {
  return statusReadableMap[status] || status;
};

const getPaymentStatusForDueIn = (daysUntilPayment: number) => {
  if (daysUntilPayment === 0)
    return getReadablePaymentStatus(LoanPaymentStatus.DUE);
  else if (daysUntilPayment < 0)
    return getReadablePaymentStatus(LoanPaymentStatus.OVERDUE);
  else {
    return `Due in ${daysUntilPayment} days`;
  }
};

export const LoanPaymentTableRow: FC<LoanPaymentTableRowProps> = ({
  payment,
  index,
}) => {
  const isPaid = useMemo(() => {
    return payment.status === LoanPaymentStatus.PAID;
  }, [payment]);

  const daysUntilPayment = useMemo(() => {
    return differenceDays(payment.date);
  }, [payment]);

  return (
    <div
      key={`payment-schedule-${index}`}
      className={`cx-flex cx-flex-row cx-items-center cx-p-2 cx-min-h-[40px] cx-rounded-lg ${
        index % 2 === 0
          ? "cx-bg-background-secondary"
          : "cx-bg-background-default"
      }`}
    >
      <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-base">
        {`AED ${formatAmount(payment.amount)}`}
      </div>
      <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-base">
        {DateTime.fromISO(payment.date).toFormat("d LLL, yyyy")}
      </div>
      <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-base">
        {`AED ${
          payment.status !== LoanPaymentStatus.PAID
            ? formatAmount(payment.outstandingAmount)
            : 0
        }`}
      </div>
      {isPaid && (
        <div className="cx-w-1/4 cx-text-text-success cx-font-medium cx-text-base">
          {getReadablePaymentStatus(payment.status)}
        </div>
      )}
      {!isPaid && (
        <div className="cx-w-1/4 cx-text-text-secondary cx-font-medium cx-text-base">
          {daysUntilPayment < 7 &&
          payment.status === LoanPaymentStatus.SCHEDULED
            ? getPaymentStatusForDueIn(daysUntilPayment)
            : getReadablePaymentStatus(payment.status)}
        </div>
      )}
    </div>
  );
};

export default LoanPaymentTableRow;
