type DividerProps = {
  mobileOnly?: boolean;
  className?: string;
};

const Divider = ({ mobileOnly, className = "" }: DividerProps) => {
  return (
    <div
      className={`${
        mobileOnly && "sm:cx-hidden"
      } cx-w-full cx-h-[0.5px] cx-bg-background-tertiary ${className}`}
    />
  );
};

export default Divider;
