import DOMPurify from "dompurify";

export function downloadBlob(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.click();
}

export const sanitizeHtml = (rawHtml: string) => {
  return DOMPurify.sanitize(rawHtml);
};
