import * as yup from "yup";
import { SignModeType } from "../../contexts/loanOffer";

export type SelectBankAccountFields = {
  bankName: string;
  bankIban: string;
};

export type LoanOfferSignTypeFields = {
  signMode: SignModeType;
};

export type LoanOfferSignInputFields = {
  signImgUrl: string;
};

export type LoanOfferDeclineReasonFields = {
  reason: string;
};

export const SELECT_BANK_ACCOUNT_INITIAL_VALUES: SelectBankAccountFields = {
  bankName: "",
  bankIban: "",
};

export const LOAN_OFFER_SIGN_TYPE_INITIAL_VALUES: LoanOfferSignTypeFields = {
  signMode: SignModeType.CAMERA,
};

export const LOAN_OFFER_SIGN_INPUT_INITIAL_VALUES: LoanOfferSignInputFields = {
  signImgUrl: "",
};

export const LOAN_OFFER_DECLINE_REASON_INITIAL_VALUES: LoanOfferDeclineReasonFields =
  {
    reason: "",
  };

export const SELECT_ACCOUNT_VALIDATION_SCHEMA = yup.object().shape({
  bankName: yup.string().required("Please select an account to proceed"),
});

export const ADD_ACCOUNT_VALIDATION_SCHEMA = yup.object().shape({
  bankName: yup.string().required("Bank name is required"),
  bankIban: yup
    .string()
    .required("IBAN is required")
    .matches(/^AE[0-9]*/g, "IBAN should be valid and start with 'AE'"),
});

export const LOAN_OFFER_SIGN_TYPE_VALIDATION_SCHEMA = yup.object().shape({
  signMode: yup.string().required("Sign is required"),
});

export const LOAN_OFFER_SIGN_INPUT_VALIDATION_SCHEMA = yup.object().shape({
  signImgUrl: yup
    .string()
    .matches(
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
      "Signature is required"
    ),
});

export const LOAN_OFFER_DECLINE_REASON_VALIDATION_SCHEMA = yup.object().shape({
  reason: yup.string().required("Please select a reason to continue."),
});
