import { FC, ReactNode } from "react";
import { clsx } from "clsx";

export type OptionCardProps = {
  value: string | number;
  label?: ReactNode;
  description?: ReactNode;
  onClick?(value: string | number): void;
  isSelected?: boolean;
};

export const OptionCard: FC<OptionCardProps> = ({
  value,
  label,
  description,
  onClick,
  isSelected,
}) => {
  return (
    <div
      key={value}
      className={`cx-p-10 cx-border cx-rounded-2xl cx-flex cx-flex-col 
      cx-items-center cx-align-middle cx-cursor-pointer
      cx-transition-all cx-h-full 
        ${clsx({
          "cx-card-select-background-gradient cx-border-1.5 cx-border-stroke-brand":
            isSelected,
        })}
      `}
      onClick={() => onClick?.(value)}
    >
      {label && (
        <div className="cx-font-bold cx-text-center cx-text-2xl cx-mb-2">
          {label}
        </div>
      )}
      {description}
    </div>
  );
};

export default OptionCard;
